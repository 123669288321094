import { ChangeDetectorRef, Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/services/auth.service';
import { UserService } from 'src/services/user.service';
import { AlertService } from 'src/shared/alert.service';
import { DatePipe } from '@angular/common';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA
} from "@angular/material/dialog";
import { MultipleEntriesComponent } from '../modals/multiple-entries/multiple-entries.component';
interface InputClickStatus {
  firstname: boolean;
  lastname: boolean;
  email: boolean;
  id: boolean;
  telephone: boolean;
  newPassword: boolean,
  currentPassword: boolean,
  verifyPassword: boolean,
  alt_contactNo: boolean,
  alt_email: boolean,
}

@Component({
  selector: 'app-account-page',
  templateUrl: './account-page.component.html',
  styleUrls: ['./account-page.component.scss']
})
export class AccountPageComponent implements OnInit {
  firstname: any = 'N/A';
  lastname: any = 'N/A';
  email: any = 'N/A';
  id: any ='N/A';
  telephone: any = 'N/A';
  alt_email: any = 'N/A';
  alt_contactNo: any = 'N/A'
  PREV_email : any;
  PREV_telephone : any;
  isInputClicked: InputClickStatus = {
    firstname: false,
    lastname: false,
    email: false,
    telephone: false,
    newPassword: false,
    id: false,
    currentPassword: false,
    verifyPassword: false,
    alt_contactNo: false,
    alt_email: false,
  };
  isInputClickedAlternate: InputClickStatus = {
    firstname: false,
    lastname: false,
    email: false,
    telephone: false,
    newPassword: false,
    id: false,
    currentPassword: false,
    verifyPassword: false,
    alt_contactNo: false,
    alt_email: false,
  };
  newPassword:any;
  currentPassword:any;
  verifyPassword:any;
  activeHeader: string = 'personal-details';
  isEditClicked: boolean = false;
  isEditClickedAlternate: boolean = false;
  loggedInUser: any;
  IDFrontDoc: string = 'Pending upload';
  CompanyRegLetter: string = 'Pending upload';
  Proof_of_address: string = 'Pending upload';
  company_letter: string = 'Pending upload';
  passwordForm!: FormGroup;
  IDFrontDocStatus: string = 'no status found';
  CompanyRegLetterStatus: string = 'no status found';
  Proof_of_addressStatus: string = 'no status found';
  company_letterStatus: string = 'no status found';
  IDFrontDocDate: any;
  CompanyRegLetterDate: any;
  Proof_of_addressDate:any;
  company_letterDate: any;
  IDFrontDocSize: any;
  CompanyRegLetterSize: any;
  Proof_of_addressSize: any;
  company_letterSize:any;
  passwordRequirementsMet: boolean = false;
  userType: any;
  profileUrl: any = "../../assets/placeholder.jpg";
  baseUrl: any = environment.baseUrl;
  selectedFile: File | undefined;
  compressedImage: File | undefined;
  showModalBox: boolean = false;
  activeButton: String = 'alternate';
  matDialogRef!: MatDialogRef<MultipleEntriesComponent>;
  name: string = "hello";

  remainingTime: number = 300;
  displayTime: string = '05:00'
  intervalId: any;
  type: string = 'email';
  otpInput: any;
  displayOTPScreen: boolean = false;
  otpFormSubmitted: boolean = false;
  verificationForm!: FormGroup;

  constructor(private fb: FormBuilder,private auth: AuthService,private users:UserService,private alert: AlertService,private ref: ChangeDetectorRef
    ,private ngZone: NgZone,private router: Router,private datePipe: DatePipe,private matDialog: MatDialog,private _formBuilder: FormBuilder) { 
    this.passwordForm = this.fb.group({
      currentPassword: ['', [Validators.required, this.passwordValidator()]],
      newPassword: ['', [Validators.required, this.passwordValidator()]],
      verifyPassword: ['', [Validators.required, this.matchPasswordValidator('newPassword')]],
    });
  }
  private passwordValidator() {
    return Validators.pattern(/^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/);
  }

  private matchPasswordValidator(controlName: string) {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const newPassword = this.passwordForm?.get(controlName)?.value;
      const match = newPassword === control.value;
      return match ? null : { 'passwordMismatch': true };
    };
  }
  ngOnInit(): void {
    this.getUserByEmail(this.auth.getLoggedInUserId().contactInfo.Email);
  }
  OpenModal() {
    this.matDialogRef = this.matDialog.open(MultipleEntriesComponent, {
      data: this.name,
      disableClose: true
    });

    this.matDialogRef.afterClosed().subscribe(res => {
      if ((res == true)) {
        this.name = "";
      }
    });
  }
  updateAlternateContactInfo(){
    if(this.userType !== 'individual'){
      this.loggedInUser.contactInfo['AlternateContactNo'] = this.alt_contactNo;
      this.loggedInUser.contactInfo['AlternateEmail'] = this.alt_email;
      this.users.updateUserContactInfo(this.loggedInUser.contactInfo,this.email).subscribe((data:any)=>{
        if(data.status.success){
          this.alert.notificationToast('success','check your email address to confirm detail changes!');
        }
        else if(!data.status.success){
          this.alert.notificationToast('warning','Your must confirm your current changes or wait for 24 hrs before updating user changes!')
        }
        this.getUserByEmail(this.email);
      })
    }
  }
  updateContactInfo(){
    if(this.userType){
      if(this.email !== this.loggedInUser.contactInfo['Email'] && this.telephone !== this.loggedInUser.contactInfo.ContactNumber){
        // this.alert.notificationToast('warning','You can only update phone numbers or email.');
        this.OpenModal();
        return;
      }
      else if(this.email !== this.loggedInUser.contactInfo['Email']){
        const data = {
          type: 'email',
          email: this.loggedInUser.contactInfo['Email'],
          newEmail: this.email,
        };
        this.ngZone.run(() => {
          this.router.navigate(['/otp'], {
            queryParams: data
          });
        });
      }
      else if(this.telephone != this.loggedInUser.contactInfo.ContactNumber){
        const data = {
          type: 'contactNumber',
          contactNumber: this.PREV_telephone,
          email: this.loggedInUser.contactInfo['Email'],
          newcontactNumber: this.telephone,
        };
        this.ngZone.run(() => {
          this.router.navigate(['/otp'], {
            queryParams: data
          });
        });
      }
    }
  }
  uploadProfilePicture(event: any){
    const selectedFile = event.target.files[0];
    if (selectedFile) {
        this.users.uploadKycDoc(selectedFile, 'profile_picture', this.email).subscribe(data=>{
          this.getUserByEmail(this.email);
        });
    }
  }
  formatDate(originalDate:any) {
    const parsedDate = new Date(originalDate);
    return this.datePipe.transform(parsedDate, 'dd.MM.yyyy (HH:mm:ss)');
  }
  onEmailChange(newEmail: string) {
    this.email = newEmail.toLowerCase();
  }
  getUserByEmail(email:any){
    this.users.getLoggedinUser(email).subscribe((data:any)=>{
      this.loggedInUser = data.user;
      this.firstname= this.loggedInUser.contactInfo.FirstName;
      this.lastname = this.loggedInUser.contactInfo.Surname;
      this.email= this.loggedInUser.contactInfo.Email;
      this.telephone= this.loggedInUser.contactInfo.ContactNumber;
      this.PREV_email= this.loggedInUser.contactInfo.Email;
      this.PREV_telephone= this.loggedInUser.contactInfo.ContactNumber;
      this.id = this.loggedInUser.contactInfo.IDNumber;
      this.alt_email= this.loggedInUser.contactInfo['AlternateEmail'];
      this.alt_contactNo = this.loggedInUser.contactInfo['AlternateContactNo'];
      this.userType = this.loggedInUser.userType;
      this.users.getUploadedDocuments(this.email).subscribe((docs:any)=>{
        docs.forEach((doc: any) => {
          if(doc.metadata.type == "profile_picture"){
            this.profileUrl = this.baseUrl+`/files/document/${this.email}/${doc.name}`;
          }
          if(doc.metadata.type == 'identity_document_front'){
            this.IDFrontDoc = doc.name;
            this.IDFrontDocDate = this.formatDate(doc.uploadDate);
            this.IDFrontDocSize = doc.length/1024;
            this.IDFrontDocSize = +this.IDFrontDocSize.toFixed(2) + 'KB';
            if(doc.metadata.verified == false){
              this.IDFrontDocStatus = 'Pending approval';
            }
            else{
              if(doc.metadata.verified_reason){
                this.IDFrontDocStatus = doc.metadata.verified_reason;
              }
              
            }
          }
          if(doc.metadata.type == 'company_registration'){
            this.CompanyRegLetter = doc.name;
            this.CompanyRegLetterDate = this.formatDate(doc.uploadDate);
            this.CompanyRegLetterSize = doc.length/1024;
            this.CompanyRegLetterSize = +this.CompanyRegLetterSize.toFixed(2) + 'KB';
            if(doc.metadata.verified == false){
              this.CompanyRegLetterStatus = 'Pending approval';
            }
            else{
              this.CompanyRegLetterStatus = doc.metadata.verified_reason;
            }
          }
          if(doc.metadata.type == 'proof_of_address'){
            this.Proof_of_address = doc.name;
            this.Proof_of_addressDate = this.formatDate(doc.uploadDate);
            this.Proof_of_addressSize = doc.length/1024;
            this.Proof_of_addressSize = +this.Proof_of_addressSize.toFixed(2) + 'KB';
            if(doc.metadata.verified == false){
              this.Proof_of_addressStatus = 'Pending approval';
            }
            else{
              if(doc.metadata.verified_reason){
                this.Proof_of_addressStatus = doc.metadata.verified_reason;
              }
              
            }
          }
          if(doc.metadata.type == 'company_letter'){
            this.company_letter = doc.name;
            this.company_letterDate = this.formatDate(doc.uploadDate);
            this.company_letterSize = doc.length/1024;
            this.company_letterSize = +this.company_letterSize.toFixed(2) + 'KB';
            if(doc.metadata.verified == false){
              this.company_letterStatus = 'Pending approval';
            }
            else{
              if(doc.metadata.verified_reason){
                this.company_letterStatus = doc.metadata.verified_reason;
              } 
             
            }
          }
        });
      })
    })
  }
  isDocument(file: File): boolean {
    const imageFormats = ['image/jpeg', 'image/png', 'application/pdf'];
    return imageFormats.includes(file.type);
  }
  async uploadIDFront(event: any) {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      if (!this.isDocument(selectedFile)) {
        this.alert.notificationToast('warning','Selected file is not an allowed document type.');
        return;
      }
      if (selectedFile.size > 1024 * 1024) {
        this.alert.notificationToast('warning','Selected file exceeds the maximum size limit (1MB).');
        return;
      }
        this.IDFrontDoc = selectedFile ? selectedFile.name : 'No file uploaded';
        this.users.uploadKycDoc(selectedFile, 'identity_document_front', this.email).subscribe(data=>{
          this.getUserByEmail(this.email);
        });
    }
  }
  async uploadCompanyReg(event: any) {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
        if (!this.isDocument(selectedFile)) {
          this.alert.notificationToast('warning','Selected file is not an allowed document type.');
          return;
        }
        if (selectedFile.size > 1024 * 1024) {
          this.alert.notificationToast('warning','Selected file exceeds the maximum size limit (1MB).');
          return;
        }
        this.CompanyRegLetter = selectedFile ? selectedFile.name : 'No file uploaded';
        this.users.uploadKycDoc(selectedFile, 'company_registration', this.email).subscribe(data=>{
          this.getUserByEmail(this.email);
        });
    }
  }
  async uploadProofOfaddress(event: any) {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
        if (!this.isDocument(selectedFile)) {
          this.alert.notificationToast('warning','Selected file is not an allowed document type.');
          return;
        }
        if (selectedFile.size > 1024 * 1024) {
          this.alert.notificationToast('warning','Selected file exceeds the maximum size limit (1MB).');
          return;
        }
        this.Proof_of_address = selectedFile ? selectedFile.name : 'No file uploaded';
        this.users.uploadKycDoc(selectedFile, 'proof_of_address', this.email).subscribe(data=>{
          this.getUserByEmail(this.email);
        });
    }
  }
  async uploadCompanyLetter(event: any){
    const selectedFile = event.target.files[0];
    if (selectedFile) {
        if (!this.isDocument(selectedFile)) {
          this.alert.notificationToast('warning','Selected file is not an allowed document type.');
          return;
        }
        if (selectedFile.size > 1024 * 1024) {
          this.alert.notificationToast('warning','Selected file exceeds the maximum size limit (1MB).');
          return;
        }
        this.company_letter = selectedFile ? selectedFile.name : 'No file uploaded';
        this.users.uploadKycDoc(selectedFile, 'company_letter', this.email).subscribe(data=>{
          this.getUserByEmail(this.email);
        });
    }
  }
  onInputFocus(inputName: keyof InputClickStatus) {
    this.isInputClicked[inputName] = true;
  }
  toggleHeader(header: string) {
    this.activeHeader = header;
  }
  onEditClick() {
    this.isEditClicked = !this.isEditClicked;
    if(this.isEditClicked){
      this.isEditClickedAlternate = false;
      this.activeButton = 'maindetails';
    }
  }
  onEditClickAlternate() {
    this.isEditClickedAlternate = !this.isEditClickedAlternate;
    if(this.isEditClickedAlternate){
      this.isEditClicked = false;
      this.activeButton = 'alternate';
    }
  }
  onSaveClick() {
    const currentPassword = this.passwordForm.get('currentPassword')?.value;
    const newPassword = this.passwordForm.get('newPassword')?.value;
    this.users.loggedInuserChangePassword(this.loggedInUser.email, currentPassword, newPassword)
      .subscribe({
        next: (data: any) => {
          this.alert.notificationToast('success', data.message);
          this.getUserByEmail(this.email);
        },
        error: (error: any) => {
          console.error('Error:', error);
          this.alert.notificationToast('error', error.error.message || 'An error occurred.');
        },
        complete: () => {
          this.auth.logOut();
        }
      });
  }
  getStatusStyles(status: string) {
    if(status){
      if (status === 'Pending approval') {
        return { 'color': '#FE993B' };
      }
      if (status  === 'no status found'){
        return { 'color': '#352b91;'};
      }
      if (status.toLowerCase().includes('not valid')) {
        return { 'color': '#FF361B' };
      }
    }
    return { 'color': '#008F55' };
  }

  showOTPscreen(){
    this.displayOTPScreen = true;
    this.verificationForm = this._formBuilder.group({
      verificationType: ['SMS', Validators.required],
      otpInput:['', Validators.required],
      otpEmail: [{value: this.email, disabled: true}, Validators.email],
      otpNumber: [{value: this.telephone , disabled: true}]
    });
  }
  verifyUSerRegistration(){
    this.users.getUserByEmail(this.email).subscribe({
      next: (data: any) => {
        if(data.user.userType==='admin'){
          // this.loginUser();
          this.showOTPscreen();
        }
        else{
          this.showOTPscreen();
          
        }

      },
      error: (error: any) => {
        this.alert.notificationToast('warning','You must register to be able to login.');
      },
      complete: () => {
        
      }
    });
  }
  requestOtp(){
    this.stopTimer();
    this.users.requestOTPUpdateChanges(this.email,this.email).subscribe({
      next: (regStatus:any) => {
        this.otpFormSubmitted = true;
        this.ref.detectChanges();
        this.startTimer();
        this.alert.notificationToast("success",regStatus.message)
      },
      error: error => {
        console.error('Error handler:', error);
        this.alert.notificationToast("warning",error.error.message)
      },
      complete: () => {
      }
    });
  }
  startTimer() {
    this.intervalId = setInterval(() => {
      this.updateTimer();
    }, 1000);
  }
  stopTimer() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.remainingTime = 300;
    }
  }
  updateTimer() {
    if (this.remainingTime > 0) {
      this.remainingTime--;
      this.updateDisplayTime();
    } else {
    }
  }
  updateDisplayTime() {
    const minutes = Math.floor(this.remainingTime / 60);
    const seconds = this.remainingTime % 60;
    this.displayTime = `${this.padNumber(minutes)}:${this.padNumber(seconds)}`;
  }
  padNumber(num: number): string {
    return num < 10 ? '0' + num : num.toString();
  }
  onOtpChange(value:any){
    this.otpInput = value;
  }
  verifyEmail(){
    if(this.remainingTime == 0){
      this.stopTimer();
      this.remainingTime = 0;
      this.alert.notificationToast('warning','OTP has expired request another one!');
      return;
    }
    this.users.verifyOTP(this.email,this.otpInput).subscribe({
      next: (regStatus:any) => {
        if(regStatus.status){
          this.alert.notificationToast("success",'otp successfully validated');
          localStorage.setItem('token', regStatus.token);
          this.stopTimer();
          // this.loginUser();
          this.onSaveClick();
        }
        else{
          this.alert.notificationToast("warning",'error validating otp');
        }
      },
      error: error => {
        console.error('Error handler:', error);
        this.alert.notificationToast("warning",error.error.message)
      },
      complete: () => {
      }
    });
  }
  
}
