<div  class="status-container">
    <div class="content">
        <img src="../../../assets/logo.svg" alt="Logo">
        <div class="message">
            <h3 >
                Liveness Notice
            </h3>
            <p>
            Before proceeding to the liveness check, please click Accept to confirm your active participation in the verification process. 
            This step ensures the accuracy and security of your identity verification. 
            Thank you for your cooperation.
            </p>
        </div>
        <button type="button" class="btn btn-primary" (click)="navigateToLiveness()"> Accept</button>
    </div>
</div>