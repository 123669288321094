<div class="chat-container">
    <h2 id="customer-enquiries">Customer Enquiries</h2>
    <div class="row mt-4 account-screen" id="personal-details">
        <div class="col-3 messages-container">
            <div class="chat-message-header">
                <h2 id="chat-message-header">Tickets</h2>
                <div class="search-container">
                  <div class="form-group has-search">
                      <span class="fa fa-search form-control-feedback"></span>
                      <input type="text" class="form-control" placeholder="Search messages" [(ngModel)]="searchTerm">
                  </div>
                </div>
            </div>
            <div class="control-messages-scroll">
              <div class="chat-message" *ngFor="let enquiry of enquiries | filter: searchTerm" (click)="setSelectedChat(enquiry)" [class.selected]="enquiry === selectedChat">
                <!-- <div class="profile-picture">
                  <img src="../../assets/placeholder.jpg" alt="Profile Picture">
                </div> -->
                <div class="profile-picture" *ngIf="enquiry?.profile_picture === 'none'">
                  <img src="../../assets/placeholder.jpg" alt="Profile Picture">
                </div>
                <div class="profile-picture" *ngIf="enquiry?.profile_picture !== 'none'">
                  <img src="{{enquiry?.profile_picture}}" alt="Profile Picture">
                </div>
                <div class="message-details">
                  <div class="message-header">
                    <h5>Ticket #{{enquiry._id}}</h5>
                    <!-- <span class="unread-dot" *ngIf="enquiry.unread"></span> -->
                    <span class="check-circle" *ngIf="enquiry?.resolved"><mat-icon id="check-circle">check_circle</mat-icon></span>
                  </div>
                  <div class="message-footer">
                    <p class="paragraph-content">{{ enquiry.subject }}</p>
                    <span class="time">{{ formatDateForSubject(enquiry.date) }}</span>
                  </div>
                </div>
            </div>
            </div>

        </div>
        <div class="col-8 personal-details-container">
            <div class="header">
              <div class="user-username-container">
                <div class="profile-picture-header" *ngIf="selectedChat?.profile_picture === 'none'">
                  <img src="../../assets/placeholder.jpg" alt="Profile Picture">
                </div>
                <div class="profile-picture-header" *ngIf="selectedChat?.profile_picture !== 'none'">
                  <img src="{{selectedChat?.profile_picture}}" alt="Profile Picture">
                </div>
                <div class="subject" *ngIf="selectedChat">
                    <p>Ticket number - {{selectedChat?._id}}</p>
                </div>
              </div>
                <div class="resolve-ticket-button" *ngIf="!selectedChat?.resolved">
                  <button *ngIf="selectedChat" button type="button" class="btn btn-success custom-button" (click)="resolveTicket()">Resolve Ticket</button>
                </div>
            </div>
            <hr *ngIf="selectedChat">
              <p id="sender-company-name" *ngIf="selectedChat && selectedChat?.company !== ''" >Sender - {{selectedChat?.company}}</p>
              <p id="sender-company-name" *ngIf="selectedChat && selectedChat?.company === ''" >Sender - {{selectedChat?.username}}</p>
            <hr>
            <div class="my-messages" #messageContainer *ngIf="selectedChat">
                <div class="imessage" >
                  <span class="date-time">{{ formatDateTime(selectedChat?.date) }}</span>
                  <span class="label-chat-sender-receiver">Subject</span>
                  <p class="from-them margin-b_one">{{selectedChat?.message || 'no messages in chat'}}</p>
                  <span class="label-chat-sender-receiver"  *ngIf="selectedChat?.attachment != ''">attachment</span>
                  <p class="from-them margin-b_one" *ngIf="selectedChat?.attachment != ''"><img width="300px" height="300px" src="{{selectedChat?.attachment}}"/></p>
                </div>  
                <div class="imessage" *ngFor="let reply of selectedChat?.replies">
                  <span class="label-chat-sender-receiver" *ngIf="reply.from == 'me'">{{selectedChat?.username}}</span>
                  <span class="label-chat-sender-receiver-admin" *ngIf="reply.from == 'admin'">admin</span>
                  <p class="from-them margin-b_one" *ngIf="reply.from == 'me'">{{reply.message}}</p>
                
                  <p class="from-me margin-b_one" *ngIf="reply.from == 'admin'">{{reply.message}}</p>
                </div>
                <div class="chat-bubble-typing my-3" *ngIf="selectedChat?.typing">
                  <img src="../../assets/gif/circles-menu.gif" alt="User is typing">
                </div>
            </div>
            <div class="reply-input" *ngIf="selectedChat && !selectedChat?.resolved" >
              <input [(ngModel)]="replyMessage" type="text" placeholder="Type your reply..." (input)="onTyping()">
              <div class="sent-container" (click)="sendReply()">
                <mat-icon class="sent-container-icon">send</mat-icon>
              </div>
            </div>
            <div class="reply-input-ticket-resolved" *ngIf="selectedChat?.resolved">
              <p id="ticket-is-resolved">The ticket # {{selectedChat._id}} has been resolved ...</p>
            </div>
        </div>
    </div>
</div>

