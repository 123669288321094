import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DatabasesService {
  private token = localStorage.getItem('token');
  private baseUrl = environment.baseUrl;
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.token}`
    })
  };
  constructor(private http: HttpClient) { }
  addBillboard(markerData: any) {
    const url = `${this.baseUrl}/save/billboard`;
    return this.http.post(url, markerData, this.httpOptions);
  }
  updateBillboard(markerData:any){
    const url = `${this.baseUrl}/update/billboard`;
    return this.http.post(url, markerData, this.httpOptions);
  }
  getBillboard(){
    const url = `${this.baseUrl}/retrieve/billboard`;
    return this.http.get(url, this.httpOptions);
  }
  deleteBillboard(id:any){
    const url = `${this.baseUrl}/delete/billboard/${id}`;
    return this.http.delete(url, this.httpOptions);
  }
  deletePointsOfinterest(id:any){
    const url = `${this.baseUrl}/delete/pointsOfinterest/${id}`;
    return this.http.delete(url, this.httpOptions);
  }
  addCustomPointsOfinterest(pointsofinterest: any){
    const url = `${this.baseUrl}/add/PointOfinterest`;
    return this.http.post(url, pointsofinterest, this.httpOptions);
  }
  getCustomPointsOfinterest(){
    const url = `${this.baseUrl}/retrieve/PointOfinterest`;
    return this.http.get(url, this.httpOptions);
  }
}
