<div class="container">
    <div class="form-containers">
        <div class="container flex-column" style="padding: 10px;">
            <h2>Search Address</h2>
            <div class="search-container">
              <div class="search-input">
                <input type="text" [(ngModel)]="input" (keyup.enter)="onInputChange()" placeholder="Enter an address" />
                <mat-icon class="search-icon" (click)="onInputChange()">search</mat-icon>
              </div>
              <ul class="suggestions" *ngIf="showSuggestions && predictions.length > 0">
                <li *ngFor="let prediction of predictions">
                  <p (click)="onSuggestionSelect($event, prediction)">{{ prediction.description }}</p>
                </li>
              </ul>
            </div>
        </div>
        <div class="form-container">
            <h1>Billboard</h1>
            <p>Provide some information about this place. If this place is added to the map, it will appear publicly.</p>
            <div class="row">
                <div class="col-12 col-md-3" >
                    <mat-form-field appearance="outline" >
                    <mat-label>Place Name (Required)</mat-label>
                    <input matInput  [(ngModel)]="markerLabel" required>
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-3">
                    <mat-form-field appearance="outline" >
                        <mat-label>Additional information (Required)</mat-label>
                        <input matInput [(ngModel)]="markerInfo" name="markerInfo" required>
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-3">
                    <mat-form-field appearance="outline">
                        <mat-label>Latitude (Required)</mat-label>
                        <input matInput [(ngModel)]="newLatitude" name="newLatitude" required>
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-3">
                    <mat-form-field appearance="outline">
                        <mat-label>Longitude (Required)</mat-label>
                        <input matInput [(ngModel)]="newLongitude" name="newLongitude" required>
                    </mat-form-field>
                </div>
            </div>
            <h2>Place Image</h2>
            <p>Add a helpful image like place store, notice or sign.</p>
            <div class="row">
                <div class="col-12 col-md-3">
                    <label for="file-input-place-image" class="file-label" >
                        <img src="assets/add_a_photo.png" alt="Upload Icon" class="file-icon" width="14px" height="14px" /> Add Image
                    </label>
                    <input type="file" id="file-input-place-image" class="file-input" (change)="onFileSelected($event)" required/>
                </div>
            </div>
            <button class="mt-4" name="add-marker-button" (click)="addMarker()">Add Billboard</button>
        </div>
        
        <div class="form-container">
            <h1>Point Of interest</h1>
            <p>Provide some information about this place. If this place is added to the map, it will appear publicly.</p>
            <div class="row">
                <div class="col-12 col-md-3">
                    <mat-form-field appearance="outline" >
                        <mat-label>Place Name (Required)</mat-label>
                        <input matInput [(ngModel)]="pointOfinterestName" name="pointOfinterestName" required>
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-3">
                    <mat-form-field appearance="outline" >
                        <mat-label>Latitude (Required)</mat-label>
                        <input matInput [(ngModel)]="Latitude" name="Latitude" required>
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-3">
                    <mat-form-field appearance="outline" >
                        <mat-label>Longitude (Required)</mat-label>
                        <input matInput [(ngModel)]="Longitude" name="Longitude" required>
                    </mat-form-field>
                </div>
            </div>
            <h2>Place Image & Logo</h2>
            <p>Add a helpful image and logo of the point of interest.</p>
            <div class="row">
                <div class="col-1" style="margin-right: 35px;">
                    <label for="file-input-poi-image" class="file-label" >
                        <img src="assets/add_a_photo.png" alt="Upload Icon" class="file-icon" width="14px" height="14px" /> Add Image
                    </label>
                    <input type="file" id="file-input-poi-image" class="file-input" (change)="onPointsOfFileSelected($event)" required/>
                </div>
                <div class="col-2">
                    <label for="file-input-poi-logo" class="file-label-longer-text" >
                        <img src="assets/add_a_photo.png" alt="Upload Icon" class="file-icon" width="14px" height="14px" /> Add Logo Image
                    </label>
                    <input type="file" id="file-input-poi-logo" class="file-input" (change)="onIcon($event)" required />
                </div>
            </div>
            <button class="mt-4" name="add-marker-button" (click)="addPointsOfinterest()">Add Point of interest</button>
        </div>
        <div class="mock-container"></div>
</div>

<div id="map-container" [ngSwitch]="currentMapStyle">
    <div *ngSwitchDefault>
        <div id="map"></div>
    </div>
</div>
</div>
