import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private baseUrl = environment.baseUrl;

  private loggedInUser: any = undefined;
  private loggedInuserId: string | undefined;
  public logginInUser_bsub = new BehaviorSubject(this.loggedInUser);
  constructor(private http: HttpClient,private router: Router) { }
  
  login(email: any) {
    const url = `${this.baseUrl}/auth/login`
    const requestBody = {
      email: email
    };
    return this.http.post(url, requestBody);
  }
  public isAuthenticated(): boolean {
    if (!sessionStorage["usr"]) {
      return false;
    }
    return true;
  }
  public logOut() {
    sessionStorage.clear();
    localStorage.clear();
    this.router.navigate(['/login']);
  }
  sessionSet(key: string, value: any, expirationInMin: number) {
    let expirationDate = new Date(new Date().getTime() + (60000 * expirationInMin))
      let newValue = {
      value: value,
      expirationDate: expirationDate.toISOString()
    }
    window.sessionStorage.setItem(key, JSON.stringify(newValue));
  }
  sessionGet(key: string) {
    let stringValue = window.sessionStorage.getItem(key)
      if (stringValue !== null) {
        let value = JSON.parse(stringValue)
          let expirationDate = new Date(value.expirationDate)
          if (expirationDate > new Date()) {
            return value.value
          } else {
            window.sessionStorage.removeItem(key)
            this.logOut();
          }
      }
      return null
  }
  public setLoggedInUserId(user: any) {
    this.loggedInuserId = user;
    this.sessionSet('usr', this.loggedInuserId,120);
  }
  public setLoggedInUserIdPermanent(user: any) {
    this.loggedInuserId = user;
    this.sessionSet('usr', this.loggedInuserId,80000);
  }
  public getLoggedInUserId() {
    return this.sessionGet('usr');
  }

}
