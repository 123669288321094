<div class="mobile-border"></div>
<div class="app-container">
  <div class="header">
    <div class="header-icons">
      <mat-menu  class="custom-menu" #notificationMenu="matMenu">
        <div *ngFor="let notification of myNotifications">
          <div class="notification-menu" (click)="setActivePage('account')" (click)="readNotification(notification.email,notification.content)" [ngClass]="{'unread': notification.unread}">
            <div class="notification-container">
              <div>
                <div class="notification-dot"></div>
              </div>
              <div class="notification-content">
                <p class="notification-message">{{notification.content}}</p>
                <p class="notification-date"> {{ notification.date | date:'yyyy-MM-dd' }}</p>
              </div>
            </div>
          </div>
        </div>
      </mat-menu>
      <span class="notification-bell" [matMenuTriggerFor]="notificationMenu">
        <mat-icon>notifications</mat-icon>
        <span *ngIf="unreadCount > 0" class="badge">{{ unreadCount }}</span>
      </span>
      <img class="responsive-logo" src='../../assets/logo.svg'>
      <img src={{profileUrl}} width="30px" height="30px" class="profile-image-container" alt="" [matMenuTriggerFor]="profileMenu">
      <span id="loggedinuserName"  [matMenuTriggerFor]="profileMenu">{{firstname}} {{lastname}}</span>
      <mat-menu  class="custom-menu" #profileMenu="matMenu">
        <div class="profile-menu">
          <div class="profile-image">
            <img src={{profileUrl}} width="30px" height="30px" class="profile-image-container" alt="">
          </div>
          <div class="Profile-user-details">
            <span id="loggedinuserPerson">{{ firstname }} {{ lastname }}</span>
            <span id="email">{{ email }}</span>
          </div>
        </div>
        <div class="profile-details" (click)="navigateToAccountPage()">
          <div class="profile-image">
            <mat-icon width="30px" height="30px"> person </mat-icon>
          </div>
          <div class="Profile-user-details"  >
            <span id="loggedinuserPerson">My Account</span>
            <span id="account-settings">Account setting & more</span>
          </div>
        </div>
        <div class="button-container">
          <button type="button" class="btn btn-primary mt-4 custom-button" (click)="logOutUser()">logout</button>
        </div>
      </mat-menu>
      
      <mat-icon class="dropdown" [matMenuTriggerFor]="profileMenu" >keyboard_arrow_down</mat-icon>
      
    </div>
  </div>
  <div class="main-content">
    <div class="left-menu">
      <div class="logo-container">
        <img src="./../../assets/menus/side-menu-icon.svg" alt="Logo">
      </div>
      <ul class="mobile-menu">
        <li [class.active]="activePage === 'addLocation'" *ngIf="userType != 'individual' "  (click)="setActivePage('addLocation')">
          <mat-icon>add_location_alt</mat-icon> Add location
        </li>
        <li [class.active]="activePage === 'billboards'" (click)="setActivePage('billboards')">
          <mat-icon>location_on</mat-icon> Billboards
        </li>
        <li *ngIf="userType != 'individual' &&  userType != 'company'" [class.active]="activePage === 'users'" (click)="setActivePage('users')">
          <mat-icon>people_alt</mat-icon> Users
        </li>
        <li [class.active]="activePage === 'support'" *ngIf="userType != 'admin' " (click)="setActivePage('support')">
          <mat-icon>support</mat-icon> Support
        </li>
        <li [class.active]="activePage === 'enquiries'" *ngIf="userType == 'admin' " (click)="setActivePage('enquiries')">
          <mat-icon>question_answer</mat-icon> Enquiries
        </li>
        <li [class.active]="activePage === 'account'" (click)="setActivePage('account')">
          <mat-icon>person</mat-icon> My Account
        </li>
        <p style="border-bottom: 2px solid #fff; left: 0" class="mt-2"></p>
        <li style="left: 30px;" data-bs-toggle="modal" data-bs-target="#logout">
          <mat-icon>logout</mat-icon> Logout
        </li>
      </ul>
    </div>
    <div class="right-content">
      <app-add-location-page *ngIf="activePage === 'addLocation'"  [active]="activePage"></app-add-location-page>
      <app-billboards-page *ngIf="activePage === 'billboards'"></app-billboards-page>
      <app-users-page *ngIf="activePage === 'users'"></app-users-page>
      <app-account-page *ngIf="activePage === 'account'"></app-account-page>
      <app-support-channel *ngIf="activePage === 'support'"></app-support-channel>
      <app-admin-chat-channel *ngIf="activePage === 'enquiries'"></app-admin-chat-channel>
    </div>
  </div>
</div>
<div class="modal fade" id="logout" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="exampleModalLabel">Logout</h1>
        <div data-bs-theme="dark">
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
      </div>
      <div class="modal-body">
        Are you sure you want to logout?
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" data-bs-dismiss="modal" (click)="logOutUser()">Logout</button>
        <button type="button" class="btn btn-outline-primary" data-bs-dismiss="modal">Cancel</button>
      </div>
    </div>
  </div>
</div>
