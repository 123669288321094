import { Component, OnInit , Renderer2 , NgZone } from '@angular/core';
import * as L from 'leaflet';
import { DatabasesService } from 'src/services/databases.service';
import { SateliteImageService } from 'src/services/satelite-image.service';
import { StreetViewService } from 'src/services/street-view.service';
import { TrafficDataService } from 'src/services/traffic-data.service';
import { UploadfilesService } from 'src/services/uploadfiles.service';
import { environment } from 'src/environments/environment';
import { ScrapperService } from 'src/services/scrapper.service';
import axios from 'axios';

declare const navigator: Navigator;
declare var google: any;

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  map!: L.Map;
  draggableMarker!: L.Marker;
  markerLabel!: HTMLElement;
  markerInfo!: string;
  markers = new Array();
  newLabel: any;
  newId: any;
  newInfo: any;
  newLatitude: any;
  newLongitude: any;
  pointOfinterestName: any;
  pointOfinterestAddress: any;
  baseUrl = environment.baseUrl;
  selectedFile: File | undefined;
  selectedFilePOI: File | undefined;
  selectedFileICON: File | undefined;
  Latitude: any;
  Longitude: any;
  centerLatitude: any;
  centerLongitude: any;
  currentMapStyle: string = 'osm-bright';
  isDragging: boolean = false;
  startX: number = 0;
  startY: number = 0;
  imagePositionX: number = 0;
  imagePositionY: number = 0;
  imageUrl: any;
  file: any;
  address: any;
  minZoomToShowMarker: number = 16;
  POI_selectedFile: any;
  icon_selectedFile: any;
  input: string = '';
  predictions: any[] = [];
  compressedImage: File | undefined;
  showSuggestions: boolean = false;
  selectedAddress: string = '';
  selectedPrediction: any = null;
  newSpeed: any = 'N/A'
  selectedCoordinates: { lat: number, lng: number } | null = null;
  initialX: number = 0;
  initialY: number = 0;
  startOffsetX: number = 0;
  startOffsetY: number =0;
  constructor(private db: DatabasesService, 
              private satellite: SateliteImageService, 
              private streets: StreetViewService,
              private traffic: TrafficDataService, 
              private uploadService: UploadfilesService,
              private scrapper: ScrapperService,
              private renderer: Renderer2,
              private ngZone: NgZone
              ) 
  {
    this.onLabelMouseMove = this.onLabelMouseMove.bind(this);
    this.onLabelMouseUp = this.onLabelMouseUp.bind(this);
    this.onLabelMouseDown = this.onLabelMouseDown.bind(this);
  }
  ngOnInit(): void {
    this.db.getBillboard().subscribe((POIObjectData:any)=>{
      this.markers = POIObjectData.pointOfinterest;
      this.initMap();
    })
  }
  ngOnDestroy(): void {
    this.map.remove();
  }


  onInputChange() {
    this.traffic.autocomplete(this.input).subscribe(data => {
      this.predictions = data.predictions;
      this.showSuggestions = true;
    });
  }

  onSuggestionSelect(event: Event, prediction: any) {
    event.preventDefault();
    this.input = prediction.description;
    this.selectedPrediction = prediction;
    this.showSuggestions = false;
    this.traffic.getPlaceDetails(prediction.place_id).subscribe(details => {
      if (details && details.result && details.result.geometry && details.result.geometry.location) {
        const location = details.result.geometry.location;
        this.selectedCoordinates = { lat: location.lat, lng: location.lng };
        this.newLatitude = location.lat;
        this.newLongitude = location.lng ;
        this.Latitude = location.lat;
        this.Longitude = location.lng;
      }
    });
  }

  async onFileSelected(event: any) {
    this.selectedFile = event.target.files[0];
    if (this.selectedFile) {
          this.compressedImage =  await this.compressImage(this.selectedFile);;
          this.uploadService.uploadFile(this.compressedImage ).subscribe(
            response => {
              this.file = response.fileId;
            },
            error => {
              console.error(error);
            }
          );
    }
  }
  async imageSize(url:any) {
    const img = document.createElement("img");
    const promise = new Promise((resolve, reject) => {
      img.onload = () => {
        const width  = img.naturalWidth;
        const height = img.naturalHeight; 
        resolve({width, height});
      };
      img.onerror = reject;
    });
    img.src = url;
    return promise;
  }
  async compressImage(selectedImage: File): Promise<File> {
    return new Promise(async(resolve, reject) => {
      const image = new Image();
      image.src = URL.createObjectURL(selectedImage);
      const imageDimensions:any = await this.imageSize(image.src);
      image.onload = () => {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');

        if (context) {
          canvas.width = 640; 
          canvas.height = 640; 

          context.drawImage(image, 0, 0, canvas.width, canvas.height);

          canvas.toBlob(
            (blob) => {
              if (blob) {
                const compressedFile = new File([blob], selectedImage.name, {
                  type: 'image/jpeg',
                  lastModified: Date.now(),
                });
                resolve(compressedFile);
              } else {
                reject(new Error('Error creating compressed blob.'));
              }
            },
            'image/jpeg',
            0.6 
          );
        } else {
          reject(new Error('Canvas context is null.'));
        }
      };

      image.onerror = (error) => {
        reject(error);
      };
    });
  }
  async onPointsOfFileSelected(event: any) {
    this.selectedFilePOI = event.target.files[0];
    if (this.selectedFilePOI) {
      try {
        this.compressedImage = await this.compressImage(this.selectedFilePOI);
        this.uploadService.uploadFile(this.compressedImage).subscribe(
          response => {
            this.POI_selectedFile = response.fileId;
          },
          error => {
            console.error(error);
          }
        );
      } catch (error) {
        console.error(error);
      }
    }
  }
  async onIcon(event: any) {
    this.selectedFileICON = event.target.files[0];
    if (this.selectedFileICON) {
      try {
        this.compressedImage = await this.compressImage(this.selectedFileICON);
        const response = await this.uploadService.uploadFile(this.compressedImage).toPromise();
        this.icon_selectedFile = response.fileId;
      } catch (error) {
        console.error(error);
      }
    }
  }
  initMap(): void {
    this.map = L.map('map');
        this.map.setView([-26.17332, 28.08399], 12.56);
        L.tileLayer(`https://ad-drop-maps-api-dev.shayelasolutions.co.za/styles/${this.currentMapStyle}/{z}/{x}/{y}.png`, {
          attribution: 'Map data &copy; <a href="https://www.kalevamedia.com/">Kaleva Media Maps</a> contributors',
          maxZoom: 19,
        }).addTo(this.map);
        this.map.on('click', (event) => {
          this.newLatitude = event.latlng.lat;
          this.newLongitude = event.latlng.lng;
          this.Latitude = event.latlng.lat;
          this.Longitude = event.latlng.lng;
        });
        const markerGroup = L.layerGroup().addTo(this.map);
        this.map.on('mousedown', (e) => {
          let clickHoldTimer: number;
          let satelliteButton: HTMLButtonElement | null = null;
          clickHoldTimer = window.setTimeout(() => {
            const lat = e.latlng.lat;
            const lng = e.latlng.lng;
            const popupContent = `
              <div>
                <p>Satellite image loading...</p>
              </div>
            `;
            L.popup()
              .setLatLng([lat, lng])
              .setContent(popupContent)
              .openOn(this.map);
            this.satellite.generateSatelliteImage(lat, lng).subscribe((data: any) => {
              this.imageUrl = data.imageUrl;
              const imageBounds = L.latLngBounds(
                L.latLng(lat - 0.001, lng - 0.001),
                L.latLng(lat + 0.001, lng + 0.001)
              );
              L.imageOverlay(data.imageUrl, imageBounds).addTo(this.map);
              this.map.fitBounds(imageBounds);
            });
          }, 5000);
          this.map.on('mouseup', () => {
            clearTimeout(clickHoldTimer);
            if (satelliteButton) {
              this.map.closePopup();
            }
          });
        });
        this.markers.forEach(async (marker) => {
        const { _id, label, info, latitude, longitude , pointsOfInterest, file , address, income} = marker;
        const icon = L.icon({
          iconUrl: 'assets/icons/marker-icon-2x.png',
          iconRetinaUrl: 'assets/icons/marker-icon-2x.png',
          shadowUrl: 'assets/icons/marker-shadow.png',
          iconSize: [41, 41],
          iconAnchor: [12, 41],
          popupAnchor: [1, -34],
        });
        
        const draggableMarker = L.marker([latitude, longitude], {
          draggable: true,
          icon: icon,
        }).addTo(this.map);
        this.map.on('zoomend', () => {
          this.updateMarkers(pointsOfInterest, markerGroup, this.map);
        });
        const congestedData = await this.traffic.getCongestedData(latitude, longitude).toPromise();
        const popupContent = await this.createPopupContent(marker, congestedData);
        const customOptions = {
          'className': 'customPopup'
        }
        draggableMarker.bindPopup(popupContent,customOptions);
        this.displayPointsofInterest();
        draggableMarker.on('dragend', (event) => {
          const marker = event.target;
          const latLng = marker.getLatLng();
          const updatedLatitude = latLng.lat;
          const updatedLongitude = latLng.lng;
          const updatedMarkerObject = {
            _id: _id,
            label: label,
            info: info,
            latitude: updatedLatitude,
            longitude: updatedLongitude,
            file: file,
          };
          this.traffic.getPointsOfInterest(updatedLatitude,updatedLongitude,1000).subscribe((poi:any)=>{
            const mergedObject = { ...updatedMarkerObject, ...poi };
            poi.pointsOfInterest.forEach((point:any) => {
              const icon = L.icon({
                iconUrl: 'assets/icons/location-pin.png',
                iconRetinaUrl: 'assets/icons/location-pin.png',
                shadowUrl: point.icon_background_color,
                iconSize: [15, 15],
                iconAnchor: [12, 41],
                popupAnchor: [1, -34],
              });
              const marker = L.marker([point.geometry.location.lat, point.geometry.location.lng], {
                draggable: false,
                icon: icon,
              }).addTo(this.map);
              const { name, rating, photos, vicinity } = point;
              let popupContent = `
              <div class='p-2'>
                <h3>${name}</h3>
                <p>Rating: ${rating}</p>
                <p>${vicinity}</p>
              </div>
            `;
              marker.bindPopup(popupContent);
            });
            this.traffic.getAddress(draggableMarker.getLatLng().lat,draggableMarker.getLatLng().lng).subscribe(async(addr:any)=>{
              this.address = addr.items[0]?.address?.label || '';

              let addressParts =  this.address.split(', ');
              let estimatedIncome = "N/A";
              if (addressParts && addressParts.length > 1) {
                const Link = await this.scrapper.scrapeLinks(addressParts).toPromise();
                if (Link.SelectedLink && Link.SelectedLink.trim() !== "") {
                  const economic = await this.scrapper.getEconomicProfile(Link.SelectedLink).toPromise();
                  const economicProfileString = economic.economic_profile;
                  const economicProfile = JSON.parse(economicProfileString.replace(/'/g, '"'));
                  if (economicProfile && economicProfile.estimatedIncome) {
                    estimatedIncome = economicProfile.estimatedIncome;
                  }
                }
              }
              mergedObject['address'] = this.address;
              mergedObject['income'] = estimatedIncome;
              this.db.updateBillboard(mergedObject).subscribe(async ()=>{
                const congestedData = await this.traffic.getCongestedData(latitude, longitude).toPromise();
                const popupContent =await this.createPopupContent(mergedObject, congestedData);
                const customOptions = {
                  'className': 'customPopup'
                }
                draggableMarker.bindPopup(popupContent,customOptions);
              })
            })
          });
        });
        draggableMarker.on('popupopen', () => {
          const deleteButton = document.querySelector('.delete-marker');
          const streetViewButton = document.querySelector('.view-marker');
          const satelliteViewButton = document.querySelector('.satellite-marker');
          const clearSatellite = document.querySelector('.satellite-clear');
          if (deleteButton) {
            deleteButton.addEventListener('click', () => {
              draggableMarker.closePopup();
              this.map.removeLayer(draggableMarker);
              this.deleteMarker(_id);
            });
          }
          if (streetViewButton) {
            streetViewButton.addEventListener('click', () => {
              draggableMarker.closePopup();
              this.streets.getStreetViewLink(draggableMarker.getLatLng().lat,draggableMarker.getLatLng().lng).subscribe((data: any)=>{
                window.open(data.googleStreetLink, '_blank');
              })
            });
          }
          if (satelliteViewButton) {
            satelliteViewButton.addEventListener('click', () => {
              draggableMarker.closePopup();
              this.satellite.generateSatelliteImage(draggableMarker.getLatLng().lat,draggableMarker.getLatLng().lng).subscribe((data: any)=>{
                this.imageUrl = data.imageUrl;
                var southWest = L.latLng(draggableMarker.getLatLng().lat - 0.001, draggableMarker.getLatLng().lng - 0.001);
                var northEast = L.latLng(draggableMarker.getLatLng().lat + 0.001, draggableMarker.getLatLng().lng + 0.001);
                var imageBounds = L.latLngBounds(southWest, northEast);
                L.imageOverlay(data.imageUrl, imageBounds).addTo(this.map);
                this.map.fitBounds(imageBounds);
              })
            })
          }
          if (clearSatellite) {
            clearSatellite.addEventListener('click', () => {
              draggableMarker.closePopup();
              this.switchToDefaultStyle();
            })
          }
          this.makePopupDraggable(_id);
          
        });
        this.map.on('dragend', () => {
          draggableMarker.closePopup();
        });
        draggableMarker.on('popupclose', () => {
          this.initialX= 0;
          this.initialY = 0;
          this.startOffsetX = 0;
          this.startOffsetY = 0;
        })
        const updateCoordinates = () => {
          const centerLatLng = this.map.getCenter();
          this.centerLatitude = centerLatLng.lat;
          this.centerLongitude = centerLatLng.lng;
        };
        this.map.on('move', updateCoordinates);
      });

  }

  async addMarker(): Promise<void> {
    if (
      !this.markerLabel ||
      !this.markerInfo ||
      !this.newLongitude ||
      !this.newLatitude ||
      isNaN(this.newLatitude) ||
      isNaN(this.newLongitude) ||
      this.newLatitude < -90 ||
      this.newLatitude > 90 ||
      this.newLongitude < -180 ||
      this.newLongitude > 180 ||
      !this.selectedFile ||
      this.selectedFile.length === 0
    ) {
      alert('Please fill in all the required details correctly and select at least one file.');
      return;
    }
    else{
        const icon = L.icon({
          iconUrl: 'assets/icons/marker-icon-2x.png',
          iconRetinaUrl: 'assets/icons/marker-icon-2x.png',
          shadowUrl: 'assets/icons/marker-shadow.png',
          iconSize: [41, 41],
          iconAnchor: [12, 41],
          popupAnchor: [1, -34],
        });
        const draggableMarker = L.marker([this.newLatitude, this.newLongitude], {
            draggable: true,
            icon: icon,
        }).addTo(this.map);
        const markerGroup = L.layerGroup().addTo(this.map);
        const congestedData = await this.traffic.getCongestedData(draggableMarker.getLatLng().lat, draggableMarker.getLatLng().lng).toPromise();
        const address = await this.traffic.getAddress(draggableMarker.getLatLng().lat, draggableMarker.getLatLng().lng).toPromise();
        const typeOfAddress = address as { items: any[] };
        let addressParts =  typeOfAddress?.items[0]?.address?.label.split(', ');
        let estimatedIncome = "N/A";
        if (addressParts && addressParts.length > 1) {
          const Link = await this.scrapper.scrapeLinks(addressParts).toPromise();
          if (Link.SelectedLink && Link.SelectedLink.trim() !== "") {
            const economic = await this.scrapper.getEconomicProfile(Link.SelectedLink).toPromise();
            const economicProfileString = economic.economic_profile;
            const economicProfile = JSON.parse(economicProfileString.replace(/'/g, '"'));
            if (economicProfile && economicProfile.estimatedIncome) {
              estimatedIncome = economicProfile.estimatedIncome;
            }
          }
        }
        let marker = {
          address : typeOfAddress?.items[0]?.address?.label,
          latitude: draggableMarker.getLatLng().lat,
          longitude:  draggableMarker.getLatLng().lng,
          _id: this.newId,
          label: this.markerLabel,
          info: this.markerInfo,
          file: this.file,
          income: estimatedIncome,
        }
        const popupContent = await this.createPopupContent(marker, congestedData);
        const customOptions = {
          'className': 'customPopup'
        }
        draggableMarker.bindPopup(popupContent,customOptions);
        draggableMarker.on('dragend', (event) => {
          this.traffic.getPointsOfInterest(draggableMarker.getLatLng().lat,draggableMarker.getLatLng().lng,1000).subscribe((poi:any)=>{
            let markerData = {
              _id: this.newId,
              label: this.markerLabel,
              info: this.markerInfo,
              latitude: draggableMarker.getLatLng().lat,
              longitude: draggableMarker.getLatLng().lng,
              file: this.file
            };
            const mergedObject = { ...markerData, ...poi };
            poi.pointsOfInterest.forEach((point:any) => {
              const icon = L.icon({
                iconUrl: 'assets/icons/location-pin.png',
                iconRetinaUrl: 'assets/icons/location-pin.png',
                shadowUrl: point.icon_background_color,
                iconSize: [15, 15],
                iconAnchor: [12, 41],
                popupAnchor: [1, -34],
              });
              const marker = L.marker([point.geometry.location.lat, point.geometry.location.lng], {
                draggable: false,
                icon: icon,
              }).addTo(this.map);
              const { name, rating, photos, vicinity } = point;
              let popupContent = `
              <div class='p-2'>
                <h3>${name}</h3>
                <p>Rating: ${rating}</p>
                <p>${vicinity}</p>
              </div>
            `;
    
              marker.bindPopup(popupContent);
            });
            this.traffic.getAddress(draggableMarker.getLatLng().lat,draggableMarker.getLatLng().lng).subscribe(async(addr:any)=>{
              this.address = addr.items[0]?.address?.label || '';
  
              let addressParts =  this.address.split(', ');
              let estimatedIncome = "N/A";
              if (addressParts && addressParts.length > 1) {
                const Link = await this.scrapper.scrapeLinks(addressParts).toPromise();
                if (Link.SelectedLink && Link.SelectedLink.trim() !== "") {
                  const economic = await this.scrapper.getEconomicProfile(Link.SelectedLink).toPromise();
                  const economicProfileString = economic.economic_profile;
                  const economicProfile = JSON.parse(economicProfileString.replace(/'/g, '"'));
                  if (economicProfile && economicProfile.estimatedIncome) {
                    estimatedIncome = economicProfile.estimatedIncome;
                  }
                }
              }
              mergedObject['address'] = this.address;
              mergedObject['income'] = estimatedIncome;
              this.db.updateBillboard(mergedObject).subscribe(async (data)=>{
                const congestedData = await this.traffic.getCongestedData(draggableMarker.getLatLng().lat, draggableMarker.getLatLng().lng).toPromise();
                const popupContent =await this.createPopupContent(mergedObject, congestedData);
                const customOptions = {
                  'className': 'customPopup'
                }
                draggableMarker.bindPopup(popupContent,customOptions);
              })
            })
         });
        });
        draggableMarker.on('popupopen', () => {
          const deleteButton = document.querySelector('.delete-marker');
          const streetViewButton = document.querySelector('.view-marker');
          const satelliteViewButton = document.querySelector('.satellite-marker');
          if (deleteButton) {
            deleteButton.addEventListener('click', () => {
              this.deleteMarker(this.newId);
              this.map.removeLayer(draggableMarker);
            });
          }
          if (streetViewButton) {
            streetViewButton.addEventListener('click', () => {
              this.streets.getStreetViewLink(draggableMarker.getLatLng().lat,draggableMarker.getLatLng().lng).subscribe((data: any)=>{
                const newTab = window.open(data.googleStreetLink, '_blank');
                window.open(data.googleStreetLink, '_blank');
              })
            })
          }
          if (satelliteViewButton) {
            satelliteViewButton.addEventListener('click', () => {
              this.satellite.generateSatelliteImage(draggableMarker.getLatLng().lat,draggableMarker.getLatLng().lng).subscribe((data: any)=>{
                this.imageUrl = data.imageUrl;
                var southWest = L.latLng(draggableMarker.getLatLng().lat - 0.001, draggableMarker.getLatLng().lng - 0.001);
                var northEast = L.latLng(draggableMarker.getLatLng().lat + 0.001, draggableMarker.getLatLng().lng + 0.001);
                var imageBounds = L.latLngBounds(southWest, northEast);
                L.imageOverlay(data.imageUrl, imageBounds).addTo(this.map);
                this.map.fitBounds(imageBounds);
              })
            })
          } 
        });
        this.traffic.getAddress(draggableMarker.getLatLng().lat,draggableMarker.getLatLng().lng).subscribe(async (addr:any)=>{
          this.address = addr.items[0]?.address?.label || '';
          let addressParts =  this.address.split(', ');
          let estimatedIncome = "N/A";
          if (addressParts && addressParts.length > 1) {
            const Link = await this.scrapper.scrapeLinks(addressParts).toPromise();
            if (Link.SelectedLink && Link.SelectedLink.trim() !== "") {
              const economic = await this.scrapper.getEconomicProfile(Link.SelectedLink).toPromise();
              const economicProfileString = economic.economic_profile;
              const economicProfile = JSON.parse(economicProfileString.replace(/'/g, '"'));
              if (economicProfile && economicProfile.estimatedIncome) {
                estimatedIncome = economicProfile.estimatedIncome;
              }
            }
          }
          this.traffic.getPointsOfInterest(draggableMarker.getLatLng().lat, draggableMarker.getLatLng().lng, 1000).subscribe((poi: any) => {
            let markerData = {
              label: this.markerLabel,
              info: this.markerInfo,
              file: this.file,
              latitude: draggableMarker.getLatLng().lat,
              longitude: draggableMarker.getLatLng().lng,
              address: this.address,
              income: estimatedIncome
            };
  
            const mergedObject = { ...markerData, ...poi };
            poi.pointsOfInterest.forEach((point: any) => {
              const icon = L.icon({
                iconUrl: 'assets/icons/location-pin.png',
                iconRetinaUrl: 'assets/icons/location-pin.png',
                shadowUrl: point.icon_background_color,
                iconSize: [15, 15],
                iconAnchor: [12, 41],
                popupAnchor: [1, -34],
              });
          
              const marker = L.marker([point.geometry.location.lat, point.geometry.location.lng], {
                draggable: false,
                icon: icon,
              }).addTo(this.map);
              const { name, rating, photos, vicinity } = point;
              let popupContent = `
              <div class='p-2'>
                <h3>${name}</h3>
                <p>Rating: ${rating}</p>
                <p>${vicinity}</p>
              </div>`;
              marker.bindPopup(popupContent);
              markerGroup.addLayer(marker);
            });
            mergedObject['address'] = this.address;
            this.db.addBillboard(mergedObject).subscribe((data: any) => {
              this.newId = data.pointOfinterest._id;
              this.newLabel = data.pointOfinterest.label;
              this.newInfo = data.pointOfinterest.info;
            });
            this.updateMarkers(poi.pointsOfInterest, markerGroup, this.map);
  
          });
        })
    }
  }

  
  onLabelMouseDown(event: MouseEvent): void {
    event.preventDefault();
    window.addEventListener('mousemove', this.onLabelMouseMove);
    window.addEventListener('mouseup', this.onLabelMouseUp);
  }

  onLabelMouseMove(event: MouseEvent): void {
    const labelPosition = this.getRelativePosition(event);
    this.markerLabel.style.left = labelPosition.x + 'px';
    this.markerLabel.style.top = labelPosition.y + 'px';
  }

  addMarkerToMap(marker: any): boolean {
    try {
      marker.addTo(this.map);
      return true;
    } catch (error) {
      return false;
    }
  }
  makePopupDraggable(popupId: string) {
    const popup = document.getElementById(`popup-${popupId}`);
    if (popup) {
      let isDragging = false;

  
      popup.addEventListener("mousedown", (e) => {
        isDragging = true;
        const boundingBox = popup.getBoundingClientRect();
        if(this.initialX == 0 || this.initialY ==0 ){
          this.initialX = boundingBox.left;
          this.initialY = boundingBox.top;
          this.startOffsetX = e.clientX - this.initialX;
          this.startOffsetY = e.clientY - this.initialY;
        }

        popup.style.cursor = "grabbing";
        popup.style.transition = "none"; // Disable transition for smoother dragging
      });
  
      document.addEventListener("mousemove", (e) => {
        if (isDragging) {
          const x = e.clientX - this.startOffsetX;
          const y = e.clientY - this.startOffsetY;
          popup.style.transform = `translate(${x - this.initialX}px, ${y - this.initialY}px)`;
        }
      });
  
      document.addEventListener("mouseup", () => {
        if (isDragging) {
          isDragging = false;
          popup.style.cursor = "grab";
          popup.style.transition = "transform 0.3s ease"; // Enable transition again for smooth animations
        }
      });
    }
  }

  onLabelMouseUp(event: MouseEvent): void {
    window.removeEventListener('mousemove', this.onLabelMouseMove);
    window.removeEventListener('mouseup', this.onLabelMouseUp);
    const mapPosition = this.getRelativePosition(event);
    const latLng = this.map.layerPointToLatLng(L.point(mapPosition.x, mapPosition.y));
    this.draggableMarker.setLatLng(latLng);
  }

  getRelativePosition(event: MouseEvent): { x: number, y: number }{
    const mapDiv = document.getElementById('map');
    if (!mapDiv) return { x: 0, y: 0 };
    const rect = mapDiv.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    return { x, y };
  }

  deleteMarker(markerID: any):string{
    this.db.deleteBillboard(markerID).subscribe((data:any)=>{
      return data;
    });
    return '';
  }

  switchToDefaultStyle() {
    this.map.remove();
    this.currentMapStyle = 'osm-bright';
    this.db.getBillboard().subscribe((POIObjectData:any)=>{
      this.markers = POIObjectData.pointOfinterest;
      this.initMap();
    })
  }

  getAddress(latitude: number,longitude: number){
      this.traffic.getAddress(latitude,longitude).subscribe((addr:any)=>{
        this.address = addr.items[0]?.address?.label || '';
        return this.address
    })
  }
  async createPopupContent(draggableMarker: any, congestedData: any): Promise<string> {
    const { file, label, info, address, latitude, longitude, _id, income, avgSpeed } = draggableMarker;
    const deleteButtonHtml = `<button class="btn btn-danger delete-marker custom-btn" data-id="${_id}"><i class="fas fa-trash" ></i>Remove Billboard</button>`;
    const streetViewButtonHtml = `<button class="btn btn-primary view-marker custom-btn">Street View</button>`;
    const satelliteViewButtonHtml = `<button class="btn btn-success satellite-marker custom-btn">Satellite View</button>`;
    const ClearSatelliteImages = `<button class="btn btn-danger satellite-clear custom-btn">Clear Satellite View</button>`;
    const addressParts = address ? address.split(', ') : [];
    let totalSpeed = 0;
    let averageSpeed: string = 'N/A';
    const fetchSpeedLimit = async () => {
      try {
        const trafficSpeed: any = await this.traffic.getSpeedLimit(latitude, longitude).toPromise();
        averageSpeed = trafficSpeed.speedLimit;
      } catch (error) {
        console.error("Error fetching traffic speed:", error);
      }
    };
    if (avgSpeed == undefined) {
      await fetchSpeedLimit();
    } else {
      const speedArray = avgSpeed;
      if (speedArray && speedArray.length > 0) {
        let validSpeedCount = 0;
  
        for (const speedObj of speedArray) {
          if (speedObj.speed !== undefined && speedObj.speed !== 0) {
            const trimmedSpeed = parseFloat(speedObj.speed);
            if (!isNaN(trimmedSpeed)) {
              totalSpeed += trimmedSpeed;
              validSpeedCount++;
            }
          }
        }
        if (validSpeedCount > 0) {
          averageSpeed = (totalSpeed / validSpeedCount).toFixed(2);
        }
      }
    }
    return `
    <div style="width: 300px; cursor: grab; position: absolute; background-color: white;" id="popup-${_id}"> 
      <img src="${this.baseUrl}/files/${file}" alt="Photo" style="width: 300px; height: 139px; object-fit: cover; border-radius: 15px 15px 0 0;">
      <h2 class="marker-label"><b>${label}</b></h2>
      <h3 class="marker-info"> ${info} </h3>
      <div class="coordinates-container">
        <div class="coordinate">
          <span class="coordinates">LAT:  </span><span class="lat">${latitude}</span>
        </div>
        <div class="coordinate">
          <span class="coordinates">LON:  </span><span class="lat">${longitude}</span>
        </div>
      </div>
      <div class="info-box">
      ${addressParts && addressParts.length > 0 ? `
      <div class="address">
        <div class="label">Address:</div>
        <div class="values">
          <span class="value">${addressParts[0] || ''}</span><span class="value">${addressParts[2] || ''}</span>
          <span class="value">${addressParts[1] || ''}</span>
          <span class="value">${addressParts[3] || ''}</span>
        </div>
      </div>
    ` : ''}
        <div class="economy">
          <span class="label">Economic Profile:</span>
          <div class="values">
            <span class="value">${income}</span>
          </div>
        </div>
        <div class="traffic">
          <span class="label">Traffic:</span>
          <div class="values">
            <span class="value">${congestedData.congestedStatus}</span>
          </div>
        </div>
        <div class="speed">
          <span class="label">Speed:</span>
          <div class="values">
            ${averageSpeed !== 'N/A' ? `<span class="value">${averageSpeed} km/h</span>` : '<span class="value">N/A</span>'}
          </div>
        </div>
      </div>

      <div class='row mt-1 d-flex justify-content-center'>
        ${deleteButtonHtml}
      </div>
      <div class='row mt-1 d-flex justify-content-center'>
        ${streetViewButtonHtml}
      </div>
      <div class='row mt-1 mb-2 d-flex justify-content-center'>
        ${satelliteViewButtonHtml}  
      </div>
      <div class='row mt-1 mb-2 d-flex justify-content-center'>
        ${ClearSatelliteImages}  
      </div>
    </div>
  `;
  }
  async addPointsOfinterest() {
    if (
      !this.pointOfinterestName ||
      !this.Latitude ||
      !this.Longitude ||
      isNaN(this.Latitude) ||
      isNaN(this.Longitude) ||
      this.Latitude < -90 ||
      this.Latitude > 90 ||
      this.Longitude < -180 ||
      this.Longitude > 180 ||
      !this.POI_selectedFile ||
      !this.icon_selectedFile
    ) {
      alert('Please fill in all the required details correctly and input all files.');
      return;
    }
     else {
      const address = await this.traffic.getAddress(this.Latitude, this.Longitude).toPromise();
      const typeOfAddress = address as { items: any[] };
      let addressParts =  typeOfAddress?.items[0]?.address?.label.split(', ');
        this.db.addCustomPointsOfinterest({ address: typeOfAddress?.items[0]?.address?.label, name: this.pointOfinterestName, latitude: this.Latitude, longitude: this.Longitude, file: this.POI_selectedFile, icon: this.icon_selectedFile }).subscribe((result:any) => {
          if (result.pointOfinterest === null) {
            alert('Points of interest already exist.');
          } else {
            this.displayPointsofInterest();
          }
        });
    }
  }
  displayPointsofInterest(){
    const markerGroup = L.layerGroup().addTo(this.map);
    this.db.getCustomPointsOfinterest().subscribe((poi:any)=>{
      poi.pointOfinterest.forEach((point: any) => {
        const icon = L.icon({
          iconUrl: `${this.baseUrl}/files/${point.icon}`,
          iconRetinaUrl: `${this.baseUrl}/files/${point.icon}`,
          iconSize: [20, 20],
          iconAnchor: [12, 41],
          popupAnchor: [1, -34],
        });
        const marker = L.marker([point.latitude, point.longitude], {
          draggable: false,
          icon: icon,
        }).addTo(this.map);
        
        const { name, latitude, longitude, address } = point;
        const addressParts = address.split(', ');
        let popupContent = `
        <div style="width: 260px;"> 
         <img src="${this.baseUrl}/files/${point.file}" alt="Photo" style="width: 260px; height: 89px; object-fit: cover; border-radius: 15px 15px 0 0;">
         <h2 class="marker-label"><b>${name}</b></h2>
         <div class="coordinates-container">
          <div class="coordinate">
            <span class="coordinates">LAT: </span><span class="lat">${latitude}</span>
          </div>
          <div class="coordinate">
            <span class="coordinates">LON: </span><span class="lat">${longitude}</span>
          </div>
        </div>
         <div style="padding:10px;">
         ${addressParts && addressParts.length > 0 ? `
            <span >${addressParts[0] || ''}</span><span class="value">,${addressParts[2] || ''}</span>,
            <span >${addressParts[1] || ''}</span>,
            <span >${addressParts[3] || ''}</span>
         </div>
       ` : ''}
         </div>
         </div>`;
        marker.bindPopup(popupContent);
        markerGroup.addLayer(marker);
      });
    })
  }
  private updateMarkers(pointsOfInterest: any[], markerGroup: L.LayerGroup, map: L.Map): void {
    const currentZoom = map.getZoom();
    if (currentZoom >= this.minZoomToShowMarker) {
      if (pointsOfInterest && Array.isArray(pointsOfInterest)) {
        pointsOfInterest.forEach((point: any) => {
          const icon = L.icon({
            iconUrl: 'assets/icons/location-pin.png',
            iconRetinaUrl: 'assets/icons/location-pin.png',
            shadowUrl: point.icon_background_color,
            iconSize: [15, 15],
            iconAnchor: [12, 41],
            popupAnchor: [1, -34],
          });
          const marker = L.marker([point.geometry.location.lat, point.geometry.location.lng], {
            draggable: false,
            icon: icon,
          }).addTo(map);
    
          const { name, rating, photos, vicinity } = point;
          let popupContent = `
            <div class='p-2'>
              <h3>${name}</h3>
              <p>Rating: ${rating}</p>
              <p>${vicinity}</p>
            </div>`;
          marker.bindPopup(popupContent);
          markerGroup.addLayer(marker);
        });
      }

    } else {
      markerGroup.clearLayers();
    }
  }
  
}
