import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WebcamService {
  private apiUrl = environment.apiUrl;
  private facialDirection = environment.facialDirection;
  constructor(private http: HttpClient) { }
  livenessdetect(file: File): Observable<any> {
    const formData = new FormData();
    formData.append('image', file);
    const options = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
      })
    };
    return this.http.post<any>(this.apiUrl+'/liveness-detection', formData, options);
  }
  detectFacedirection(file: File): Observable<any> {
    const formData = new FormData();
    formData.append('image', file);
    const options = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
      })
    };
    return this.http.post<any>(this.facialDirection+'/api/processImage', formData, options);
  }
  compareFaces(file: File, idDocument: File): Observable<any> {
    const formData = new FormData();
    formData.append('image', file);
    formData.append('file', idDocument);
    const options = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
      })
    };
    return this.http.post<any>(this.apiUrl+'/compare_faces', formData, options);
  }

}
