<div class="container">
    <div class="center-content">
        <div class="webcam-container" >
            <mat-icon class="arrow-icon" (click)="navigateBackToWarning()">arrow_back</mat-icon>
            <div class="verification">
                <p id="verify-status">Identity Verification</p>
                <p id="oval">Position your face in the oval</p>
                <p id="complete">{{progressPercentage}}% Complete</p>
            </div>
            <div class="progress"> 
              <mat-progress-bar mode="determinate" [value]="progressPercentage"></mat-progress-bar>
            </div>
            <div class="face-container"  [ngClass]="{'accepted': status === 'accepted'}" #faceContainer>
              <video #videoElement playsinline autoplay muted></video>
              <canvas #canvasElement></canvas>
            </div>
            <div *ngIf="status === 'ready'" class="verification">
              <p>Press Record and follow the instructions</p>
            </div>
            <div *ngIf="status === 'accepted'" class="verification accepted">
              <p id="accepted">Accepted <mat-icon class="tick-icon">done</mat-icon></p>
            </div>
            <div *ngIf="status === 'failed'" class="verification failed">
              <p id="failed">{{challenge}}</p>
            </div>
            <div *ngIf="status === 'available'" class="verification failed">
              <p id="failed">{{challenge}}</p>
            </div>
            
            <!-- <div class="challenges">
              <p *ngIf="challenge === 'Looking Directly at the Camera'" class="challenge-text look-straight">Challenge: Look Straight into the Camera</p>
              <p *ngIf="challenge === 'Turned Head to the Left'" class="challenge-text turn-left">Challenge: Turn Your Head to the Left</p>
              <p *ngIf="challenge === 'Press start!'" class="challenge-text press-start">Challenge: Press Start to Start the Challenge!</p>
            </div> -->
            <!-- <p class="status-message" [class.passed]="challengePassed">OK Passed!</p> -->
            <div class="button">
              <button type="button" class="btn d-flex align-items-center" 
              [class.btn-primary]="!isRecording && !challengePassed" 
              [class.btn-danger]="isRecording || challengePassed" 
              [class.btn-primary]="challengePassed"
              [class.buttonTest]="challengePassed"
              (click)="handleContinueOrRecording()">
              <mat-icon class="video-icon mr-2">{{ isRecording ? 'stop' : 'videocam' }}</mat-icon>
              {{ status === 'ready'? 'START CHALLENGE' : (progressPercentage === 100 ? 'STOP CHALLENGE' : 'STOP CHALLENGE') }}
            </button>
            </div>
          </div>
    </div>
</div>