<div class="resetPassword-container">
    <div class="resetPassword-content">
        <img src="../../assets//logo.svg" alt="Logo">
        <div class="reset-details-container">
            <h1>Reset your password</h1>
        </div>
        <br>
        <form [formGroup]="passwordForm">
            <mat-form-field appearance="outline" [style.width.px]="380">
              <mat-label>New Password</mat-label>
              <input matInput type="password" formControlName="newpassword">
            </mat-form-field>
            <div class="description">
                 <div *ngIf="passwordForm!.get('newpassword')?.hasError('required'); else minLengthError">
                    Password is required.
                  </div>
                  
                  <ng-template #minLengthError>
                    <div *ngIf="passwordForm!.get('newpassword')?.hasError('minlength'); else patternError">
                      Password must be at least 8 characters long.
                    </div>
                  </ng-template>
                  
                  <ng-template #patternError>
                    <div *ngIf="passwordForm!.get('newpassword') && passwordForm!.get('newpassword')?.hasError('pattern')">
                      Password must contain at least 1 uppercase letter, 1 symbol, and 1 number.
                    </div>
                  </ng-template>
            </div>
            <br>
            <mat-form-field appearance="outline" [style.width.px]="380">
                <mat-label>Confirm Password</mat-label>
                <input matInput type="password" formControlName="confirmpassword" required>
            </mat-form-field>
            <div class="description">
                <div *ngIf="passwordForm.value['newpassword'] !== passwordForm.value['confirmpassword'] ; else match">
                Passwords do not match!.
                </div>
                <ng-template #match>
                <div *ngIf="passwordForm.value['newpassword'] === passwordForm.value['confirmpassword'] ;">
                    Passwords match.
                </div>
                </ng-template>
            </div>
        <br>
        </form> 
        <button button type="button" class="btn btn-success mt-2 custom-button" (click)="resetPassword()" [disabled]="passwordForm && passwordForm.invalid ">
          RESET PASSWORD
        </button>
        <br>
    </div>
</div>
