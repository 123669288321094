import { HttpClient,HttpParams, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class TrafficDataService {
  private baseUrl = environment.baseUrl;
  private apiEndpoint = `${this.baseUrl}/google-api/points-of-interest`;
  private token = localStorage.getItem('token');
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.token}`
    })
  };
  constructor(private http: HttpClient) { }
  getSpeedLimit(latitude: any, longitude: any) {
    const url = `${this.baseUrl}/traffic/traffic-speed`;
    const requestBody = {
      latitude: latitude,
      longitude: longitude
    };
    return this.http.post(url, requestBody, this.httpOptions);
  } 
  getCongestedData(latitude: any, longitude: any) {
    const url = `${this.baseUrl}/traffic/congested-data`;
    const requestBody = {
      latitude: latitude,
      longitude: longitude
    };
    return this.http.post(url, requestBody, this.httpOptions);
  }
  getTrafficData(latitude: any, longitude: any, type: any, radius: any) {
    const url = `${this.baseUrl}/traffic/traffic-data`;
    const requestBody = {
      latitude: latitude,
      longitude: longitude,
      type: type,
      radius: radius
    };
    return this.http.post(url, requestBody, this.httpOptions);
  }
  getPointsOfInterest(latitude: number, longitude: number, radius: number): Observable<any> {
    let params = new HttpParams();
    params = params.append('latitude', latitude.toString());
    params = params.append('longitude', longitude.toString());
    params = params.append('radius', radius.toString());
    return this.http.get<any>(this.apiEndpoint, { params: params as HttpParams, headers: this.httpOptions.headers as HttpHeaders });
  }
  getAddress(latitude: number, longitude: number) {
    const url = `${this.baseUrl}/traffic/getAddress`;
    const requestBody = {
      latitude: latitude,
      longitude: longitude,
    };
    return this.http.post(url, requestBody, this.httpOptions);
  }
  autocomplete(input: string): Observable<any> {
    return this.http.get(`${this.baseUrl}/google-api/autocomplete`, { params: { input }, ...this.httpOptions });
  }
  getPlaceDetails(placeId: string): Observable<any> {
    return this.http.get(`${this.baseUrl}/google-api/place-details`, { params: { placeId }, ...this.httpOptions });
  }
}
