import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-general-user',
  templateUrl: './general-user.component.html',
  styleUrls: ['./general-user.component.scss']
})
export class GeneralUserComponent implements OnInit {
  @Output() showGeneralUserInfoChange = new EventEmitter<boolean>();
  constructor() { }

  ngOnInit(): void {
  }
  emitShowGeneralUserInfo() {
    this.showGeneralUserInfoChange.emit(false);
  }

}
