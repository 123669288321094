<div class="myBillboards">
    <h1 class="responsive-header">Billboards</h1>
    <div class="container">
        <div class="billboardsListing" [ngClass]="{'active-container': activeHeader === 'billboards', 'inactive-container': activeHeader !== 'billboards'}" (click)="toggleHeader('billboards')">
            <h2 class="inline-header mr-4" [ngClass]="{'active': activeHeader === 'billboards'}" [class.active-header]="activeHeader === 'billboards'"  >
                All Billboard Listings
            </h2>
        </div>
        <div class="myBillboardsListing" [ngClass]="{'active-container': activeHeader === 'map', 'inactive-container': activeHeader !== 'map'}" (click)="toggleHeader('map')">
            <h2 class="inline-header" [ngClass]="{'active': activeHeader === 'map'}" [class.active-header]="activeHeader === 'map'">
                All Billboards Map View
            </h2>
        </div>
        <div *ngIf="userType !== 'individual'" class="myBillboardsListing" [ngClass]="{'active-container': activeHeader === 'myBillboardsListing', 'inactive-container': activeHeader !== 'myBillboardsListing'}" (click)="toggleHeader('myBillboardsListing')">
            <h2 class="inline-header" [ngClass]="{'active': activeHeader === 'myBillboardsListing'}" [class.active-header]="activeHeader === 'myBillboardsListing'">
                My billboards Listings
            </h2>
        </div>
        <div *ngIf="userType !== 'individual'" class="MapViewer" [ngClass]="{'active-container': activeHeader === 'Mymap', 'inactive-container': activeHeader !== 'Mymap'}" (click)="toggleHeader('Mymap')">
            <h2 class="inline-header" [ngClass]="{'active': activeHeader === 'Mymap'}" [class.active-header]="activeHeader === 'Mymap'" >
                My Map View
            </h2>
        </div>

    </div>
    <div class="row mt-4">
        <div class="col-lg-6 col-12" *ngIf="activeHeader === 'billboards' || activeHeader === 'myBillboardsListing' ">
            <div class="form-group has-search">
                <input type="text" class="form-control" placeholder="Search" [(ngModel)]="searchText">
                <mat-icon class="search-icon">search</mat-icon>
            </div>
        </div>
        <div class="col-6" *ngIf="activeHeader === 'map' || activeHeader === 'Mymap'">
            <form class="example-form">
                <mat-form-field class="custom-input">
                    <input
                      matInput
                      [formControl]="searchControl"
                      placeholder="Search Billboard by name or description..."
                      [matAutocomplete]="auto"
                      class="centered-text"
                    />
                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                      <mat-option *ngFor="let option of filteredOptions | async" [value]="option.label" (click)="selectedOption(option)">
                        {{ option.label}}({{ option.info }})
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
            </form>
        </div>
        <div class="col-2 help-info" *ngIf="activeHeader === 'map'|| activeHeader === 'Mymap'  || !activeHeader  " >
            <div class="help-center-container" (click)="showGeneralUserInfoScreen()">
                <mat-icon class="help-icon">help_center</mat-icon>
            </div>
        </div>
    </div>
</div>

<div class="myBillboards" *ngIf="activeHeader === 'billboards' || !activeHeader " >
    <app-billboard-listing [searchText]="searchText" [type]="'billboards'" (viewonmap)="onBillboardValueChanges($event)"></app-billboard-listing>
</div>
<div class="myBillboards" *ngIf="activeHeader === 'myBillboardsListing' || !activeHeader " >
    <app-billboard-listing [searchText]="searchText" [type]="'myBillboardsListing'"  (viewonmap)="onBillboardValueChanges($event)"></app-billboard-listing>
</div>
<div class="map-view" *ngIf="activeHeader === 'map' || activeHeader === 'Mymap' " >
    <div class="row">
        <div id="map-container" [ngSwitch]="currentMapStyle">
            <div *ngSwitchDefault>
                <div id="map"></div>
            </div>
        </div>
    </div>
</div>
<div class="container-generalUserInfo" *ngIf="showGeneralUserInfo" >
    <app-general-user (showGeneralUserInfoChange)="handleShowGeneralUserInfoChange($event)"></app-general-user>
</div>
