import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { map } from 'rxjs';
import { startWith } from 'rxjs/internal/operators/startWith';
import { UserService } from 'src/services/user.service';
import { AlertService } from 'src/shared/alert.service';

@Component({
  selector: 'app-registration-page',
  templateUrl: './registration-page.component.html',
  styleUrls: ['./registration-page.component.scss']
})
export class RegistrationPageComponent implements OnInit,AfterViewInit {
  otpFormSubmitted: boolean = false;
  firstFormGroup = this._formBuilder.group({
    companyName: ['', Validators.required],
    regNumber: ['', Validators.required],
    vatGstNumber: ['', Validators.required],
    country: ['', Validators.required],
    valid: ['', Validators.required],
  });
  contactInfoFormGroup = this._formBuilder.group({
    FirstName: ['', Validators.required],
    Surname: ['', Validators.required],
    ContactNumber: ['', Validators.required],
    IDNumber: ['', Validators.required],
    Email: ['', [Validators.required, Validators.email]],
    confirmEmail: ['', [Validators.required, this.matchEmailValidator('Email')]],
    AlternateContactPerson: [''],
    AlternateContactNo: [''],
    AlternateEmail: ['', Validators.email],
    IDcartFront: ['', Validators.required],
    LetterOfAuthority: [''],
    Password: [
      '',
      [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern(/^(?=.*[A-Z])(?=.*[!@#$%^&*(),.?":{}|<>0-9])/),
      ],
    ],
    verifyPassword: ['', [Validators.required, this.matchPasswordValidator('Password')]],
  });
  personalInfoGroup = this._formBuilder.group({
    FirstName: ['', Validators.required],
    Surname: ['', Validators.required],
    ContactNumber: ['', Validators.required],
    Email: ['', [Validators.required, Validators.email]],
    confirmEmail: ['', [Validators.required, this.matchEmailValidator('Email')]],
    ageGroup: [''],
    employmentGroup: [''],
    genderGroup: [''],
    Password: [
      '',
      [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern(/^(?=.*[A-Z])(?=.*[!@#$%^&*(),.?":{}|<>0-9])/),
      ],
    ],
    verifyPassword: ['', [Validators.required, this.matchPasswordValidator('Password')]],
  });
  PostOfficeAddress = this._formBuilder.group({
    PObox: [''],
    CityTown: [''],
    ProvinceState: [''],
    Country: [''],
    PostalCode: [''],
    CountryCode: [''],
    CityCode: [''],
    TelephoneNo: [''],
  });
  physicalAddress = this._formBuilder.group({
      BuildingNo: [''],
      BuildingName: [''],
      StreetName: [''],
      CityTown: [''],
      ProvinceState: [''],
      Country: [''],
      PostalCode: [''],
      CountryCode: [''],
      CityCode: [''],
      SameAddress: ['']
  });
  verificationForm!: FormGroup;
  PersonalverificationForm!: FormGroup;
  isLinear = true;
  p_isLinear = true;
  fileName: string | undefined;
  companyDetails: any;
  PhysicalAddress: any;
  PostalAddress: any;
  contactInfo: any;
  otpInput: any;
  regType: string = 'media-owner';
  company_letter: string = 'Company Letter';
  company_registration: string = 'Company Registration'
  OTPverificationInput: boolean = true;
  //default countries
  countries = [
    { name: 'United States' },
    { name: 'Canada'},
    { name: 'South Africa'},
    { name: 'India'}
  ];
  selectedCountry: any; 
  countryFilterControl = new FormControl();
  filteredCountries!: any[];
  remainingTime: number = 300;
  @ViewChild('steppers')
  stepper!: MatStepper;
  displayTime: string = '05:00'
  intervalId: any;
  @ViewChild('personalInfoStepper')
  personalInfoStepper!: MatStepper;
  currentStep: any;
  nextStep:any;
  
  constructor(private _formBuilder: FormBuilder,private renderer: Renderer2,private router: Router,private user:UserService,private _alert: AlertService,private ref: ChangeDetectorRef) {}
  ngOnInit(): void {
    this.verificationForm = this._formBuilder.group({
      verificationType: ['SMS', Validators.required],
      otpInput:['', Validators.required],
      otpEmail: [{value: '', disabled: true}, Validators.email],
      otpNumber: [{value: '', disabled: true}]
    });
    this.PersonalverificationForm = this._formBuilder.group({
      pverificationType: ['SMS', Validators.required],
      p_otpInput:['', Validators.required],
      p_otpEmail: [{value: '', disabled: true}, Validators.email],
      p_otpNumber: [{value: '', disabled: true}]
    });
    this.firstFormGroup = this._formBuilder.group({
      companyName: ['', Validators.required],
      regNumber: ['', Validators.required],
      vatGstNumber: ['', Validators.required],
      country: ['', Validators.required],
      valid: ['', Validators.required],
    });
    this.user.getCountryNames().subscribe(names => {
      this.countries = names;
      this.filteredCountries = this.countries;
      this.countryFilterControl.valueChanges.pipe(
        startWith(''),
        map(value => this.filterCountries(value))
      ).subscribe(filteredCountries => {
        this.filteredCountries = filteredCountries;
      });
    });

  }

  ngAfterViewInit(){
    this.currentStep = this.stepper.selected?.label;
    this.stepper.selectionChange.subscribe((data:any) => {
      this.currentStep = this.stepper.selected?.label;
    })
  }

  filterCountries(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.countries.filter(country => country.name.toLowerCase().includes(filterValue));
  }
  private matchPasswordValidator(controlName: string) {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const newPassword = control.root.get(controlName)?.value;
      const match = newPassword === control.value;
      return match ? null : { 'passwordMismatch': true };
    };
  }
  matchEmailValidator(controlName: string) {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const emailControl = control.root.get(controlName);

      if (emailControl && control.value !== emailControl.value) {
        return { 'emailMismatch': true };
      }

      return null;
    };
  }
  onFileSelected(event: any): void {
    const inputElement = event.target;
    if (inputElement.files.length > 0) {
      this.fileName = inputElement.files[0].name;
    } else {
      this.fileName = '';
    }
  }
  get verificationTypeControl() {
    return this.verificationForm.get('verificationType');
  }
  get isSmsSelected() {
    return this.verificationTypeControl!.value === 'SMS';
  }
  get isEmailSelected() {
    return this.verificationTypeControl!.value === 'Email';
  }
  get pverificationTypeControl() {
    return this.PersonalverificationForm.get('pverificationType');
  }
  get pisSmsSelected() {
    return this.pverificationTypeControl!.value === 'SMS';
  }
  get pisEmailSelected() {
    return this.pverificationTypeControl!.value === 'Email';
  }
  navigateToLogin(){
    this.router.navigate(['/login'])
  }
  getCompanyDetails(): void {
      const formValues = this.firstFormGroup.value;
      this.companyDetails = {
          companyName: formValues.companyName,
          registrationNumber: formValues.regNumber,
          vatGstNumber: formValues.vatGstNumber,
          country: formValues.country
      };
      this._alert.showLoader();
      this.user.verifyCompanyReg(formValues.country, formValues.regNumber).subscribe((data: any) => {
        this._alert.hideLoader()
        if (data.status == 'valid') {
          this.firstFormGroup.get('valid')!.setValue('success');
          this._alert.notificationToast('success', 'Registration number validated!');
          this.stepper.next();
        } else if (data.status == 'invalid') {
          this._alert.notificationToast('warning', 'Incorrect registration number provided!');
        }
      });
  }
  onContactInfoUpdated(updatedSecondFormGroup: FormGroup): void {
    this.contactInfo =  updatedSecondFormGroup.value;
    
  }
  onOtpChange(value:any){
    this.otpInput = value;
  }
  isDocument(file: File): boolean {
    const imageFormats = ['image/jpeg', 'image/png', 'application/pdf'];
    return imageFormats.includes(file.type);
  }
  onFormValueChanges(personalInfoValue: any){
    if (this.personalInfoGroup.valid) {
      this.user.createAccount(
        personalInfoValue.value.Email,
        personalInfoValue.value.Password,
        personalInfoValue.value,
        {},{},false,'individual',{}
      ).subscribe({
        next: (regStatus:any) => {
          this.PersonalverificationForm.setValue({
            pverificationType: 'SMS',
            p_otpInput: '',
            p_otpNumber: personalInfoValue.value.ContactNumber,
            p_otpEmail: personalInfoValue.value.Email.toLowerCase()
          });
          this._alert.notificationToast("success",regStatus.message)
          this.personalInfoStepper.next();
        },
        error: error => {
          console.error('Error handler:', error);
          if(error.error.message){
            this._alert.notificationToast("warning",error.error.message)
          }
          else{
            this._alert.notificationToast("warning", "Account creation failed. This email might be in use or there could be other issues.");

          }
          
        },
        complete: () => {
        }
      });
    } else {
      this._alert.notificationToast("warning",'Not all information were provided. Cannot create account.')
    }
  }
  onAddressInfoUpdated(updatedSecondFormGroup: any): void {
    this.PhysicalAddress= updatedSecondFormGroup.physicalAddress.value;
    this.PostalAddress = updatedSecondFormGroup.postOfficeAddress.value; 
    this.createAccount();
  }
  createAccount() {
    if (this.contactInfoFormGroup.valid) {
      this.verificationForm.setValue({
        verificationType: 'SMS',
        otpInput: '',
        otpNumber: this.contactInfo.ContactNumber,
        otpEmail: this.contactInfo.Email.toLowerCase()
      });
      this.contactInfo.FullName = `${this.contactInfo.FirstName} ${this.contactInfo.Surname}`;
      this.user.createAccount(
        this.contactInfo.Email.toLowerCase(),
        this.contactInfo.Password,
        this.contactInfo,
        this.PostalAddress,
        this.PhysicalAddress,
        false,
        'company',
        this.companyDetails
      ).subscribe({
        next: (regStatus:any) => {

          this._alert.notificationToast("success",regStatus.message)
        },
        error: error => {
          if(error.error.message){
            this._alert.notificationToast("warning",error.error.message)
          }
          else{
            this._alert.notificationToast("warning", "Account creation failed. This email might be in use or there could be other issues.");

          }
        },
        complete: () => {
        }
      });
    } else {
      this._alert.notificationToast("warning",'Not all information were provided. Cannot create account.')
    }
  }
  verifyEmail(){
    if(this.remainingTime == 0){
      this.stopTimer();
      this.remainingTime = 0;
      this._alert.notificationToast('warning','OTP has expired request another one!');
      return;
    }
    let requestOTPEmail = '';
    const formValues = this.PersonalverificationForm.value;
    if(this.regType == 'media-owner' || this.regType == 'company'){
      requestOTPEmail = this.contactInfo.Email.toLowerCase();
    }
    else if(this.regType == 'individual'){
      requestOTPEmail = this.personalInfoGroup.value.Email!.toLowerCase();
    }
    this.user.verifyOTP(requestOTPEmail,this.otpInput).subscribe({
      next: (regStatus:any) => {
        if(regStatus.status){
          this._alert.notificationToast("success",'otp successfully validated');
          localStorage.setItem('token', regStatus.token);
          this.router.navigate(['/liveness-notice']);
          const documents = {
            IDdocument: this.contactInfoFormGroup.value.IDcartFront || 'None',
            email: requestOTPEmail.toLowerCase()
          };
          this.stopTimer()
          this.router.navigate(['/liveness-notice'], {
            queryParams: documents
          });
        }
        else{
          this._alert.notificationToast("warning",'error validating otp');
        }
      },
      error: error => {
        console.error('Error handler:', error);
        this._alert.notificationToast("warning",error.error.message)
      },
      complete: () => {
      }
    });
  }
  requestOTPForIndividual(){
    const personal = this.PersonalverificationForm.value.pverificationType;
    if( personal === 'Email')
    {
      this.requestOtp();
    }
    else{
      this.requestSMSOtp();
    }
  }
  requestOTPForCompany(){
    const corporate = this.verificationForm.value.verificationType;
    if(corporate === 'Email')
    {
      this.requestOtp();
    }
    else{
      this.requestSMSOtp();
    }
  }
  requestOtp(){
    this.stopTimer();
    let requestOTPEmail = '';
    if(this.regType == 'media-owner' || this.regType == 'company'){
      requestOTPEmail = this.contactInfo.Email.toLowerCase();
    }
    else{
      requestOTPEmail = this.personalInfoGroup.value.Email!.toLowerCase() || '';
    }
    this.user.requestOTP(requestOTPEmail).subscribe({
      next: (regStatus:any) => {
        this.otpFormSubmitted = true;
        this.ref.detectChanges();
        this.startTimer();
        this._alert.notificationToast("success",regStatus.message)
      },
      error: error => {
        this._alert.notificationToast("warning",error.error.message)
      },
      complete: () => {
      }
    });
  }
  requestSMSOtp(){
    this.stopTimer();
    let requestOTPMobileNumber = '';
    if(this.regType == 'media-owner' || this.regType == 'company'){
      requestOTPMobileNumber = this.contactInfo.ContactNumber;
    }
    else{
      requestOTPMobileNumber = this.personalInfoGroup.value.ContactNumber! || '';
    }
    this.user.requestSMSOTP(requestOTPMobileNumber).subscribe({
      next: (regStatus:any) => {
        this.otpFormSubmitted = true;
        this.ref.detectChanges();
        this.startTimer();
        this._alert.notificationToast("success","Otp successfully sent to contact number.")
      },
      error: error => {
        this._alert.notificationToast("warning",error.error.message)
      },
      complete: () => {
      }
    });
  }
  onToggleChange(event: any) {
    this.regType = event.value;
  }
  async uploadCompanyLetter(event: any){
    const selectedFile = event.target.files[0];
    const email = this.contactInfoFormGroup!.value.Email!.toLowerCase() || '';
    if (selectedFile) {
        if (!this.isDocument(selectedFile)) {
          this._alert.notificationToast('warning','Selected file is not an allowed document type.');
          return;
        }
        if (selectedFile.size > 1024 * 1024) {
          this._alert.notificationToast('warning','Selected file exceeds the maximum size limit (1MB).');
          return;
        }
        this.company_letter = selectedFile ? selectedFile.name : 'Company Letter';
        this.user.uploadKycDoc(selectedFile, 'company_letter', email ).subscribe(data=>{
        });
    }
  }
  async uploadCompanyRegistration(event: any){
    const selectedFile = event.target.files[0];
    const email = this.contactInfoFormGroup!.value.Email!.toLowerCase() || '';
    if (selectedFile) {
        if (!this.isDocument(selectedFile)) {
          this._alert.notificationToast('warning','Selected file is not an allowed document type.');
          return;
        }
        if (selectedFile.size > 1024 * 1024) {
          this._alert.notificationToast('warning','Selected file exceeds the maximum size limit (1MB).');
          return;
        }
        this.company_registration = selectedFile ? selectedFile.name : 'Company Registration';
        this.user.uploadKycDoc(selectedFile, 'company_registration', email ).subscribe(data=>{
        });
    }
  }
  startTimer() {
    this.intervalId = setInterval(() => {
      this.updateTimer();
    }, 1000);
  }
  stopTimer() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.remainingTime = 300;
    }
  }
  updateTimer() {
    if (this.remainingTime > 0) {
      this.remainingTime--;
      this.updateDisplayTime();
    } else {
    }
  }
  updateDisplayTime() {
    const minutes = Math.floor(this.remainingTime / 60);
    const seconds = this.remainingTime % 60;
    this.displayTime = `${this.padNumber(minutes)}:${this.padNumber(seconds)}`;
  }
  padNumber(num: number): string {
    return num < 10 ? '0' + num : num.toString();
  }

}
