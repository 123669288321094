import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { UserService } from 'src/services/user.service';
import { AlertService } from 'src/shared/alert.service';

@Component({
  selector: 'app-contact-info',
  templateUrl: './contact-info.component.html',
  styleUrls: ['./contact-info.component.scss']
})
export class ContactInfoComponent implements OnInit {

  fileName: any;
  @Input() contactInfoFormGroup: FormGroup | undefined;
  @Output() contactInfoUpdated = new EventEmitter<FormGroup>();
  id_BaseUrl : string = environment.baseUrl+'/files/document/';
  Idurl: string = '';
  IDFrontDoc: string = 'ID Document';
  LetterOfAuthority: string = 'Letter of Authority';
  constructor(private _formBuilder: FormBuilder,private router:Router,private users: UserService,private alert: AlertService) { }

  ngOnInit(): void {
  }
  async uploadIDFront(event: any) {
    const email = this.contactInfoFormGroup!.value.Email;
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      if (!this.isImage(selectedFile)) {
        this.alert.notificationToast('warning','Selected file is not an image.');
        return;
      }
      if (selectedFile.size > 10 * 1024 * 1024) {
        this.alert.notificationToast('warning','Selected file exceeds the maximum size limit (10MB).');
        return;
      }
      this.users.uploadKycDoc(selectedFile, 'identity_document_front', email).subscribe((data: any) => {
        this.Idurl = this.id_BaseUrl + email + '/' + data.fileId;
        this.IDFrontDoc = data.fileId;
        this.contactInfoFormGroup!.get('IDcartFront')!.setValue(this.Idurl);
      });
    }
  }
  
  isImage(file: File): boolean {
    const imageFormats = ['image/jpeg', 'image/png'];
    return imageFormats.includes(file.type);
  }
  isDocument(file: File): boolean {
    const imageFormats = ['image/jpeg', 'image/png', 'application/pdf'];
    return imageFormats.includes(file.type);
  }
  async uploadLetterOfAuth(event: any) {
    const selectedFile = event.target.files[0];
    const email = this.contactInfoFormGroup!.value.Email;
    if (selectedFile) {
      if (!this.isDocument(selectedFile)) {
        this.alert.notificationToast('warning','Selected file is not an allowed document type.');
        return;
      }
      if (selectedFile.size > 1024 * 1024) {
        this.alert.notificationToast('warning','Selected file exceeds the maximum size limit (1MB).');
        return;
      }
      this.users.uploadKycDoc(selectedFile, 'letter_of_authority', email).subscribe(data=>{
          this.Idurl = this.id_BaseUrl + email+ '/'+ data.fileId;
          this.LetterOfAuthority = data.fileId;
          this.contactInfoFormGroup!.get('LetterOfAuthority')!.setValue(this.Idurl);
      });
    }
  }

  updateContactInfo(): void {
    this.contactInfoUpdated.emit(this.contactInfoFormGroup);
  }
  navigateToLogin(){
    this.router.navigate(['/login'])
  }
}
