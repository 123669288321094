import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild,NgZone  } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/services/user.service';
import { WebcamService } from 'src/services/webcam.service';
import { AlertService } from 'src/shared/alert.service';
enum HeadDirection {
  Left = 'Tilt Head to the Left',
  Right = 'Tilt Head to the Right',
  Center = 'Look Directly at the Camera',
}
@Component({
  selector: 'app-webcam',
  templateUrl: './webcam.component.html',
  styleUrls: ['./webcam.component.scss']
})
export class WebcamComponent implements OnInit {
  @ViewChild('videoElement') videoElement: ElementRef | undefined;
  @ViewChild('canvasElement') canvasElement: ElementRef | undefined;
  @ViewChild('faceContainer') faceContainer: ElementRef | undefined;
  @ViewChild('recordedImage') recordedImage: ElementRef | undefined;
  mediaRecorder: MediaRecorder | undefined;
  recordedChunks: Blob[] = [];
  isRecording = false;
  lastRecordedImage: any;
  currentChallengeIndex = 0;
  failureCount = 0;
  challenges: HeadDirection[] = [
    HeadDirection.Center,
    HeadDirection.Center,
    HeadDirection.Center,
  ];
  status: string = 'ready';
  challenge: any  = 'Please wait!';
  challengePassed: boolean = false;
  progressPercentage: any= 0;
  IdDocumentFileUrl: string = '';
  identification: boolean = false;
  email: string = '';
  constructor(private ngZone: NgZone, 
    private router: Router,
    private ref: ChangeDetectorRef,
    private liveness: WebcamService, 
    private alertService: AlertService,
    private route: ActivatedRoute,
    private http: HttpClient,
    private users: UserService)
  {
    this.route.queryParams.subscribe(params => {
      const receivedData = params;
      this.IdDocumentFileUrl = receivedData['IDdocument'];
      this.email = receivedData['email'];
      if(!receivedData['IDdocument']){
        this.router.navigate(['/']);
      };
    });
  }

  ngOnInit(): void {
  }
  async startRecording() {
    try {
      this.status = 'available';
      const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: false });
      this.videoElement!.nativeElement.srcObject = stream;

      this.mediaRecorder = new MediaRecorder(stream);
      this.mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          this.recordedChunks.push(event.data);
        }
      };
      this.mediaRecorder.onstop = () => {
        const blob = new Blob(this.recordedChunks, { type: 'video/webm' });
        this.recordedChunks = [];
      };
      this.faceContainer!.nativeElement.style.backgroundImage = '';
      this.videoElement!.nativeElement.style.display = 'block';
      this.mediaRecorder.start();
      this.isRecording = true;
      this.startNextChallenge();
    } catch (error) {
      console.error('Error accessing webcam:', error);
    }
  }

  stopRecording() {
    if (this.isRecording) {
      this.mediaRecorder!.stop();
      this.isRecording = false;
      const stream = this.videoElement!.nativeElement.srcObject as MediaStream;
      const tracks = stream.getTracks();
      tracks.forEach(track => track.stop());
      this.videoElement!.nativeElement.srcObject = null;
    }
  }
  compareAgainstIDdocument(File: File){
    this.http.get(this.IdDocumentFileUrl, { responseType: 'blob' }).subscribe((blob:any)=> {
      const file = this.blobToFile(blob, 'id-front.png', 'image/png');
      this.liveness.compareFaces(file, File).subscribe(
        (data) => {
          if (data.match === 'False') {
            const documents = {
              IDdocument: this.IdDocumentFileUrl,
              email: this.email
            };
            this.ngZone.run(() => {
              this.router.navigate(['/liveness/failed'], {
                queryParams: documents
              });
            });
          } else if (data.match === 'True') {
            this.identification = true;
            this.captureFrames();
          }
        },
        (error) => {
          console.error('Error in face comparison:', error);
          const documents = {
            IDdocument: this.IdDocumentFileUrl,
            email: this.email
          };
          this.ngZone.run(() => {
            this.router.navigate(['/liveness/failed'], {
              queryParams: documents
            });
          });
        }
      );
    });
  }
  blobToFile(theBlob: Blob, fileName: string, fileType: string): File {
    const file = new File([theBlob], fileName, { type: fileType });
    return file;
  }
  captureFrames(): void {
    if (this.isRecording) {
      this.status = 'failed';
      const video = this.videoElement!.nativeElement;
      const inMemoryCanvas = document.createElement('canvas');
      const inMemoryContext = inMemoryCanvas.getContext('2d');
      inMemoryCanvas.width = video.videoWidth;
      inMemoryCanvas.height = video.videoHeight;
      inMemoryContext!.drawImage(video, 0, 0, inMemoryCanvas.width, inMemoryCanvas.height);
      inMemoryCanvas.toBlob(async (blob: any) => {
      const file = new File([blob], 'captured-image.png', { type: 'image/png' });
      if(!this.identification ){
        if(this.IdDocumentFileUrl !== 'None'){
          this.compareAgainstIDdocument(file);
        }
        else if(this.IdDocumentFileUrl === 'None'){
          this.identification = true;
          this.captureFrames();
        }
      }
      else if(this.identification){
        this.liveness.livenessdetect(file).subscribe(
          (result) => {
            console.log(result);
            if(result.label == 1){
              this.failureCount = 0;
              this.sendFileToServer(file);
            }
            else{
              this.failureCount ++;
              if(this.failureCount > 2){
                this.stopRecording();
                this.users.updateUserliveness(this.email,false).subscribe(data=>{
                })
                const documents = {
                  IDdocument: this.IdDocumentFileUrl,
                  email: this.email
                };
                this.ngZone.run(() => {
                  this.router.navigate(['/liveness/failed'], {
                    queryParams: documents
                  });
                });
              }
              else{
                this.sendFileToServer(file);
              }
             
            }
          },
          (error) => {
            console.error('Error predicting:', error);
          }
        );
      }
      }, 'image/png');
    }
  }
  startNextChallenge(): void {
    if (this.currentChallengeIndex < this.challenges.length) {
      const currentChallenge = this.challenges[this.currentChallengeIndex];
      this.challenge = currentChallenge;
      setTimeout(() => this.captureFrames(), 1000);
    } else {
      this.challengePassed = true;
      if (this.isRecording) {
        this.stopRecording();
      }
      this.users.updateUserliveness(this.email,true).subscribe(data=>{
      })
      this.ngZone.run(() => {
        this.router.navigate(['/liveness/success']);
      });
    }
  }
  sendFileToServer(file: File): void {
    this.liveness.livenessdetect(file).subscribe(
      (result) => {
        console.log(result);
        if(result.label == 1){
          this.liveness.detectFacedirection(file).subscribe((result) => {
            console.log(result);
            const currentChallenge = this.challenges[this.currentChallengeIndex];
            if (result.direction === currentChallenge) {
              this.status = 'accepted';
              this.currentChallengeIndex += 1;
              this.progressPercentage += 33;
              this.ref.detectChanges();
              this.startNextChallenge();
            } else {
              this.status = 'failed';
              this.ref.detectChanges();
              setTimeout(() =>this.captureFrames(), 1000);
            }
          });
        }
        else{
          this.failureCount ++;
          if(this.failureCount > 2){
            this.stopRecording();
            const documents = {
              IDdocument: this.IdDocumentFileUrl,
              email: this.email
            };
            this.ngZone.run(() => {
              this.router.navigate(['/liveness/failed'], {
                queryParams: documents
              });
            });
          }
          else{
             this.sendFileToServer(file);
          }
        }
      },
      (error) => {
        console.error('Error predicting:', error);
      }
    );

  }
  handleContinueOrRecording() {
    if (this.challengePassed) {
        this.stopRecording();
        this.ngZone.run(() => {
          this.router.navigate(['/liveness/success']);
        });
        
    } else {
      if (this.isRecording) {
        this.stopRecording();
      } else {
        this.startRecording();
      }
    }
  }
  navigateBackToWarning(){
    this.router.navigate(['/liveness-notice']);
  }
}
