import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormArray, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { UserService } from 'src/services/user.service';
import { AlertService } from 'src/shared/alert.service';

@Component({
  selector: 'app-personal-info',
  templateUrl: './personal-info.component.html',
  styleUrls: ['./personal-info.component.scss']
})
export class PersonalInfoComponent implements OnInit {
  fileName: any;
  @Input() personalInfoGroup: FormGroup | undefined;
  @Output() formValueChanges = new EventEmitter<FormGroup>();
  IDFrontDoc: string = 'ID Card Front';
  IDBackDoc: string = 'ID Card Back';
  ProofOfAddress: string = 'Proof of Address';
  id_BaseUrl : string = environment.baseUrl+'/files/document/';
  IdfileUrl: any;
  phoneNumber: string = '';
  phoneNumberError:any;
  constructor(private router:Router,private users: UserService,private alert: AlertService) { }
  ngOnInit(): void {
  }
  updatePersonalInfo(): void {
    if (this.personalInfoGroup!.valid) {
      this.personalInfoGroup!.value.FullName = `${this.personalInfoGroup!.value.FirstName} ${this.personalInfoGroup!.value.Surname}`;  
      this.formValueChanges.emit(this.personalInfoGroup);
    }
    else{
      this.alert.notificationToast('warning','Ensure that all inputs are correctly entered!');
    }
  }
  
  navigateToLogin(){
    this.router.navigate(['/login'])
  }
  hasError(event: any) {
    this.phoneNumberError = event;
  }
  getNumber(event: any) {}
  telInputObject(event: any) {}
  onCountryChange(event: any) {}
}
