import { Component, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-liveness-status',
  templateUrl: './liveness-status.component.html',
  styleUrls: ['./liveness-status.component.scss']
})
export class LivenessStatusComponent implements OnInit {

  status: string ='failed';
  IdDocument: string = '';
  email: string = '';
  constructor(private route: ActivatedRoute,private ngZone: NgZone, private router: Router) {
    this.route.queryParams.subscribe(params => {
      const receivedData = params;
      this.IdDocument = receivedData['IDdocument'],
      this.email = receivedData['email']
    });
   }
  ngOnInit(): void {
    this.status = this.route.snapshot.paramMap.get('status')!;
  }
  retakeVideoAuth(){
    const documents = {
      IDdocument: this.IdDocument,
      email: this.email
    };
    this.ngZone.run(() => {
      this.router.navigate(['/liveness'], {
        queryParams: documents
      });
    });
  }
  navigateToLogin(){
    this.router.navigate(['/login']);
  }
}
