<div class="row">
    <div class="col-12">
        <div class="table-container mt-4">
            <table id="users" class="users-table">
                <tr>
                    <th id="date"> Company </th>
                    <th id="time"> Description </th>
                    <th id="time"> Address </th>
                    <th id="first-item">Latitude</th>
                    <th id="email"> Longitude </th>
                    <th id="second-item">Economic Profile</th>
                    <th id="last-item">Action</th>
                </tr>
                <tr *ngFor="let billboard of markers | filter:searchText ">
                  <td>{{ billboard.user?.user?.companyInfo?.companyName || 'admin'}} </td>
                  <td>{{billboard.label}} - {{billboard.info || 'no info available'}} </td>
                  <td>{{billboard.address || 'address not available'}} </td>
                  <td>{{billboard.latitude || 'no info available'}} </td>
                  <td>{{billboard.longitude || 'no info available'}}</td>
                  <td>{{billboard.income || 'economic profile not available'}} </td>
                  <td>
                    <mat-icon class="more-items" (click)="toggleOptions($event, billboard)"  (clickOutside)="onClickedOutside()">more_vert</mat-icon>
                    <div class="options-container" *ngIf="isOptionsOpen && selectedBillboard === billboard ">
                      <p (click)="viewOnMap(billboard)">View on map</p>
                      <p (click)="deleteBillboard()" *ngIf="type === 'myBillboardsListing' ">Remove Billboard</p>
                    </div>
                  </td>
                </tr>
            </table>
          <div class="container repsonsive-table p-4">
              <div class="detail-wrapper mb-1" *ngFor="let billboard of markers | filter:searchText">
                  <div class="header-wrapper">
                      <div class="img-wrapper">
                          </div>
                          <h4 class="billboard-header">{{billboard.label}} - {{billboard.info || 'no info available'}}</h4>
                          <mat-icon class="more-items" (click)="toggleOptions($event, billboard)"  (clickOutside)="onClickedOutside()">more_vert</mat-icon>
                          <div class="options-container" *ngIf="isOptionsOpen && selectedBillboard === billboard ">
                            <p (click)="viewOnMap(billboard)">View on map</p>
                            <p (click)="deleteBillboard()" *ngIf="type === 'myBillboardsListing' ">Remove Billboard</p>
                          </div>
                      </div>
                      <div class="info-wrapper p-4">
                          <p class="address-text">{{billboard.address || 'address not available'}}</p>
                          <table class="col-12">
                              <tr>
                                  <thead class="t-header">
                                      Latitude
                                  </thead>
                                  <td class="text-end">
                                    {{billboard.latitude || 'no info available'}}
                                  </td>
                              </tr>
                              <tr>
                                  <thead class="t-header">
                                      Longitude
                                  </thead>
                                  <td class="text-end">
                                    {{billboard.longitude || 'no info available'}}
                                  </td>
                              </tr>
                              <tr>
                                  <thead class="t-header">
                                      User
                                  </thead>
                                  <td class="text-end">
                                    {{ billboard.user?.user?.companyInfo?.companyName || 'admin'}}
                                  </td>
                              </tr>
                              <tr>
                                  <thead class="t-header">
                                      Economic Profile
                                  </thead>
                                  <td class="text-end">
                                    {{billboard.income || 'economic profile not available'}} 
                                  </td>
                              </tr>
                          </table>
                      </div>
                  </div>
          </div>
        </div>
    </div>
</div>
