import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EnquiryChatService {
  private baseUrl = environment.baseUrl;
  private token = localStorage.getItem('token');
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.token}`
    })
  };
  constructor(private httpClient: HttpClient) { }
  sendEnquiry(email: any,message: any,attachmentUrl: any,subject: any) {
    const url = `${this.baseUrl}/sendEnquiry`
    const requestBody = {
      email:email,
      message:message,
      subject: subject,
      attachmentUrl:attachmentUrl
    };
    return this.httpClient.post(url, requestBody, this.httpOptions);
  }
  getALLEnquiries() {
    const url = `${this.baseUrl}/enquiries`
    return this.httpClient.get(url, this.httpOptions);
  }
  sendResponse(chatID: any,email: any, reply: any) {
    const url = `${this.baseUrl}/sendResponse`
    const requestBody = {
      chatID:chatID,
      message:reply,
      email: email
    };
    return this.httpClient.post(url, requestBody, this.httpOptions);
  }
  markChatAsRead(chatID: any){
    const url = `${this.baseUrl}/readEnquiry`
    const requestBody = {
      chatID:chatID,
    };
    return this.httpClient.post(url, requestBody, this.httpOptions);
  }
  resolveTicket(chatID: any){
    const url = `${this.baseUrl}/resolve-ticket`
    const requestBody = {
      chatID:chatID,
    };
    return this.httpClient.post(url, requestBody, this.httpOptions);
  }

}
