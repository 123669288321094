<div class="mobile-border"></div>
<div class="login-container" *ngIf="!displayOTPScreen">
    <div class="login-content">
        <img class="responsive-logo" src="../../assets//logo.svg" alt="Logo">
        <div class="login-form">
            <mat-form-field appearance="outline" class="col-lg-7 col-12">
                <mat-label for="email">Email</mat-label>
                <input matInput [(ngModel)]="email" id="email" name="email" type="email" required autocomplete="email"
                    (input)="checkFormValidity()" (ngModelChange)="onEmailChange($event)">
            </mat-form-field>
            <br>
            <!-- <mat-form-field appearance="outline" class="col-lg-7 col-12">
                <mat-label>Password</mat-label>
                <input matInput [(ngModel)]="password" type="password" name="password" required (input)="checkFormValidity()">
            </mat-form-field> -->
            <br>
            <div class="remember-forgot-container">
                <div class="rememberBox">
                    <input type="checkbox" id="rememberme" [(ngModel)]="rememberMe" />
                    <label for="rememberme">Remember me</label>
                </div>
                <a (click)="navigateToResetPassword()" class="forgot-password">Forgot Password</a>
            </div>
            <br>
            <button button type="button" class="btn btn-success mt-4 custom-button" (click)="verifyUSerRegistration()"
                [disabled]="!isFormValid">Login</button>
            <br>
            <p class="newUser mt-4"> New User? <span id="create-new-account" (click)="navigateToRegister()">Create an account</span></p>
        </div>
    </div>
</div>
<div class="otp-container" *ngIf="displayOTPScreen">
    <div class="otp-content">
        <img class="responsive-logo" src="../../../assets/logo.svg" alt="Logo">
        <div class="otp-form">
         <p *ngIf="type === 'contactNumber' " class="description">To update your mobile number, kindly verify the new number. Please proceed by selecting "Get OTP" below.
         </p>
         <p *ngIf="type === 'email' " class="description">To login into the account you must do 2FA, kindly verify your email address. Please proceed by selecting "Get OTP" below.
        </p>
         <form [formGroup]="verificationForm">
            <div  class="verify-otp" *ngIf="otpFormSubmitted">
                <div class="verification">
                  <h2>Account Verification</h2>
                  <p id="otpsent">OTP Sent!</p>
                  <p id="enterotp">Please enter the 5 digit OTP sent to your email address!</p>
                </div>
                <div class="otp-container-details">
                  <ng-otp-input  (onInputChange)="onOtpChange($event)"  [config]="{length:5}"></ng-otp-input>
                </div>
                <p id="remainingTime">Remaining Time: {{ displayTime }}</p>
                <p id="resentOTP">
                  Didn’t receive an OTP?
                  <span id="sendAgain" [ngClass]="{'disabledSendAgain': disableSendAgain}" (click)="requestOtp()" [style.pointer-events]="disableSendAgain ? 'none' : 'auto'"> Send again</span>
                </p>
                <!-- <p>
                  <span class="cooldown-message" *ngIf="cooldownMessage">{{ cooldownMessage }}</span>
                </p> -->
                <div class="continue">
                  <button button type="button" class="btn btn-success mt-4 custom-button" mat-button (click)="verifyEmail()">SUBMIT</button>
                </div>
              </div>
              <div class="getOTP" *ngIf="!otpFormSubmitted">
                <div class="verification">
                  <h2>Account Verification</h2>
                  <p id="otpsent">With OTP</p>
                </div>
                <div *ngIf="type === 'contactNumber' " class="otp-inputs mt-4">
                  <mat-form-field appearance="outline" [style.width.px]="300">
                    <mat-label>Enter Mobile Number</mat-label>
                    <input matInput formControlName="otpNumber" >
                  </mat-form-field>
                </div>
                <div *ngIf="type === 'email' " class="otp-inputs mt-4">
                  <mat-form-field appearance="outline" [style.width.px]="300">
                    <mat-label>Enter Email Address</mat-label>
                    <input matInput formControlName="otpEmail" >
                  </mat-form-field>
                </div>
                <div class="continue">
                    <button button type="button" class="btn btn-success mt-4 custom-button" mat-button (click)="requestOtp()">GET OTP</button>
                </div>
              </div>  
         </form>
        </div>
    </div>
</div>

<div class="mobile-footer col-12">
    <div class="curved-div"></div>
    <div class="footer-content">
        <h4 class="text-center mt-4" (click)="navigateToResetPassword()">Forgot Password</h4>
        <p class="mt-4 text-center mobile"> New User? <span (click)="navigateToRegister()">Create an account</span></p>
    </div>  
</div>
