import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/services/user.service';
import { AlertService } from '../../shared/alert.service';
import { FormGroup, FormControl, Validators, FormBuilder }  from '@angular/forms';
import {FormsModule,ReactiveFormsModule} from '@angular/forms';


@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  loginForm: any;
  json: any;
  token: any;
  email: string = '';
  constructor(private activatedRoute: ActivatedRoute,private _router:Router, private formBuilder: FormBuilder,private _alert:AlertService,private _user:UserService) { 
    // this.activatedRoute.queryParams.subscribe(params => {
    //     if(!params['token'] && !params['tokenId']){
    //       this._router.navigate(['/']);
    //     };
    //     this.token = params['token'];
    // });
  }
  ngOnInit(): void {
    // this.createForm();
  }
  // createForm() {
  //   this.loginForm = this.formBuilder.group({
  //     email: ['', Validators.required]
  //   });
  // }
  // submit(){
  //   this._user.resetPassword(this.loginForm.controls['newPassword'].value,this.token).subscribe((data: any)=>{
  //     if(data.message == 'Password reset successful'){
  //       this._alertService.notificationToast('success','check your email for reset password link!');
  //     }else{
  //       this._alertService.notificationToast('danger','something went wrong while reseting password!');
  //     }
  //   });
  // }
  onEmailChange(newEmail: string) {
    this.email = newEmail.toLowerCase();
  }
  submit(){
    this._user.requestResetPasswordLink(this.email).subscribe({
      next: (resetPasswordStatus:any) => {
        this._alert.notificationToast("success",`Password reset sent to ${this.email}`);
      },
      error: error => {
        console.error('Error handler:', error);
        this._alert.notificationToast("warning",error.error.message)
      },
      complete: () => {
      }
    });
  }
}
