import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/services/user.service';
import { AlertService } from 'src/shared/alert.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  newpassword: string = '';
  confirmpassword: string = '';
  passwordForm: FormGroup ;
  token: any;
  constructor(private fb: FormBuilder,private user: UserService,private activatedRoute: ActivatedRoute,private _router:Router, private formBuilder: FormBuilder,private _alert:AlertService,private _user:UserService) {
    this.passwordForm = this.fb.group({
      newpassword: [
        '',
        [
          Validators.required,
          Validators.minLength(8),
          Validators.pattern(/^(?=.*[A-Z])(?=.*[!@#$%^&*(),.?":{}|<>0-9])/),
        ],
      ],
      confirmpassword:[
        '',
        [
          Validators.required,
          Validators.minLength(8),
          Validators.pattern(/^(?=.*[A-Z])(?=.*[!@#$%^&*(),.?":{}|<>0-9])/),
        ],
      ],
    });
    this.activatedRoute.queryParams.subscribe(params => {
        if(!params['token'] && !params['tokenId']){
          this._router.navigate(['/']);
        };
        this.token = params['token'];
    });
  }

  ngOnInit(): void {
  }
  newPassword() {
    return this.passwordForm!.get('newpassword');
  }
  confirmPassword(){
    return this.passwordForm!.get('confirmpassword');
  }
  resetPassword(){
    this._user.resetPassword(this.passwordForm.value['newpassword'],this.token).subscribe((data: any)=>{
      if(data.message == 'Password reset successful'){
        this._alert.notificationToast('success','Password successfully changed!');
        this._router.navigate(['/login']);
      }else{
        this._alert.notificationToast('danger','something went wrong while reseting password!');
      }
    });
  }
  
}
