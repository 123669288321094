<div class="col-12 company-details-container">
    <h1>
        Company Information
    </h1>
    <div class="company-registration">
        <div class="row">
            <div class="col-5">
                <mat-form-field appearance="outline">
                    <mat-label>Company Name</mat-label>
                    <input matInput [(ngModel)]="companyName" name="companyName" placeholder="Company Name" disabled>
                </mat-form-field>
            </div>
            <div class="col-5">
                <mat-form-field appearance="outline">
                    <mat-label>Registration Number</mat-label>
                    <input matInput [(ngModel)]="registrationNo" name="registrationNo" placeholder="Registration Number" disabled>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-5">
                <mat-form-field appearance="outline">
                    <mat-label>VAT/GST Number</mat-label>
                    <input matInput [(ngModel)]="VatNo" name="VatNo" placeholder="VAT/GST Number" disabled>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="address-details-header">
        <h1>
            Address Information
        </h1>
        <div class="edit-container" (click)="onEditClick()">
            {{ isEditClicked ? 'Cancel' : 'Edit' }} <mat-icon (click)="onEditClick()">create icon</mat-icon>
        </div>
    </div>
    <div class="company-address-information">
        <div class="row">
            <div class="col-5">
              <mat-form-field appearance="outline">
                <mat-label>Building Number</mat-label>
                <input matInput [(ngModel)]="BuildingNo" name="BuildingNo" placeholder="Building Number" [disabled]="!isEditClicked">
              </mat-form-field>
            </div>
          
            <div class="col-5">
              <mat-form-field appearance="outline">
                <mat-label>Building Name</mat-label>
                <input matInput [(ngModel)]="BuildingName" name="BuildingName" placeholder="Building Name" [disabled]="!isEditClicked">
              </mat-form-field>
            </div>
          </div>
          
          <div class="row">
            <div class="col-5">
              <mat-form-field appearance="outline">
                <mat-label>Street Name</mat-label>
                <input matInput [(ngModel)]="StreetName" name="StreetName" placeholder="Street Name" [disabled]="!isEditClicked">
              </mat-form-field>
            </div>
          
            <div class="col-5">
              <mat-form-field appearance="outline">
                <mat-label>City/Town</mat-label>
                <input matInput [(ngModel)]="cityTown" name="cityTown" placeholder="City/Town" [disabled]="!isEditClicked">
              </mat-form-field>
            </div>
          </div>
          
          <div class="row">
            <div class="col-5">
              <mat-form-field appearance="outline">
                <mat-label>Province/State</mat-label>
                <input matInput [(ngModel)]="ProvinceState" name="ProvinceState" placeholder="Province/State" [disabled]="!isEditClicked">
              </mat-form-field>
            </div>
          
            <div class="col-5">
              <mat-form-field appearance="outline">
                <mat-label>Country</mat-label>
                <input matInput [(ngModel)]="country" name="country" placeholder="Country" [disabled]="!isEditClicked">
              </mat-form-field>
            </div>
          </div>
          
          <div class="row">
            <div class="col-5">
              <mat-form-field appearance="outline">
                <mat-label>Postal Code</mat-label>
                <input matInput [(ngModel)]="PostalCode" name="PostalCode" placeholder="Postal Code" [disabled]="!isEditClicked">
              </mat-form-field>
            </div>
          </div>          
    </div>
    <div class="postal-address-information">
        <div class="address-details-header">
          <h1>
            Postal Information
          </h1>
          <div class="edit-container" (click)="onPostalClick()">
              {{ isEditClickedPostal ? 'Cancel' : 'Edit' }} <mat-icon (click)="onEditClick()">create icon</mat-icon>
          </div>
        </div>
        <div class="row">
          <div class="col-5">
            <mat-form-field appearance="outline">
              <mat-label>Postal PO Box</mat-label>
              <input matInput [(ngModel)]="postal_PObox" name="postal_PObox" placeholder="Postal PO Box" [disabled]="!isEditClickedPostal">
            </mat-form-field>
          </div>
          <div class="col-5">
            <mat-form-field appearance="outline">
              <mat-label>Postal City/Town</mat-label>
              <input matInput [(ngModel)]="postal_cityTown" name="postal_cityTown" placeholder="Postal City/Town" [disabled]="!isEditClickedPostal">
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-5">
            <mat-form-field appearance="outline">
              <mat-label>Postal Province/State</mat-label>
              <input matInput [(ngModel)]="postal_ProvinceState" name="postal_ProvinceState" placeholder="Postal Province/State" [disabled]="!isEditClickedPostal">
            </mat-form-field>
          </div>
          <div class="col-5">
            <mat-form-field appearance="outline">
              <mat-label>Postal Country</mat-label>
              <input matInput [(ngModel)]="postal_country" name="postal_country" placeholder="Postal Country" [disabled]="!isEditClickedPostal">
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-5">
            <mat-form-field appearance="outline">
              <mat-label>Postal Postal Code</mat-label>
              <input matInput [(ngModel)]="postal_PostalCode" name="postal_PostalCode" placeholder="Postal Postal Code"  [disabled]="!isEditClickedPostal">
            </mat-form-field>
          </div>

          <div class="col-5">
            <mat-form-field appearance="outline">
              <mat-label>Postal Country Code</mat-label>
              <input matInput [(ngModel)]="postal_CountryCode" name="postal_CountryCode" placeholder="Postal Country Code" [disabled]="!isEditClickedPostal">
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-5">
            <mat-form-field appearance="outline">
              <mat-label>Postal City Code</mat-label>
              <input matInput [(ngModel)]="postal_CityCode" name="postal_CityCode" placeholder="Postal City Code" [disabled]="!isEditClickedPostal">
            </mat-form-field>
          </div>
        </div>
    </div>
</div>
<div class="button-container">
  <button type="button" class="btn btn-primary mt-4 custom-button" (click)="updateAddressInfo()">Save</button>
</div>
