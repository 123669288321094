import { Injectable } from '@angular/core';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class WebsocketsService {
  private socket$: WebSocketSubject<any>;
  private websocketUrl = environment.webSocketUrl;
  constructor(private auth: AuthService) {
    const email = this.auth.getLoggedInUserId().contactInfo.Email;
    const userType = this.auth.getLoggedInUserId().userType;
    if(userType === 'admin'){
      this.socket$ = webSocket(`${this.websocketUrl}?email=admin`);
    }
    else{
      this.socket$ = webSocket(`${this.websocketUrl}?email=${email}`);
    }
    this.socket$.subscribe(
      (message) => console.log('message received: ', message),
      (err) => console.error('error:', err),
      () => console.warn('connection closed')
    );
  }
  sendMessage(message: any): void {
    this.socket$.next(message);
  }
  onMessage() {
    return this.socket$.asObservable();
  }

  onError() {
    console.log('error')
    // return this.socket$.pipe((_: any, source: any) => source);
  }
}
