<div class="otp-container">
    <div class="otp-content">
        <img src="../../../assets/logo.svg" alt="Logo">
        <div class="otp-form">
         <p *ngIf="type === 'contactNumber' " class="description">To update your mobile number, kindly verify the new number. Please proceed by selecting "Get OTP" below.
         </p>
         <p *ngIf="type === 'email' " class="description">To update your email address, kindly verify the new email. Please proceed by selecting "Get OTP" below.
        </p>
         <form [formGroup]="verificationForm">
            <div  class="verify-otp" *ngIf="otpFormSubmitted">
                <div class="verification">
                  <h2>Account Verification</h2>
                  <p id="otpsent">OTP Sent!</p>
                  <p id="enterotp">Please enter the 5 digit OTP sent to your mobile number</p>
                </div>
                <div class="otp-container-details">
                  <ng-otp-input  (onInputChange)="onOtpChange($event)"  [config]="{length:5}"></ng-otp-input>
                </div>
                <p id="remainingTime">Remaining Time: {{ displayTime }}</p>
                <p id="resentOTP">Didn’t receive an OTP?<span id="sendAgain" (click)="requestOtp()"> Send again</span></p>
                <div class="continue">
                  <button button type="button" class="btn btn-success mt-4 custom-button" mat-button (click)="verifyEmail()">SUBMIT</button>
                </div>
              </div>
              <div class="getOTP" *ngIf="!otpFormSubmitted">
                <div class="verification">
                  <h2>Account Verification</h2>
                  <p id="otpsent">With OTP</p>
                </div>
                <div *ngIf="type === 'contactNumber' " class="otp-inputs mt-4">
                  <mat-form-field appearance="outline" [style.width.px]="300">
                    <mat-label>Enter Mobile Number</mat-label>
                    <input matInput formControlName="otpNumber" >
                  </mat-form-field>
                </div>
                <div *ngIf="type === 'email' " class="otp-inputs mt-4">
                  <mat-form-field appearance="outline" [style.width.px]="300">
                    <mat-label>Enter Email Address</mat-label>
                    <input matInput formControlName="otpEmail" >
                  </mat-form-field>
                </div>
                <div class="continue" *ngIf="type === 'contactNumber' " >
                    <button button type="button" class="btn btn-success mt-4 custom-button" mat-button (click)="requestOTPSMS()">GET OTP</button>
                </div>
                <div class="continue" *ngIf="type === 'email' " >
                  <button button type="button" class="btn btn-success mt-4 custom-button" mat-button (click)="requestOtp()">GET OTP</button>
                </div>
                <div class="cancel d-flex justify-content-center align-items-center">
                    <button type="button" class="btn btn-outline-primary mt-2" (click)="cancelUpdate()">Cancel</button>
                </div>
              </div>  
         </form>
        </div>
    </div>
</div>

