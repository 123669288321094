import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class UploadfilesService {
  private baseUrl = environment.baseUrl;
  private token = localStorage.getItem('token');

  constructor(private httpClient: HttpClient) { }

  uploadFile(file: File) {
    const formData = new FormData();
    formData.append('file', file);
    const headers = new HttpHeaders({ 'Authorization': `Bearer ${this.token}` });
    return this.httpClient.post<any>(`${this.baseUrl}/uploadBillboardImage`, formData, { headers });
  }

  compressImage(file: File): Observable<File> {
    const formData = new FormData();
    formData.append('file', file);
    const headers = new HttpHeaders({ 'Authorization': `Bearer ${this.token}`, 'responseType': 'arraybuffer' });
    return this.httpClient.post(`${this.baseUrl}/compress`, formData, {
      headers: headers,
      observe: 'response',
      responseType: 'arraybuffer' as 'json',
    }).pipe(
      map((response: HttpResponse<any>) => {
        const compressedBlob = new Blob([response.body as ArrayBuffer], { type: 'image/jpeg' });
        const compressedFile = new File([compressedBlob], file.name, { lastModified: Date.now(), type: 'image/jpeg' });
        return compressedFile;
      })
    );
  }
}
