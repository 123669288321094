<h1>Users</h1>
<div class="row">
    <div style="display: flex; justify-content: space-between;">
        <div class="search-container">
            <div class="form-group has-search" style="flex: 1; position: relative; margin-right: 10px;">
                <input type="text" class="form-control" placeholder="Search" style="width: 400px;" [(ngModel)]="searchText">
                <mat-icon class="search-icon">search</mat-icon>
            </div>
        </div>
        <div (clickOutside)="onClickedOutsideSort()" class="dropdown" [class.open]="isDropdownOpen" (click)="toggleDropdown()">
            <span id="sort-by" style="margin-right: 18px;">Sort By:</span>
            <mat-icon class="dropdown-icon">keyboard_arrow_down</mat-icon>
            <div class="dropdown-content" >
              <p (click)="sortBy('default')">Default</p>
              <p (click)="sortBy('approved')">Registration Status</p>
              <p (click)="sortBy('contactInfo.FirstName')">A - Z (User Name)</p>
              <p (click)="sortBy('createdAt')">Date</p>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-12">
        <div class="table-container mt-4">
            <table id="users" class="users-table">
                <tr>
                    <th id="date"> Date </th>
                    <th id="time"> Time </th>
                    <th id="first-item">User Name</th>
                    <th id="email"> Email </th>
                    <th id="second-item">User Description</th>
                    <th>Registration Status</th>
                    <th id="last-item">Action</th>
                </tr>
                <tr *ngFor="let user of usersList?.users | filter:searchText">
                  <td>{{ user.createdAt | date:'dd-MM-yyyy' }}</td>
                  <td>{{ user.createdAt | date:'HH:mm:ss' }}</td>
                  <td>{{ user.contactInfo.FirstName }} {{ user.contactInfo.Surname }}</td>
                  <td>{{ user.email }}</td>
                  <td>{{ user.userType }}</td>
                  <td [ngClass]="{'approved': user.registrationStatus == 'Approved','rejected': user.registrationStatus == 'Rejected', 'pending': user.registrationStatus == 'Pending'}">
                    {{ user.registrationStatus }}
                  </td>
                  <td>
                    <mat-icon class="more-items" (click)="toggleOptions($event, user)" (clickOutside)="onClickedOutside()">more_vert</mat-icon>
                    <div class="options-container" *ngIf="isOptionsOpen && selectedUser === user">
                      <p data-bs-toggle="modal" data-bs-target="#documents">View Documents</p>
                      <p data-bs-toggle="modal" data-bs-target="#approve">Approve Request</p>
                      <p data-bs-toggle="modal" data-bs-target="#reject">Reject Request</p>
                      <p data-bs-toggle="modal" data-bs-target="#refer">Referred Back</p>
                    </div>
                  </td>
                </tr>
            </table>
        </div>
    </div>
</div>
<div class="modal fade" id="approve" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel">Confirmation</h1>
          <div data-bs-theme="dark">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
        </div>
        <div class="modal-body">
           <h3>
            Are you sure you want to Approve this user?
           </h3>
           <p class="header-description">This action cannot be undone</p>
           <p class="user-label">Full Name: <span class="label-span">{{ selectedUser?.contactInfo.FirstName }} {{ selectedUser?.contactInfo.Surname }}</span></p>
           <p class="user-label">User Description: <span class="label-span">{{ selectedUser?.role }}</span></p>

           <h3 class="mb-4">Select one or more:</h3>
           <div class="checkBox">
            <input type="checkbox" id="IdDocument" [(ngModel)]="IdDocument" (ngModelChange)="onDocumentChange('identity_document_front')" />
            <label for="IdDocument">Approve ID Document</label>
          </div>
          
          <div class="checkBox">
            <input type="checkbox" id="company_Registration_Document" [(ngModel)]="company_Registration_Document" (ngModelChange)="onDocumentChange('company_registration')" />
            <label for="company_Registration_Document">Approve Company Registration Document</label>
          </div>
          
          <div class="checkBox">
            <input type="checkbox" id="LetterOfAuthority" [(ngModel)]="LetterOfAuthority" (ngModelChange)="onDocumentChange('company_letter')" />
            <label for="LetterOfAuthority">Approve Letter of Authority</label>
          </div>
          
          <div class="checkBox">
            <input type="checkbox" id="proofOfAddress" [(ngModel)]="proofOfAddress" (ngModelChange)="onDocumentChange('proof_of_address')" />
            <label for="proofOfAddress">Approve Proof of Address</label>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal" (click)="approveUser(selectedUser?.email,true)">Approve</button>
          <button type="button" class="btn btn-outline-primary"  data-bs-dismiss="modal">Cancel</button>
        </div>
      </div>
    </div>
</div>
<div class="modal fade" id="refer" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel">Confirmation</h1>
          <div data-bs-theme="dark">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
        </div>
        <div class="modal-body">
           <h3>
            Are you sure you want to Refer this user?
           </h3>
           <p class="header-description">This action cannot be undone</p>
           <p class="user-label">Full Name: <span class="label-span">{{ selectedUser?.contactInfo.FirstName }} {{ selectedUser?.contactInfo.Surname }}</span></p>
           <p class="user-label">User Description: <span class="label-span">{{ selectedUser?.role }}</span></p>
           <p id="reason-label">Reason:</p>
           <mat-form-field class="other-form-field" appearance="outline">
            <mat-label>Refer Reason</mat-label>
            <textarea matInput [(ngModel)]="referReason" name="referReason" placeholder="Type your reason here.."></textarea>
           </mat-form-field>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal" (click)="ReferUser()">Reject</button>
          <button type="button" class="btn btn-outline-primary"  data-bs-dismiss="modal">Cancel</button>
        </div>
      </div>
    </div>
</div>
<div class="modal fade" id="reject" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel">Confirmation</h1>
          <div data-bs-theme="dark">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
        </div>
        <div class="modal-body">
            <h3>
            Are you sure you want to Reject this user?
            </h3>
            <p class="header-description">This action cannot be undone</p>
            <p class="user-label">Full Name: <span class="label-span">{{ selectedUser?.contactInfo.FirstName }} {{ selectedUser?.contactInfo.Surname }}</span></p>
            <p class="user-label">User Description: <span class="label-span">{{ selectedUser?.role }}</span></p>
            <h2>Select a Reason:</h2>
            <label class="container-label">
              <input type="radio" checked="checked" name="reason" [(ngModel)]="selectedReason" value="ID Document not valid">
              <span class="checkmark"></span>
              ID Document not valid
            </label>
            <label class="container-label">
              <input type="radio" name="reason" [(ngModel)]="selectedReason" value="Proof of Address Document not valid">
              <span class="checkmark"></span>
              Proof of Address Document not valid
            </label>
            <label class="container-label">
                <input type="radio" name="reason" [(ngModel)]="selectedReason" value="Other" (change)="onOtherSelected()">
                <span class="checkmark"></span>
                Other
            </label>
            <mat-form-field class="other-form-field" appearance="outline" *ngIf="selectedReason === 'Other'">
                <mat-label>Enter Other Reason</mat-label>
                <textarea matInput [(ngModel)]="otherReason" name="otherReason" placeholder="Type your reason here.."></textarea>
            </mat-form-field>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal" (click)="rejectDocument()">Reject</button>
          <button type="button" class="btn btn-outline-primary" data-bs-dismiss="modal">Cancel</button>
        </div>
      </div>
    </div>
</div>


<div class="modal fade" id="documents" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="exampleModalLabel"></h1>
        <div data-bs-theme="dark">
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
      </div>
      <div class="centered-carousel">
        <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
          <div class="carousel-inner">
            <div class="carousel-item" *ngFor="let imageUrl of selectedUser?.imageUrl; let i = index" [ngClass]="{ 'active': i === 0 }">
              <ng-container *ngIf="imageUrl.endsWith('.jpg') || imageUrl.endsWith('.png')">
                <img [src]="imageUrl" class="d-block w-100" alt="Slide {{ i + 1 }}">
              </ng-container>
              <ng-container *ngIf="imageUrl.endsWith('.pdf')">
                <pdf-viewer [src]="imageUrl"
                            [render-text]="true"
                            style="width: 400px; height: 500px"
                            [rotation]="0"
                            [original-size]="false"
                            [show-all]="true"
                            [fit-to-page]="false"
                            [zoom]="1"
                            [zoom-scale]="'page-width'"
                            [stick-to-page]="false"
                            [external-link-target]="'blank'"
                            [autoresize]="true"
                            [show-borders]="false">
                </pdf-viewer>
              </ng-container>
            </div>
          </div>
          <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
            <span class="circular-arrow-icon" aria-hidden="true">&lt;</span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
            <span class="circular-arrow-icon" aria-hidden="true">&gt;</span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>