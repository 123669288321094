<h2 >Customer Support </h2>
<div class="container" *ngIf="createTicketClicked || !selectedChat ">
    <h4>Open New Ticket</h4>
    <hr>
    <input type="text" placeholder="Subject" [(ngModel)]="subject">
    <textarea placeholder="Content" rows="8" cols="50" [(ngModel)]="content"></textarea>
    <div class="file-upload-container">
        <mat-icon class="upload-icon">add_circle_outline</mat-icon>
        <label for="id-back" class="file-label" >
             {{filename}}
        </label>
        <input type="file" id="id-back" class="file-input" (change)="onFileSelected($event)" />
    </div>
    <div class="button-container">
        <button type="button" class="btn btn-primary mt-4 custom-button" (click)="sendEnquiry()">Submit</button>
    </div>
</div>

<div class="chat-container" *ngIf="!createTicketClicked && selectedChat">
    <div class="row mt-4 account-screen" id="personal-details">
        <div class="col-3 messages-container">
            <div class="chat-message-header">
                <h2 id="chat-message-header">Tickets</h2>
                <mat-icon id="add-comment-icon" (click)="createTicket()">add_comment</mat-icon>
            </div>
            <div class="search-container">
                <div class="form-group has-search">
                    <span class="fa fa-search form-control-feedback"></span>
                    <input type="text" class="form-control" placeholder="Search tickets"   [(ngModel)]="searchTerm">
                </div>
            </div>
            <div class="control-messages-scroll">
                <div class="chat-message" *ngFor="let enquiry of enquiries | filter: searchTerm" [class.selected]="selectedChat === enquiry">
                    <div class="message-details" (click)="setSelectedChat(enquiry)">
                      <div class="message-header">
                        <h5 id="enquiry-ticket-label">Ticket #{{enquiry._id}}</h5>
                        <!-- <span *ngIf="enquiry.unread" class="unread-dot"></span> -->
                        <span class="check-circle" *ngIf="enquiry?.resolved"><mat-icon id="check-circle">check_circle</mat-icon></span>
                      </div>
                      <div class="message-footer">
                        <p class="paragraph-content">{{enquiry.message}}</p>
                        <span class="time">{{formatDateForSubject(enquiry.date)}}</span>
                      </div>
                    </div>
                </div>
            </div>

            <!-- <div class="chat-message">
                <div class="message-details">
                  <div class="message-header">
                    <h5>Ticket # - 10003</h5>
                    <span class="unread-dot"></span>
                  </div>
                  <div class="message-footer">
                    <p class="paragraph-content">This is the paragraph content</p>
                    <span class="time">12:03</span>
                  </div>
                </div>
            </div> -->
        </div>
        <div class="col-8 personal-details-container">
            <div class="header">
                <div class="subject" >
                    <p>Ticket #{{this.selectedChat?._id}}</p>
                </div>
                <div class="resolve-ticket-button" *ngIf="!selectedChat.resolved">
                    <button button type="button" class="btn btn-success custom-button" (click)="resolveTicket()">Resolve Ticket</button>
                </div>
            </div>
            <hr id="line-content-divide">
            <div class="my-messages" #messageContainer >
                <div class="imessage">
                    <span class="date-time">{{ formatDateTime(selectedChat?.date) }}</span>
                    <span class="label-chat-sender-receiver">Subject</span>
                    <p class="from-me margin-b_one">{{selectedChat?.message || 'no messages in chat'}}</p>
                    <span class="label-chat-sender-receiver" *ngIf="selectedChat?.attachment != ''">attachment</span>
                    <p class="from-me margin-b_one" *ngIf="selectedChat.attachment != ''"><img width="300px" height="300px" src="{{selectedChat.attachment}}"/></p>
                </div>  
                <div class="imessage" *ngFor="let reply of selectedChat?.replies">
                    <span class="label-chat-sender-receiver" *ngIf="reply.from == 'me'">me</span>
                    <span class="label-chat-sender-receiver-admin" *ngIf="reply.from == 'admin'">admin</span>
                    <p class="from-them margin-b_one" *ngIf="reply.from == 'admin'">{{reply.message}}</p>
                    <p class="from-me margin-b_one" *ngIf="reply.from == 'me'">{{reply.message}}</p>
                </div>
                <div class="chat-bubble-typing my-3" *ngIf="selectedChat?.typing">
                    <img src="../../assets/gif/circles-menu.gif" alt="User is typing">
                </div>
            </div>
            <div class="reply-input" *ngIf="!selectedChat.resolved">
              <input [(ngModel)]="replyMessage" type="text" placeholder="Type your reply..." (input)="onTyping()">
              <div class="sent-container" (click)="sendReply()">
                <mat-icon class="sent-container-icon" >send</mat-icon>
              </div>
            </div>
            <div class="reply-input-ticket-resolved" *ngIf="selectedChat.resolved">
                <p id="ticket-is-resolved">The ticket # {{selectedChat._id}} has been resolved ...</p>
            </div>
        </div>
    </div>
</div>

