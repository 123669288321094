<div  class="status-container" *ngIf="status === 'success'">
    <div class="content">
        <div class="circle-container">
            <mat-icon class="tick-icon">done</mat-icon>
        </div>
        <div class="message">
            <h3>
                Verification Complete
            </h3>
            <p>Your video has been successfully recorded, verified, and forwarded to our administrative team along with your documents. 
                Please be aware that upon approval by our team, you will receive an email notification, enabling you to log in to your portal. 
                Thank you for your cooperation and patience throughout the verification process.
            </p>
        </div>
        <button type="button" class="btn btn-primary" (click)="navigateToLogin()"> Done</button>
    </div>
</div>
<div class="status-container" *ngIf="status === 'failed'">
    <div class="content">
        <div class="circle-container failed">
            <mat-icon class="tick-icon">clear</mat-icon>
        </div>
        <div class="message">
            <h3 class="failed">
                Verification Unsuccessful
            </h3>
            <p >Please Re-record your video</p>
        </div>
        <button type="button" class="btn btn-primary failed"  (click)="retakeVideoAuth()"> RE - TAKE VIDEO </button>
    </div>
</div>