import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StreetViewService {

  private baseUrl = environment.baseUrl;
  private token = localStorage.getItem('token');
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.token}`
    })
  };

  constructor(private http: HttpClient) { }
  
  getStreetViewLink(latitude: any, longitude: any) {
    const url = `${this.baseUrl}/google-street-view`;
    const requestBody = {
      latitude: latitude,
      longitude: longitude
    };
    return this.http.post(url, requestBody, this.httpOptions);
  }
  
}
