import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AuthService } from 'src/services/auth.service';
import { DatabasesService } from 'src/services/databases.service';
import { UserService } from 'src/services/user.service';
import { AlertService } from 'src/shared/alert.service';

@Component({
  selector: 'app-billboard-listing',
  templateUrl: './billboard-listing.component.html',
  styleUrls: ['./billboard-listing.component.scss']
})
export class BillboardListingComponent implements OnInit {
  isDropdownOpen = false;
  isOptionsOpen = false;
  markers = new Array();
  selectedBillboard: any;
  @Output() viewonmap = new EventEmitter<any>();
  @Input() searchText:any;
  @Input() type: any;
  email : any;
  constructor(private db: DatabasesService,private _alert:AlertService,private users:UserService,private auth: AuthService) { }

  ngOnInit(): void {
    this.email = this.auth.getLoggedInUserId().contactInfo.Email;
    this.retriveBillboards();
  }
  retriveBillboards(){
    this.db.getBillboard().subscribe((POIObjectData:any)=>{
      this.markers = POIObjectData.pointOfinterest;
      if(this.type === 'myBillboardsListing'){
        this.markers = POIObjectData.pointOfinterest.filter( (el:any) => {
          return el.advertiser === this.email;
        })
      } 
      this.markers.forEach((element,index) => {
        if(element.advertiser){
          this.users.getUserByEmail(element.advertiser).subscribe(user=>{
            this.markers[index].user = user;
          })
        }
      });
    })
  }
  viewOnMap(selectedBillboard:any){
    if(this.type === 'myBillboardsListing')
    {
      selectedBillboard.type = 'Mymap';
    }
    else{
      selectedBillboard.type = 'map';
    }
    this.viewonmap.emit(selectedBillboard);
  }
  onClickedOutside() {
    if(this.isOptionsOpen){
      this.isOptionsOpen = false;
    }
  }
  toggleOptions(event: Event, billboard: any) {
    event.stopPropagation(); 
    this.isOptionsOpen = !this.isOptionsOpen;
    this.selectedBillboard = billboard;
  }
  deleteBillboard(){
    this.db.deleteBillboard(this.selectedBillboard._id).subscribe({
      next: (data: any) => {
        this.retriveBillboards();
        this._alert.notificationToast('success', 'Billboard successfully deleted');
      },
      error: (error: any) => {
        console.error('Error:', error);
        this._alert.notificationToast('error', error.error.message || 'An error occurred.');
      },
      complete: () => {
      }
    });
  }
}
