import { ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/services/auth.service';
import { UserService } from 'src/services/user.service';
import { AlertService } from 'src/shared/alert.service';

@Component({
  selector: 'app-otp-page',
  templateUrl: './otp-page.component.html',
  styleUrls: ['./otp-page.component.scss']
})
export class OtpPageComponent implements OnInit {
  otpFormSubmitted: boolean = false;
  verificationForm!: FormGroup;
  OTPverificationInput: boolean = true;
  otpInput: any;
  remainingTime: number = 300;
  displayTime: string = '05:00'
  intervalId: any;
  email: string = '';
  newEmail:  string = '';
  contactNumber: string = '';
  newContactNumber:  string = '';
  type: string = '';
  regType: string = 'media-owner';
  constructor(private route: ActivatedRoute,private ngZone: NgZone,private user:UserService,private _formBuilder: FormBuilder,private _alert: AlertService,
    private ref: ChangeDetectorRef,private router: Router,private auth: AuthService) {
    this.route.queryParams.subscribe(params => {
        const receivedData = params;
        this.type = receivedData['type'];
        this.email = receivedData['email'];
        if(receivedData['type'] === 'email'){
          this.email = receivedData['email'];
          this.newEmail = receivedData['newEmail'];
        }
        else if(receivedData['type'] === 'contactNumber'){
          this.contactNumber = receivedData['contactNumber'];
          this.newContactNumber = receivedData['newcontactNumber'];
        }
    });
    this.verificationForm = this._formBuilder.group({
      verificationType: ['SMS', Validators.required],
      otpInput:['', Validators.required],
      otpEmail: [{value: this.newEmail, disabled: true}, Validators.email],
      otpNumber: [{value: this.newContactNumber , disabled: true}]
    });
   }

  ngOnInit(): void {
  }
  get verificationTypeControl() {
    return this.verificationForm.get('verificationType');
  }
  get isSmsSelected() {
    return this.verificationTypeControl!.value === 'SMS';
  }
  get isEmailSelected() {
    return this.verificationTypeControl!.value === 'Email';
  }
  requestOtp(){
    this.stopTimer();
    this.user.requestOTPUpdateChanges(this.newEmail,this.email).subscribe({
      next: (regStatus:any) => {
        this.otpFormSubmitted = true;
        this.ref.detectChanges();
        this.startTimer();
        this._alert.notificationToast("success",regStatus.message)
      },
      error: error => {
        this._alert.notificationToast("warning",error.error.message)
      },
      complete: () => {
      }
    });
  }
  requestOTPSMS(){
    this.stopTimer();
    this.user.requestOTPSMSUpdateChanges(this.contactNumber,this.newContactNumber).subscribe({
      next: (regStatus:any) => {
        this.otpFormSubmitted = true;
        this.ref.detectChanges();
        this.startTimer();
        this._alert.notificationToast("success",regStatus.message)
      },
      error: error => {
        this._alert.notificationToast("warning",error.error.message)
      },
      complete: () => {
      }
    });
  }
  startTimer() {
    this.intervalId = setInterval(() => {
      this.updateTimer();
    }, 1000);
  }
  stopTimer() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.remainingTime = 300;
    }
  }
  updateTimer() {
    if (this.remainingTime > 0) {
      this.remainingTime--;
      this.updateDisplayTime();
    } else {
    }
  }
  updateDisplayTime() {
    const minutes = Math.floor(this.remainingTime / 60);
    const seconds = this.remainingTime % 60;
    this.displayTime = `${this.padNumber(minutes)}:${this.padNumber(seconds)}`;
  }
  padNumber(num: number): string {
    return num < 10 ? '0' + num : num.toString();
  }
  onOtpChange(value:any){
    this.otpInput = value;
  }
  verifyEmail(){
    if(this.remainingTime == 0){
      this.stopTimer();
      this.remainingTime = 0;
      this._alert.notificationToast('warning','OTP has expired request another one!');
      return;
    }
    this.user.verifyOTP(this.email,this.otpInput).subscribe({
      next: (regStatus:any) => {
        if(regStatus.status){
          this._alert.notificationToast("success",'otp successfully validated');
          localStorage.setItem('token', regStatus.token);
          if(this.type === 'email'){
            this.user.stageChanges(this.newEmail,'email',this.email).subscribe((data:any)=>{
              if(!data.status.success){
                this._alert.notificationToast('warning',data.status.message);
                this.router.navigate(['/dashboard']);
              }
              else if(data.status.success){
                this.auth.logOut();
              }
            })
          }
          if(this.type === 'contactNumber'){
            this.user.stageChanges(this.newContactNumber,'contactNumber',this.contactNumber).subscribe((data:any)=>{
              if(!data.status.success){
                this._alert.notificationToast('warning',data.status.message);
                this.router.navigate(['/dashboard']);
              }
              else if(data.status.success){
                this.auth.logOut();
              }
            })
          }
          
     
        }
        else{
          this._alert.notificationToast("warning",'error validating otp');
        }
      },
      error: error => {
        console.error('Error handler:', error);
        this._alert.notificationToast("warning",error.error.message)
      },
      complete: () => {
      }
    });
  }
  cancelUpdate(){
    this.router.navigate(['/dashboard']);
  }

}
