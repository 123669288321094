import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ScrapperService {
  private baseUrl = environment.baseUrl;
  private linkScrapperUrl = `${this.baseUrl}/LinkScrapper`;
  private economicProfileUrl = `${this.baseUrl}/economic-profile`;
  private token = localStorage.getItem('token');
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.token}`
    })
  };
  constructor(private http: HttpClient) { }
  scrapeLinks(address: any): Observable<any> {
    const requestData = {
      address: address,
    };
    return this.http.post(this.linkScrapperUrl, requestData, this.httpOptions);
  }
  getEconomicProfile(url: string): Observable<any> {
    const requestData = {
      url: 'https://www.property24.com'+url
    };
    return this.http.post(this.economicProfileUrl, requestData, this.httpOptions);
  }
}
