import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { LoaderComponent } from '../shared/loader/loader.component';



@Injectable({
  providedIn: 'root'
})
export class AlertService {

  private overlayRef: OverlayRef | undefined;

   Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 2000,
    customClass: {
      popup: 'single-line-toast' 
    },
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  });

  constructor(private overlay: Overlay) {}

notificationToast(type: any,message: any){
  this.Toast.fire({
    icon: type,
    title: message
  });
}

showLoader() {
  const config: OverlayConfig = {
    hasBackdrop: true,
    backdropClass: 'cdk-overlay-dark-backdrop',
    positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
  };
  this.overlayRef = this.overlay.create(config);
    const portal = new ComponentPortal(LoaderComponent);
    this.overlayRef.attach(portal);
}

hideLoader() {
  this.overlayRef?.dispose();
}

}
