<div class="custom-page">
    <div class="content-section">
        <!-- Left content section -->
        <div class="Logo mb-4 mt-4">
            <img src="../../assets/general-user/logo.svg" alt="Icon" >
        </div>
        <div class="travel-logo">
            <img src="../../assets/general-user/travel.svg" alt="Icon" >
        </div>
        
    </div>

    <div class="content-section-right second-content mr-4">
        <p class="close-modal">
            <mat-icon (click)="emitShowGeneralUserInfo()">close</mat-icon>
        </p>
        <h1>Welcome to Ad Drop Maps</h1>
        <p class="intro-section">Your reliable resource for quick access to comprehensive information about any address. 
            whether it's about businesses, residences, or points of interest. 
            Ad Drops makes it easy for you to obtain the information you're looking for.</p>

        <div class="content-item">
            <img src="../../assets/general-user/general-user-location.svg" alt="Icon" class="icon">
            <div class="label-text">
                <p class="label">Select a Pin</p>
                <p class="text">To view a billboard on the map simply tap on a pin.</p>
            </div>
        </div>
        <div class="mt-2 mb-2">
            <p class="else-section">--- OR YOU MAY</p>
        </div>
        <div class="content-item">
            <img src="../../assets/general-user/Search.svg" alt="Icon" class="icon">
            <div class="label-text">
                <p class="label">Search Address</p>
                <p class="text">Enter a specific address into the search bar and tap on the pin to access more information.</p>
            </div>
        </div>
        <div class="content-item">
            <img src="../../assets/general-user/Satellite.svg" alt="Icon" class="icon">
            <div class="label-text">
                <p class="label">Satellite View</p>
                <p class="text">To access satellite view, click the satellite view to only get a piece of map and click google satellite to be redirected to google maps for full satellite view.</p>
            </div>
        </div>
        <div class="button"> 
            <button button type="button" class="btn btn-success mt-4 custom-button" (click)="emitShowGeneralUserInfo()">
                START EXPLORING
            </button>
        </div>

    </div>
</div>
