import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { EnquiryChatService } from 'src/services/enquiry-chat.service';
import { UserService } from 'src/services/user.service';
import { DatePipe } from '@angular/common';
import { AlertService } from 'src/shared/alert.service';
import { Observable } from 'rxjs/internal/Observable';
import { WebsocketsService } from 'src/services/websockets.service';

@Component({
  selector: 'app-admin-chat-channel',
  templateUrl: './admin-chat-channel.component.html',
  styleUrls: ['./admin-chat-channel.component.scss']
})
export class AdminChatChannelComponent implements OnInit, AfterViewInit {
  enquiries: any[] = [];
  chats: any = {};
  selectedChat: any;
  replyMessage: any;
  selectedUser: any;
  selectedChatKey: string | null = null;
  enquiriesSelectedUser : any = new Array();
  searchTerm: any;
  typingTimeout: any;
  // @ViewChild('messageContainer')
  @ViewChild('messageContainer', { static: false })
  messageContainer!: ElementRef;
  // messageContainer!: ElementRef;
  constructor(
    private enquiryChat: EnquiryChatService,
    private user: UserService,
    private datePipe: DatePipe,
    private alert: AlertService,
    private cdr: ChangeDetectorRef,
    private webSocketService: WebsocketsService) { }

  ngOnInit(): void {
    this.getAllenquiriesChats();
    this.webSocketService.onMessage().subscribe({
      next: (message) => 
      {
        if(message.type && message.type === 'typing_status' && message.isTyping){
          this.enquiries.forEach(element => {
            if(element._id === message.chatId){
              element.typing = true;
              this.scrollToBottom();
            }
          });
        }
        else if(message.type && message.type === 'typing_status' && !message.isTyping){
          this.enquiries.forEach(element => {
            if(element._id === message.chatId){
              element.typing = false;
              this.scrollToBottom();
            }
          });
        }
        else if(message.type && message.type === 'enquiry'){
          this.enquiries.unshift(message.enquiry);
        }
        else{
          this.enquiries.forEach(element => {
            if(element._id === message._id){
              element.replies.push(message);
              this.scrollToBottom();
            }
          });
        } 

      },
      error: (error) => {console.error('WebSocket error:', error)}
      }
    );
  }
  ngAfterViewInit(): void {
    
  }
  ngOnDestroy() {
    // Clear the typing timeout when the component is destroyed
    clearTimeout(this.typingTimeout);
  }
  onTyping() {
    
    clearTimeout(this.typingTimeout);
    this.typingTimeout = setTimeout(() => {
      this.sendTypingStatus(false);
    }, 3000);
    this.sendTypingStatus(true);
  }
  private sendTypingStatus(isTyping: boolean) {
    const receiverEmail = this.selectedChat.email; 
    const chatId = this.selectedChat._id;

    const typingStatusMessage = {
      type: 'typing_status',
      isTyping: isTyping,
      receiverEmail: receiverEmail,
      chatId: chatId
    };
    this.webSocketService.sendMessage(typingStatusMessage);
  }
  getProfilePicture(email: string): Observable<string> {
    return new Observable<string>((observer) => {
      let documentUrl = 'none';
      this.user.getUploadedDocuments(email).subscribe({
        next: (docs: any) => {
          docs.forEach((document: any) => {
            if (document.metadata.type === 'profile_picture') {
              documentUrl = document.url;
            }
          });
          observer.next(documentUrl);
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
        },
      });
    });
  }
  getAllenquiriesChats(){
    this.enquiries = [];
    this.enquiryChat.getALLEnquiries().subscribe((response:any)=>{
      response.forEach((element: any, index: any) => {
            this.getProfilePicture(element.email).subscribe(
              (documentUrl: string) => {
                // const fileUrl = documentUrl.replace("https://ad-drop-maps-api.shayelasolutions.co.za", "http://localhost:8008");
                element.profile_picture = documentUrl;
              },
              (error) => {
                console.error('Error:', error);
              }
            );
            this.enquiries.push(element);
      });

      this.enquiries.sort((a:any, b:any) => {
        const dateA = new Date(a.date).getTime();
        const dateB = new Date(b.date).getTime();
        if (a.resolved && !b.resolved) {
          return 1;
        } else if (!a.resolved && b.resolved) {
            return -1; 
        } else {
            return dateB - dateA;
        }
      });
      if(this.enquiries.length >= 1){
        this.setUserEnquiryProfile();
        this.selectedChat = this.enquiries[0];
        this.selectedChat.unread = false;
        this.scrollToBottom();
      }

    })
  }
  setUserEnquiryProfile(){
    this.enquiries.forEach(element => {
      this.user.getUserByEmail(element.email).subscribe((user:any)=>{
        element.username =  user.user.contactInfo.FirstName +' '+ user.user.contactInfo.Surname;
        element.company = user.user.companyInfo.companyName || '';
      })
    });
  }
  getSelectedUserEnquries(email:any){
    this.enquiryChat.getALLEnquiries().subscribe((response:any)=>{
      response.forEach((element: any, index: any) => {
          if(element.email  === email){
            this.enquiriesSelectedUser.push(element);
            //this.enquiryChat.markChatAsRead(element._id).subscribe(data=>{});
          }
      });
      this.selectedChat = this.enquiriesSelectedUser[0];
      this.selectedChat.unread = false;
    })
  }
  scrollToBottom(): void {
    try {
      requestAnimationFrame(() => {
        this.messageContainer.nativeElement.scrollTop = this.messageContainer.nativeElement.scrollHeight;
      })
      this.cdr.detectChanges();
    } catch(err) {
    }
  }
  getLatestMessage(messages: any[] | unknown): any {
    const messageArray = messages as any[];
    if (messageArray && messageArray.length > 0) {
      return messageArray.reduce((prev, current) => (new Date(prev.date) > new Date(current.date) ? prev : current));
    }
    return null;
  }
  formatDateTime(dateTime: string): string {
    return this.datePipe.transform(dateTime, 'EEE, dd MMM \'at\' HH:mm') || '';
  }
  formatDate(dateTime: string): string {
    const currentDate = new Date();
    const messageDate = new Date(dateTime);
    const timeDifference = currentDate.getTime() - messageDate.getTime();
    if (timeDifference >= 24 * 60 * 60 * 1000) {
      // More than 24 hours ago
      return this.datePipe.transform(dateTime, 'EEE, dd MMM \'at\' HH:mm') || 'Just now';
    } else if (timeDifference >= 60 * 60 * 1000) {
      // More than 1 hour ago
      return this.datePipe.transform(dateTime, 'HH:mm') || 'Just now';
    } else if (timeDifference >= 60 * 1000) {
      // More than 1 minute ago
      const minutesAgo = Math.floor(timeDifference / (60 * 1000));
      return `${minutesAgo} minute${minutesAgo > 1 ? 's' : ''} ago`;
    } else if (timeDifference >= 1000) {
      // More than 1 second ago
      const secondsAgo = Math.floor(timeDifference / 1000);
      return `${secondsAgo} second${secondsAgo > 1 ? 's' : ''} ago`;
    } else {
      // Less than 1 second ago
      return 'Just now';
    }
  }
  formatDateForSubject(dateTime: string): string {
    const currentDate = new Date();
    const messageDate = new Date(dateTime);
    const isSameDay = currentDate.toDateString() === messageDate.toDateString();
  
    if (!isSameDay) {
      return this.datePipe.transform(dateTime, 'dd MMM YYYY') || 'Just now';
    } else {
      return this.datePipe.transform(dateTime, 'HH:mm') || 'Just now';
    }
  }
  resolveTicket(){
    this.enquiryChat.resolveTicket(this.selectedChat._id).subscribe(data=>{
      this.alert.notificationToast('success',`The ticket #${this.selectedChat._id} has been resolved`);
      this.selectedChat.resolved = true;
    })
  }
  setSelectedChat(enquiry: any){
    this.selectedChat = enquiry;
    this.scrollToBottom();
  }
  sendReply() {
    const reply = {
      message: this.replyMessage,
      from: 'admin',
      date: new Date(),
    };
    const message = {
      senderEmail: 'admin',
      receiverEmail: this.selectedChat.email,
      message: this.replyMessage,
      _id: this.selectedChat._id,
      from: 'admin',
      date: new Date(),
    };
    this.webSocketService.sendMessage(message);
    this.selectedChat.replies.push(reply);
    this.scrollToBottom();
    this.replyMessage = '';
    this.enquiryChat.sendResponse(this.selectedChat._id,this.selectedChat.email,reply).subscribe(data=>{
     
    })
  }

}
