<form [formGroup]="physicalAddress!">
    <div class="row">
      <mat-form-field appearance="outline" [style.width.px]="300">
        <mat-label>Building Number</mat-label>
        <input matInput formControlName="BuildingNo" >
      </mat-form-field>
  
      <mat-form-field appearance="outline" [style.width.px]="300">
        <mat-label>Building Name</mat-label>
        <input matInput formControlName="BuildingName" >
      </mat-form-field>
    </div>
  
    <div class="row">
      <mat-form-field appearance="outline" [style.width.px]="300">
        <mat-label>Street Name</mat-label>
        <input matInput formControlName="StreetName" required>
      </mat-form-field>
  
      <mat-form-field appearance="outline" [style.width.px]="300">
        <mat-label>City/Town</mat-label>
        <input matInput formControlName="CityTown" required>
      </mat-form-field>
    </div>
  
    <div class="row">
      <mat-form-field appearance="outline" [style.width.px]="300">
        <mat-label>Province/State</mat-label>
        <input matInput formControlName="ProvinceState" required>
      </mat-form-field>
  
      <mat-form-field appearance="outline" [style.width.px]="300">
        <mat-label>Country</mat-label>
        <input matInput formControlName="Country" required>
      </mat-form-field>
    </div>
  
    <div class="row">
      <mat-form-field appearance="outline" [style.width.px]="300">
        <mat-label>Postal Code</mat-label>
        <input matInput formControlName="PostalCode" >
      </mat-form-field>
  
      <mat-form-field appearance="outline" [style.width.px]="300">
        <mat-label>Country Code</mat-label>
        <input matInput formControlName="CountryCode" required>
      </mat-form-field>
    </div>
  
    <div class="row">
      <mat-form-field appearance="outline" [style.width.px]="300">
        <mat-label>City Code</mat-label>
        <input matInput formControlName="CityCode" required>
      </mat-form-field>
    </div>
    <p id="uploadDoc"> Upload Documents </p>
    <p id="description">Please note: You have the option to bypass this step and upload your documents 
        later from your account in the "My Account" section. You will have a grace period of up to 5 days to 
        submit the necessary documents.</p>
    <div class="input-company">
        <div class="input-file">
            <div class="input">
              <div class="filename-container">
                <span class="file-label">{{ proofOfAddress}}</span>
              </div>
                <label for="myFile" class="choose-file-btn">Choose File</label>
                <input type="file" id="myFile" name="filename" (change)="uploadProofOfAddress($event)">
            </div>
        </div>
        <p id="format">Accepted formats: PNG, JPEG, PDF</p>
    </div>
</form>
<div>
  <input class="same-address" type="checkbox" id="same-address"  [(ngModel)]="isChecked" checked>
  <label for="same-address" id="label-address">Select this option if your postal address is the same as your residential address.</label><br>
</div>
<div class="thirdform" *ngIf="!isChecked">
  <form [formGroup]="PostOfficeAddress!" >
    <div class="row">
      <mat-form-field appearance="outline" [style.width.px]="300">
        <mat-label>PO Box</mat-label>
        <input matInput formControlName="PObox">
      </mat-form-field>
      <mat-form-field appearance="outline" [style.width.px]="300">
        <mat-label>City/Town</mat-label>
        <input matInput formControlName="CityTown">
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field appearance="outline" [style.width.px]="300">
        <mat-label>Province/State</mat-label>
        <input matInput formControlName="ProvinceState">
      </mat-form-field>
      <mat-form-field appearance="outline" [style.width.px]="300">
        <mat-label>Country</mat-label>
        <input matInput formControlName="Country">
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field appearance="outline" [style.width.px]="300">
        <mat-label>Postal Code</mat-label>
        <input matInput formControlName="PostalCode">
      </mat-form-field>
      <mat-form-field appearance="outline" [style.width.px]="300">
        <mat-label>Country Code</mat-label>
        <input matInput formControlName="CountryCode">
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field appearance="outline" [style.width.px]="300">
        <mat-label>City Code</mat-label>
        <input matInput formControlName="CityCode">
      </mat-form-field>
      <mat-form-field appearance="outline" [style.width.px]="300">
        <mat-label>Telephone No</mat-label>
        <input matInput formControlName="TelephoneNo">
      </mat-form-field>
    </div>
  </form>      
</div>
<div class="continue">
  <button button type="button" class="btn btn-success mt-4 custom-button" mat-button matStepperNext (click)="updateAddressInfo()">CONTINUE</button>
  <p class="newUser mt-2"> Already have an account? <span id="login-span" (click)="navigateToLogin()">Login</span></p>
</div>