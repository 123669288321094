import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }
  private baseUrl = environment.baseUrl;
  private token = localStorage.getItem('token');

  private getAuthHeaders() {
    return new HttpHeaders({
      'Authorization': `Bearer ${this.token}`
    });
  }

  resetPassword(newPassword: any, token: any) {
    const url = `${this.baseUrl}/auth/reset-password`;
    const requestBody = {
      newPassword: newPassword,
      token: token
    };
    return this.http.post(url, requestBody, { headers: this.getAuthHeaders() });
  }

  requestResetPasswordLink(email: any) {
    const url = `${this.baseUrl}/auth/requestResetPassword`;
    const requestBody = {
      email: email
    };
    return this.http.post(url, requestBody, { headers: this.getAuthHeaders() });
  }

  createAccount(email: any, password: any, contactInfo: any, postOfficeAddress: any, physicalAddress: any, documents_supplied: boolean, userType: string, companyDetails: any) {
    const url = `${this.baseUrl}/auth/register`;
    const requestBody = {
      email: email,
      password: password,
      contactInfo: contactInfo,
      userType: userType,
      documents_supplied: documents_supplied,
      postOfficeAddress: postOfficeAddress,
      physicalAddress: physicalAddress,
      companyInfo: companyDetails,
    };
    return this.http.post(url, requestBody, { headers: this.getAuthHeaders() });
  }

  verifyOTP(email: any, otp: any) {
    const url = `${this.baseUrl}/auth/verify-otp`;
    const requestBody = {
      email: email,
      otp: otp,
    };
    return this.http.post(url, requestBody, { headers: this.getAuthHeaders() });
  }

  requestOTP(email: any) {
    const url = `${this.baseUrl}/auth/email-otp`;
    const requestBody = {
      email: email,
    };
    return this.http.post(url, requestBody, { headers: this.getAuthHeaders() });
  }

  requestOTPUpdateChanges(newEmail: any, email: any) {
    const url = `${this.baseUrl}/auth/update-details/requestOTP`;
    const requestBody = {
      email: email,
      newEmail: newEmail
    };
    return this.http.post(url, requestBody, { headers: this.getAuthHeaders() });
  }

  requestOTPSMSUpdateChanges(contactNumber: any, newcontactNumber: any) {
    const url = `${this.baseUrl}/auth/sms/requestOTP`;
    const requestBody = {
      contactNumber: contactNumber,
      newcontactNumber: newcontactNumber
    };
    return this.http.post(url, requestBody, { headers: this.getAuthHeaders() });
  }

  updateUserContactInfo(contactInfo: any, email: any) {
    const url = `${this.baseUrl}/stage/updated-user-changes`;
    const requestBody = {
      email: email,
      updatedFields: { contactInfo: contactInfo }
    };
    return this.http.post(url, requestBody, { headers: this.getAuthHeaders() });
  }

  updateUserAddressInfo(email: any, physicalAddress: any, postOfficeAddress: any) {
    const url = `${this.baseUrl}/stage/updated-user-changes`;
    const requestBody = {
      email: email,
      updatedFields: { physicalAddress: physicalAddress, postOfficeAddress: postOfficeAddress }
    };
    return this.http.post(url, requestBody, { headers: this.getAuthHeaders() });
  }

  updateUserliveness(email: any, status: boolean) {
    const url = `${this.baseUrl}/users/update-user`;
    const requestBody = {
      email: email,
      updatedFields: { liveness: status }
    };
    return this.http.post(url, requestBody, { headers: this.getAuthHeaders() });
  }

  approveUserRequest(status: any, email: any) {
    const url = `${this.baseUrl}/users/update-user`;
    const requestBody = {
      email: email,
      updatedFields: { approved: status, registrationStatus: 'Approved' }
    };
    return this.http.post(url, requestBody, { headers: this.getAuthHeaders() });
  }

  rejectUserRequest(email: any, status: any) {
    const url = `${this.baseUrl}/users/update-user`;
    const requestBody = {
      email: email,
      updatedFields: { registrationStatus: status, approved: false }
    };
    return this.http.post(url, requestBody, { headers: this.getAuthHeaders() });
  }

  stageChanges(info: any, type: any, prevInfo: any) {
    const url = `${this.baseUrl}/stage/contact-changes`;
    const requestBody = {
      info: info,
      type: type,
      prev: prevInfo,
    };
    return this.http.post(url, requestBody, { headers: this.getAuthHeaders() });
  }

  getAllUsers() {
    const url = `${this.baseUrl}/users/get-all-users`;
    return this.http.get(url, { headers: this.getAuthHeaders() });
  }

  getLoggedinUser(email: any) {
    const url = `${this.baseUrl}/auth/get-user`;
    const requestBody = {
      email: email
    };
    return this.http.post(url, requestBody, { headers: this.getAuthHeaders() });
  }

  getUserByEmail(email: any) {
    const url = `${this.baseUrl}/auth/get-user`;
    const requestBody = {
      email: email
    };
    return this.http.post(url, requestBody, { headers: this.getAuthHeaders() });
  }

  uploadKycDoc(file: File, type: string, email: string) {
    const formData = new FormData();
    formData.append('file', file);
    const headers = this.getAuthHeaders().append('type', type).append('email', email);
    return this.http.post<any>(`${this.baseUrl}/uploadkycdoc`, formData, { headers });
  }

  getUploadedDocuments(email: string): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/docs/${email}`, { headers: this.getAuthHeaders() });
  }

  verifyDocument(email: any, type: any, fileName: any, status: any, reason: any) {
    const url = `${this.baseUrl}/verify-document`;
    const requestBody = {
      email: email,
      type: type,
      fileName: fileName,
      reason: reason,
      status: status
    };
    return this.http.post(url, requestBody, { headers: this.getAuthHeaders() });
  }

  loggedInuserChangePassword(email: any, password: any, newPassword: any) {
    const url = `${this.baseUrl}/auth/loggedin-user-changePassword`;
    const requestBody = {
      email: email,
      password: password,
      newPassword: newPassword,
    };
    return this.http.post(url, requestBody, { headers: this.getAuthHeaders() });
  }

  verifyCompanyReg(country: any, registrationNumber: any) {
    const url = `${this.baseUrl}/auth/verify-company-registration`;
    const requestBody = {
      country: country,
      registrationNumber: registrationNumber,
    };
    return this.http.post(url, requestBody, { headers: this.getAuthHeaders() });
  }

  getCountryNames(): Observable<{ name: string }[]> {
    return this.http.get<any[]>('https://restcountries.com/v3.1/all')
      .pipe(
        map(countries => countries.map(country => ({ name: country.name.common })))
      );
  }

  getUserNotifications(email: string) {
    const url = `${this.baseUrl}/retrieve/notifications`;
    const requestBody = {
      email: email
    };
    return this.http.post(url, requestBody, { headers: this.getAuthHeaders() });
  }

  readUserNotifications(email: string, content: string) {
    const url = `${this.baseUrl}/read/notifications`;
    const requestBody = {
      email: email,
      content: content
    };
    return this.http.post(url, requestBody, { headers: this.getAuthHeaders() });
  }

  sendApprovalMessage(email: string) {
    const url = `${this.baseUrl}/auth/send-approved-email`;
    const requestBody = {
      email: email
    };
    return this.http.post(url, requestBody, { headers: this.getAuthHeaders() });
  }

  requestSMSOTP(contactNumber: any) {
    const url = `${this.baseUrl}/auth/send-otp-message`;
    const requestBody = {
      contactNumber: contactNumber
    };
    return this.http.post(url, requestBody, { headers: this.getAuthHeaders() });
  }

}
