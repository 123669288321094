import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-liveness-awareness-page',
  templateUrl: './liveness-awareness-page.component.html',
  styleUrls: ['./liveness-awareness-page.component.scss']
})
export class LivenessAwarenessPageComponent implements OnInit {
  IdDocument: string = '';
  email: string = '';
  constructor(private router: Router,private route: ActivatedRoute) {
    this.route.queryParams.subscribe(params => {
      const receivedData = params;
      this.IdDocument = receivedData['IDdocument'],
      this.email = receivedData['email']
    });
   }

  ngOnInit(): void {
  }
  navigateToLiveness(){
    const documents = {
      IDdocument: this.IdDocument,
      email: this.email
    };
    this.router.navigate(['/liveness'], {
      queryParams: documents
    });
  }

}
