<!-- Your dialog content -->
<div style="width: 300px;height: 360px;">
    <h2 mat-dialog-title class="custom-dialog-title">Error</h2>
    <div mat-dialog-content class="mat-dialog-content">
      <h4 class="multiple-entries"><strong>Multiple Entries Detected</strong></h4>
      <p id="modal-content">
        We've noticed that you've entered both a phone number and an email for the change. Our system allows for updating only one of these at a time for security reasons. Please choose to update either your phone number or email and try again.
      </p>
    </div>
    <div mat-dialog-actions align="end">
      <span class="mat-button">
        <button type="button" mat-flat-button color="primary" [mat-dialog-close]="true">OK</button>
      </span>
    </div>
  </div>
  