import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: 'app-multiple-entries',
  templateUrl: './multiple-entries.component.html',
  styleUrls: ['./multiple-entries.component.scss'],
  encapsulation: ViewEncapsulation.None 
})
export class MultipleEntriesComponent implements OnInit {

  name: string;
  constructor(
  private _mdr: MatDialogRef<MultipleEntriesComponent>,@Inject(MAT_DIALOG_DATA) data: string) 
  {
    this.name = data;
  }
  ngOnInit(): void {
    
  }
  CloseDialog() {
    this._mdr.close(false)
  }
}
