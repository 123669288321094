<div class="mobile-border"></div>
<div class="resetPassword-container">
    <div class="resetPassword-content">
        <img class="responsive-logo" src="../../assets//logo.svg" alt="Logo">
        <div class="reset-details-container">
            <h1>Forgot Password?</h1>
        </div>
        <div class="description">
            <p class="paragraph-text">Enter the email address you used when you joined and <br>
            we’ll send you instructions to reset your password.
            <br>
            <br>
            For security reasons, we do NOT store your password.
            <br> 
            So rest assured that we will never send your password via email.</p>
        </div>
        <mat-form-field appearance="outline" class="col-lg-7 col-12">
            <mat-label>Email</mat-label>
            <input matInput [(ngModel)]="email" name="email" (ngModelChange)="onEmailChange($event)" required>
        </mat-form-field>
        <br>
        <button button type="button" class="btn btn-success mt-2 custom-button col-lg-7 col-12" (click)="submit()">SEND RESET INSTRUCTIONS</button>
        <br>

    </div>
</div>
<div class="mobile-footer">
    <div class="curved-div"></div>
    <div class="footer-content">
        <p class="mt-4 text-center mobile"> New User? <span>Create an account</span></p>
    </div>  
</div>
