import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from 'src/services/user.service';
import { AlertService } from 'src/shared/alert.service';
export interface AddressInfo {
  physicalAddress: FormGroup;
  postOfficeAddress: FormGroup;
}
@Component({
  selector: 'app-address-info',
  templateUrl: './address-info.component.html',
  styleUrls: ['./address-info.component.scss']
})
export class AddressInfoComponent implements OnInit {
  fileName: string | undefined;
  isChecked: boolean = true;
  @Input() physicalAddress: FormGroup | undefined;
  @Input() email: any;
  @Input() PostOfficeAddress: FormGroup | undefined;
  @Output() addressInfoUpdated = new EventEmitter<any>();
  proofOfAddress: string = 'Proof Of Address';
  constructor(private router:Router,private user: UserService,private alert: AlertService) {}
  ngOnInit(): void {}
  isDocument(file: File): boolean {
    const imageFormats = ['image/jpeg', 'image/png', 'application/pdf'];
    return imageFormats.includes(file.type);
  }
  async uploadProofOfAddress(event: any){
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      if (!this.isDocument(selectedFile)) {
        this.alert.notificationToast('warning','Selected file is not an allowed document type.');
        return;
      }
      if (selectedFile.size > 1024 * 1024) {
        this.alert.notificationToast('warning','Selected file exceeds the maximum size limit (1MB).');
        return;
      }
      this.proofOfAddress = selectedFile ? selectedFile.name : 'Proof Of Address';
      this.user.uploadKycDoc(selectedFile, 'proof_of_address', this.email ).subscribe(data=>{
      });
    }
  }
  updateAddressInfo(): void {
    this.addressInfoUpdated.emit({ physicalAddress: this.physicalAddress!, postOfficeAddress: this.PostOfficeAddress!,sameAddress:this.isChecked });
  }
  navigateToLogin(){
    this.router.navigate(['/login'])
  }

}
