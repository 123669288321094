import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserComponent } from './user/user.component';
import { AdminComponent } from './admin/admin.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { WebcamComponent } from './webcam/webcam.component';
import { LoginPageComponent } from './login-page/login-page.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { RegistrationPageComponent } from './registration-page/registration-page.component';
import { LivenessStatusComponent } from './liveness-status/liveness-status.component';
import { GeneralUserComponent } from './general-user/general-user.component';
import { LivenessAwarenessPageComponent } from './liveness-notice-page/liveness-awareness-page/liveness-awareness-page.component';
import { OtpPageComponent } from './users-page/otp-page/otp-page.component';
import { AuthGuard as AuthGuard } from 'src/services/auth.guard';
const routes: Routes = [
  { path: 'admin', component: AdminComponent, data: { title: 'Admin' } },
  { path: 'reset-password', component: ForgotPasswordComponent },
  { path: 'change-password', component: ResetPasswordComponent },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'webcam', component: WebcamComponent },
  { path: 'login', component: LoginPageComponent },
  { path: 'create-account', component: RegistrationPageComponent },
  { path: 'liveness', component: WebcamComponent },
  { path: 'liveness-notice', component: LivenessAwarenessPageComponent },
  { path: 'liveness/:status', component: LivenessStatusComponent },
  { path: 'general-user', component: GeneralUserComponent},
  { path: 'otp', component: OtpPageComponent},
  { path: 'user', component: UserComponent, data: { title: 'User' }},
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: '**', redirectTo: 'login', pathMatch: 'full' },
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
