import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SateliteImageService {
  private baseUrl = environment.baseUrl;
  private token = localStorage.getItem('token');
  constructor(private http: HttpClient) { }
  generateSatelliteImage(latitude: any, longitude: any) {
    const url = `${this.baseUrl}/satellite-view`;
    const requestBody = {
      latitude: latitude,
      longitude: longitude
    };
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.token}`);
    return this.http.post(url, requestBody, { headers: headers });
  }
}
