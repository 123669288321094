<form [formGroup]="contactInfoFormGroup!">
  <div class="row">
    <mat-form-field appearance="outline" [style.width.px]="300">
      <mat-label>First Name</mat-label>
      <input matInput formControlName="FirstName" required>
      <mat-error *ngIf="contactInfoFormGroup!.get('FirstName')?.hasError('required')">
        First Name is required
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" [style.width.px]="300">
      <mat-label>Surname</mat-label>
      <input matInput formControlName="Surname" required>
      <mat-error *ngIf="contactInfoFormGroup!.get('Surname')?.hasError('required')">
        Surname is required
      </mat-error>
    </mat-form-field>
  </div>

  <div class="row">
    <div class="col-5">
      <input type="text"
      ng2TelInput
      formControlName="ContactNumber"
      [ng2TelInputOptions]="{initialCountry: 'za'}"
      placeholder="Contact No."
      class="ng2-tel-input-custom" />
      <p class="include-prefix-message">include prefix</p>
    </div>
    <div  class="col-5 id-number">
      <mat-form-field appearance="outline" [style.width.px]="276">
        <mat-label>ID Number</mat-label>
        <input matInput formControlName="IDNumber" required>
        <mat-error *ngIf="contactInfoFormGroup!.get('IDNumber')?.hasError('required')">
          ID Number is required
        </mat-error>
      </mat-form-field>
    </div>

  </div>
  <div class="row">
    <mat-form-field appearance="outline" [style.width.px]="300">
      <mat-label>Email</mat-label>
      <input matInput formControlName="Email" required>
      <mat-error *ngIf="contactInfoFormGroup!.get('Email')?.hasError('required')">
        Email is required
      </mat-error>
      <mat-error *ngIf="contactInfoFormGroup!.get('Email')?.hasError('email')">
        Please enter a valid email address
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" [style.width.px]="300">
      <mat-label>Confirm Email</mat-label>
      <input matInput formControlName="confirmEmail" required>
      <mat-error *ngIf="contactInfoFormGroup!.get('confirmEmail')?.hasError('required')">
        Confirmation email is required
      </mat-error>
      <mat-error *ngIf="contactInfoFormGroup!.get('confirmEmail')!.hasError('emailMismatch')">
        Email and confirmation email must match
      </mat-error>
    </mat-form-field>
  </div>
  <div class="row">
    <div class="col-5">
      <mat-form-field appearance="outline" [style.width.px]="276">
        <mat-label>Alternate Contact Person</mat-label>
        <input matInput formControlName="AlternateContactPerson">
      </mat-form-field>
    </div>
    <div class="col-5 alternateContact">
      <input type="text"
      ng2TelInput
      formControlName="AlternateContactNo"
      placeholder="Alternate Contact No."
      [ng2TelInputOptions]="{initialCountry: 'za'}"
      class="ng2-tel-input-custom" />
      <p class="include-prefix-message">include prefix</p>
    </div>
  </div>
  <div class="row">
    <mat-form-field appearance="outline" [style.width.px]="300">
      <mat-label>Alternate Email</mat-label>
      <input matInput formControlName="AlternateEmail">
    </mat-form-field>
    <mat-form-field appearance="outline" [style.width.px]="300">
      <mat-label>Password</mat-label>
      <input matInput type="password" formControlName="Password" required>
      <mat-error *ngIf="contactInfoFormGroup!.get('Password')?.hasError('required')">
        Password is required
      </mat-error>
      <mat-error *ngIf="contactInfoFormGroup!.get('Password')?.hasError('pattern')">
        Password must be at least 8 characters long, contain 1 capital letter, and 1 special character
      </mat-error>
    </mat-form-field>
  </div>
  <div class="row">
    <mat-form-field appearance="outline" [style.width.px]="300">
      <mat-label>Verify Password</mat-label>
      <input matInput formControlName="verifyPassword" type="password">
      <mat-error *ngIf="contactInfoFormGroup!.get('verifyPassword')!.hasError('passwordMismatch')">
        Passwords do not match.
      </mat-error>
    </mat-form-field>
  </div>  
    <p id="uploadDoc"> Upload Documents </p>
    <p id="description">Please note: You have the option to bypass this step and upload your documents 
        later from your account in the "My Account" section. You will have a grace period of up to 5 days to 
        submit the necessary documents.</p>
        <div class="input-company">
          <div class="input-file">
            <div class="input">
              <div class="filename-container">
                <span class="file-label" style="width: 60px;">
                  {{ IDFrontDoc }} 
                  <span class="required-indicator">*</span>
                </span>
              </div>
              <label for="myFile3" class="choose-file-btn" [class.disabled]="!contactInfoFormGroup!.get('Email')?.valid">Choose File</label>
              <input type="file" id="myFile3" name="filename" (change)="uploadIDFront($event)" [disabled]="!contactInfoFormGroup!.get('Email')?.valid">
            </div>
          </div>
          <p id="format">Accepted formats: PNG, JPEG</p>
        </div>
        <div class="input-company">
          <div class="input-file">
            <div class="input">
              <div class="filename-container">
                <span class="file-label" style="width: 60px;">
                  {{ LetterOfAuthority }} 
                </span>
              </div>
              <label for="myFile4" class="choose-file-btn" [class.disabled]="!contactInfoFormGroup!.get('Email')?.valid">Choose File</label>
              <input type="file" id="myFile4" name="filename" (change)="uploadLetterOfAuth($event)" [disabled]="!contactInfoFormGroup!.get('Email')?.valid">
            </div>
          </div>
          <p id="format">Accepted formats: PNG, JPEG, PDF</p>
        </div>
  </form>
  <div class="continue">
    <button button type="button" class="btn btn-success mt-4 custom-button" mat-button matStepperNext (click)="updateContactInfo()">CONTINUE</button>
    <p class="newUser mt-2"> Already have an account? <span id="login-span" (click)="navigateToLogin()">Login</span></p>
  </div>
  