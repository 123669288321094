import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonModule } from '@angular/common';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { FormsModule,ReactiveFormsModule  } from '@angular/forms';
import { UserComponent } from './user/user.component';
import { AdminComponent } from './admin/admin.component';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LoaderComponent} from '../shared/loader/loader.component'
import { NeoLoaderComponent} from '../shared/neo-loader/neo-loader.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AddLocationPageComponent } from './add-location-page/add-location-page.component';
import { BillboardsPageComponent } from './billboards-page/billboards-page.component';
import { UsersPageComponent } from './users-page/users-page.component';
import { AccountPageComponent } from './account-page/account-page.component';
import { WebcamComponent } from './webcam/webcam.component';
import { LoginPageComponent } from './login-page/login-page.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { RegistrationPageComponent } from './registration-page/registration-page.component'
import { MatStepperModule} from '@angular/material/stepper';
import { AddressInfoComponent } from './address-info/address-info.component';
import { ContactInfoComponent } from './contact-info/contact-info.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { NgOtpInputModule } from  'ng-otp-input';
import { LivenessStatusComponent } from './liveness-status/liveness-status.component';
import { PersonalInfoComponent } from './individual-page/personal-info/personal-info.component';
import { LivenessAwarenessPageComponent } from './liveness-notice-page/liveness-awareness-page/liveness-awareness-page.component';
import { CompanyDetailsPageComponent } from './company-details-page/company-details-page.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { ClickOutsideModule } from 'ng-click-outside';
import { MatSelectModule } from '@angular/material/select';
import { GeneralUserComponent } from './general-user/general-user.component';
import { BillboardListingComponent } from './billboards-page/billboard-listing/billboard-listing.component';
import { Ng2TelInputModule} from 'ng2-tel-input';
import { OtpPageComponent } from './users-page/otp-page/otp-page.component';
import { SupportChannelComponent } from './support-channel/support-channel.component';
import { DatePipe } from '@angular/common';
import { AdminChatChannelComponent } from './admin-chat-channel/admin-chat-channel.component';
import { MatDialogModule} from '@angular/material/dialog';
import { MultipleEntriesComponent } from './modals/multiple-entries/multiple-entries.component';
import { SuccessUpdateComponent } from './modals/success-update/success-update.component';
import { MatAutocompleteModule} from '@angular/material/autocomplete';
import { FooterMenuComponent } from './mobile-components/footer-menu/footer-menu.component';
import { NgxExtendedPdfViewerModule} from "ngx-extended-pdf-viewer";
import { PdfViewerModule } from "ng2-pdf-viewer"
@NgModule({
  declarations: [
    AppComponent,
    UserComponent,
    AdminComponent,
    ForgotPasswordComponent,
    LoaderComponent,
    NeoLoaderComponent,
    DashboardComponent,
    AddLocationPageComponent,
    BillboardsPageComponent,
    UsersPageComponent,
    AccountPageComponent,
    WebcamComponent,
    LoginPageComponent,
    ResetPasswordComponent,
    RegistrationPageComponent,
    AddressInfoComponent,
    ContactInfoComponent,
    LivenessStatusComponent,
    PersonalInfoComponent,
    LivenessAwarenessPageComponent,
    CompanyDetailsPageComponent,
    GeneralUserComponent,
    BillboardListingComponent,
    OtpPageComponent,
    SupportChannelComponent,
    AdminChatChannelComponent,
    MultipleEntriesComponent,
    SuccessUpdateComponent,
    FooterMenuComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    MatCardModule, MatMenuModule, MatButtonModule,
    MatFormFieldModule,
    CommonModule,
    MatButtonToggleModule,
    BrowserAnimationsModule,
    FormsModule,
    MatProgressBarModule,
    ReactiveFormsModule,
    MatIconModule,     
    MatInputModule,   
    MatFormFieldModule,
    MatProgressSpinnerModule,
    MatStepperModule,
    NgOtpInputModule,
    Ng2SearchPipeModule,
    ClickOutsideModule,
    MatSelectModule,
    Ng2TelInputModule,
    MatDialogModule,
    MatAutocompleteModule,
    PdfViewerModule,
    NgxExtendedPdfViewerModule
  ],
  providers: [DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
