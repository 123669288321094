import { Component, OnInit , Renderer2, ElementRef} from '@angular/core';
import * as L from 'leaflet';
import { DatabasesService } from 'src/services/databases.service';
import { SateliteImageService } from 'src/services/satelite-image.service';
import { StreetViewService } from 'src/services/street-view.service';
import { TrafficDataService } from 'src/services/traffic-data.service';
import { environment } from 'src/environments/environment';

declare const navigator: Navigator;
@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})

export class UserComponent implements OnInit {
  map!: L.Map;
  draggableMarker!: L.Marker;
  markerLabel!: HTMLElement;
  markerInfo!: string;
  markers = new Array();
  newLabel: any;
  newId: any;
  newInfo: any;
  baseUrl = environment.baseUrl;
  selectedFile: File | undefined;
  centerLatitude: any;
  centerLongitude: any;
  currentMapStyle: string = 'osm-bright';
  isDragging: boolean = false;
  startX: number = 0;
  startY: number = 0;
  imagePositionX: number = 0;
  imagePositionY: number = 0;
  imageUrl: any;
  file: any;
  congestedData: any;
  minZoomToShowMarker: number = 16;
  initialX: number = 0;
  initialY: number = 0;
  startOffsetX: number = 0;
  startOffsetY: number = 0;
  showGeneralUserInfo: boolean = false;
  constructor(private db: DatabasesService, private satellite: SateliteImageService, private streets: StreetViewService,
    private traffic: TrafficDataService,private renderer: Renderer2) {
    this.onLabelMouseMove = this.onLabelMouseMove.bind(this);
    this.onLabelMouseUp = this.onLabelMouseUp.bind(this);
    this.onLabelMouseDown = this.onLabelMouseDown.bind(this);
  }
  ngOnInit(): void {
    this.db.getBillboard().subscribe((POIObjectData:any)=>{
      this.markers = POIObjectData.pointOfinterest;
      this.initMap();
    })
  }
  ngOnDestroy(): void {
    this.map.remove();
  }
  initMap(): void {
    this.map = L.map('map');
        this.map.setView([-26.17332, 28.08399], 12.56);
        L.tileLayer(`https://ad-drop-maps-api-dev.shayelasolutions.co.za/styles/${this.currentMapStyle}/{z}/{x}/{y}.png`, {
          attribution: 'Map data &copy; <a href="https://www.kalevamedia.com/">Kaleva Media Maps</a> contributors',
          maxZoom: 19,
        }).addTo(this.map);
        const markerGroup = L.layerGroup().addTo(this.map);
        this.map.on('mousedown', (e) => {
          let clickHoldTimer: number;
          let satelliteButton: HTMLButtonElement | null = null;
          clickHoldTimer = window.setTimeout(() => {
            const lat = e.latlng.lat;
            const lng = e.latlng.lng;
            const popupContent = `
              <div>
                <p>Satellite image loading...</p>
              </div>
            `;
            L.popup()
              .setLatLng([lat, lng])
              .setContent(popupContent)
              .openOn(this.map);
            this.satellite.generateSatelliteImage(lat, lng).subscribe((data: any) => {
              this.imageUrl = data.imageUrl;
              const imageBounds = L.latLngBounds(
                L.latLng(lat - 0.001, lng - 0.001),
                L.latLng(lat + 0.001, lng + 0.001)
              );
              L.imageOverlay(data.imageUrl, imageBounds).addTo(this.map);
              this.map.fitBounds(imageBounds);
            });
          }, 5000);
          this.map.on('mouseup', () => {
            clearTimeout(clickHoldTimer);
            if (satelliteButton) {
              this.map.closePopup();
            }
          });
        });
        this.markers.forEach(async (marker) => {
        const { latitude, longitude , pointsOfInterest, _id} = marker;
        const icon = L.icon({
          iconUrl: 'assets/icons/marker-icon-2x.png',
          iconRetinaUrl: 'assets/icons/marker-icon-2x.png',
          shadowUrl: 'assets/icons/marker-shadow.png',
          iconSize: [41, 41],
          iconAnchor: [12, 41],
          popupAnchor: [1, -34],
        });
        const draggableMarker = L.marker([latitude, longitude], {
          draggable: false,
          icon: icon,
        }).addTo(this.map);
        this.displayPointsofInterest();
        this.map.on('zoomend', () => {
          this.updateMarkers(pointsOfInterest, markerGroup, this.map);
        });
        const congestedData = await this.traffic.getCongestedData(latitude, longitude).toPromise();
        const popupContent =await this.createPopupContent(marker, congestedData);
        const customOptions = {
          'className': 'customPopup'
        }
        draggableMarker.bindPopup(popupContent,customOptions);
        
        draggableMarker.on('popupopen', () => {
          this.makePopupDraggable(_id);
          const streetViewButton = document.querySelector('.view-marker');
          const satelliteViewButton = document.querySelector('.satellite-marker');
          const clearSatellite = document.querySelector('.satellite-clear');
          if (streetViewButton) {
            streetViewButton.addEventListener('click', () => {
              draggableMarker.closePopup();
              this.streets.getStreetViewLink(draggableMarker.getLatLng().lat,draggableMarker.getLatLng().lng).subscribe((data: any)=>{
                window.open(data.googleStreetLink, '_blank');
              })
            });
          }
          if (satelliteViewButton) {
            satelliteViewButton.addEventListener('click', () => {
              draggableMarker.closePopup();
              const lat = draggableMarker.getLatLng().lat;
              const lng = draggableMarker.getLatLng().lng;
              this.satellite.generateSatelliteImage(lat, lng).subscribe((data: any) => {
                this.imageUrl = data.imageUrl;
                const imageBounds = L.latLngBounds(
                  L.latLng(lat - 0.001, lng - 0.001),
                  L.latLng(lat + 0.001, lng + 0.001)
                );
                L.imageOverlay(data.imageUrl, imageBounds).addTo(this.map);
                this.map.fitBounds(imageBounds);
              });
            });
          }
          if (clearSatellite) {
            clearSatellite.addEventListener('click', () => {
                draggableMarker.closePopup();
                this.switchToDefaultStyle();
            })
          }
        });
        this.map.on('dragend', () => {
          draggableMarker.closePopup();
        });
        draggableMarker.on('popupclose', () => {
          this.initialX= 0;
          this.initialY = 0;
          this.startOffsetX = 0;
          this.startOffsetY = 0;
        })
        const updateCoordinates = () => {
          const centerLatLng = this.map.getCenter();
          this.centerLatitude = centerLatLng.lat;
          this.centerLongitude = centerLatLng.lng;
        };
        this.map.on('move', updateCoordinates);
        });
  }
  onLabelMouseDown(event: MouseEvent): void {
    event.preventDefault();
    window.addEventListener('mousemove', this.onLabelMouseMove);
    window.addEventListener('mouseup', this.onLabelMouseUp);
  }

  onLabelMouseMove(event: MouseEvent): void {
    const labelPosition = this.getRelativePosition(event);
    this.markerLabel.style.left = labelPosition.x + 'px';
    this.markerLabel.style.top = labelPosition.y + 'px';
  }

  onLabelMouseUp(event: MouseEvent): void {
    window.removeEventListener('mousemove', this.onLabelMouseMove);
    window.removeEventListener('mouseup', this.onLabelMouseUp);
    const mapPosition = this.getRelativePosition(event);
    const latLng = this.map.layerPointToLatLng(L.point(mapPosition.x, mapPosition.y));
    this.draggableMarker.setLatLng(latLng);
  }

  getRelativePosition(event: MouseEvent): { x: number, y: number }{
    const mapDiv = document.getElementById('map');
    if (!mapDiv) return { x: 0, y: 0 };
    const rect = mapDiv.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    return { x, y };
  }

  displayPointsofInterest() {
    const markerGroup = L.layerGroup().addTo(this.map);
    this.db.getCustomPointsOfinterest().subscribe((poi: any) => {
      poi.pointOfinterest.forEach((point: any) => {
        const icon = L.icon({
          iconUrl: `${this.baseUrl}/files/${point.icon}`,
          iconRetinaUrl: `${this.baseUrl}/files/${point.icon}`,
          iconSize: [20, 20],
          iconAnchor: [12, 41],
          popupAnchor: [1, -34],
        });
        const marker = L.marker([point.latitude, point.longitude], {
          draggable: false,
          icon: icon,
        }).addTo(this.map);
  
        const { name, latitude, longitude, address } = point;
        let addressParts = [];
        if(!address){
          addressParts = ['No address for this Location']
        }
        else{
          addressParts = address!.split(', ') || [];
        }
        let popupContent = `
        <div style="width: 450px;"> 
            <img src="${this.baseUrl}/files/${point.file}" alt="Photo" style="width: 450px; height: 89px; object-fit: cover; border-radius: 15px 15px 0 0;">
            <h2 class="marker-label"><b>${name}</b></h2>
            <div class="coordinates-container">
              <div class="coordinate">
                <span class="coordinates">LAT: </span><span class="lat">${latitude}</span>
              </div>
              <div class="coordinate">
                <span class="coordinates">LON: </span><span class="lat">${longitude}</span>
              </div>
            </div>
            <div style="padding:10px;">
              ${addressParts && addressParts.length > 0 ? `
                <span >${addressParts[0] || ''}</span><span class="value">,${addressParts[2] || ''}</span>,
                <span >${addressParts[1] || ''}</span>,
                <span >${addressParts[3] || ''}</span>
              </div>
            ` : ''}
          </div>`;
  
        const customPopup = L.popup({ closeButton: false }).setContent(popupContent);

        marker.bindPopup(customPopup);
  
        markerGroup.addLayer(marker);
  
       
      });
    });
  }
  makePopupDraggable(popupId: string) {
    const popup = document.getElementById(`popup-${popupId}`);
    if (popup) {
      let isDragging = false;

  
      popup.addEventListener("mousedown", (e) => {
        isDragging = true;
        const boundingBox = popup.getBoundingClientRect();
        if(this.initialX == 0 || this.initialY ==0 ){
          this.initialX = boundingBox.left;
          this.initialY = boundingBox.top;
          this.startOffsetX = e.clientX - this.initialX;
          this.startOffsetY = e.clientY - this.initialY;
        }

        popup.style.cursor = "grabbing";
        popup.style.transition = "none"; // Disable transition for smoother dragging
      });
  
      document.addEventListener("mousemove", (e) => {
        if (isDragging) {
          const x = e.clientX - this.startOffsetX;
          const y = e.clientY - this.startOffsetY;
          popup.style.transform = `translate(${x - this.initialX}px, ${y - this.initialY}px)`;
        }
      });
  
      document.addEventListener("mouseup", () => {
        if (isDragging) {
          isDragging = false;
          popup.style.cursor = "grab";
          popup.style.transition = "transform 0.3s ease"; // Enable transition again for smooth animations
        }
      });
    }
  }
  hideGeneralUserInfo(){
    this.showGeneralUserInfo =  false;
  }
  handleShowGeneralUserInfoChange(value: boolean) {
    this.showGeneralUserInfo = value;
  }
  
  switchToDefaultStyle() {
    this.map.remove();
    this.currentMapStyle = 'osm-bright';
    this.db.getBillboard().subscribe((POIObjectData:any)=>{
      this.markers = POIObjectData.pointOfinterest;
      this.initMap();
    })
  }
  async createPopupContent(draggableMarker: any, congestedData: any): Promise<string> {
    const { file, label, info, address, latitude, longitude, _id, income, avgSpeed } = draggableMarker;
    const streetViewButtonHtml = `<span id="user-street-view" class="view-marker">
                                    <img class="icon" src="/assets/popup-ui/street-view.svg" alt="" >
                                  <br><span >StreetView</span>
                                  </span>`;
    const satelliteViewButtonHtml = `<span id="user-other-buttons" class="satellite-marker">
                                      <img class="icon" src="/assets/popup-ui/satellite-view.svg" alt="">
                                  <br><span>Satellite View</span>
                                  </span>`;
    const ClearSatelliteImages = `<span id="user-other-buttons" class="satellite-clear">
                                    <img class="icon" src="/assets/popup-ui/clear-satellite-view.svg" alt="" >
                                  <br><span >Clear Satellite View</span>
                                  </span>`;
    const addressParts = address ? address.split(', ') : [];
    let totalSpeed = 0;
    let averageSpeed: string = 'N/A';
    const fetchSpeedLimit = async () => {
      try {
        const trafficSpeed: any = await this.traffic.getSpeedLimit(latitude, longitude).toPromise();
        averageSpeed = trafficSpeed.speedLimit;
      } catch (error) {
        console.error("Error fetching traffic speed:", error);
      }
    };
    if (avgSpeed == undefined) {
      await fetchSpeedLimit();
    } else {
      const speedArray = avgSpeed;
      if (speedArray && speedArray.length > 0) {
        let validSpeedCount = 0;
  
        for (const speedObj of speedArray) {
          if (speedObj.speed !== undefined && speedObj.speed !== 0) {
            const trimmedSpeed = parseFloat(speedObj.speed);
            if (!isNaN(trimmedSpeed)) {
              totalSpeed += trimmedSpeed;
              validSpeedCount++;
            }
          }
        }
        if (validSpeedCount > 0) {
          averageSpeed = (totalSpeed / validSpeedCount).toFixed(2);
        }
      }
    }
    return `
    <div class="billboard-container" id="popup-${_id}">
        <div class="header-container">
            <i id="icon-home" class="fa fa-home" aria-hidden="true"></i>
            <div id="vl" class="vl" ></div>
            <h3 style="margin-left: 10px;">${label} (${info})</h3>
        </div>
        <div class="content-container">
            <div style="display: flex;">
                <img id="img-holder" src="${this.baseUrl}/files/${file}" width="160" height="140px" alt="">
                <div class="details-container">
                    <p id="lat-paragraph">
                        <span style="margin-right: auto;">LAT</span>
                        <span id="vh">${latitude}</span>
                    </p>
                    <p id="lon-paragraph">
                        <span style="margin-right: auto;">LON</span>
                        <span id="vh">${longitude}</span>
                    </p>
                    <p id="content-location">
                    <span style="margin-right: auto;">Address:</span>
                    <span id="address-holder" class="address">
                        <span id="address" >${addressParts[0] || ''}, ${addressParts[2] || ''}</span>
                        <span id="address" >${addressParts[1] || ''}</span>
                        <span id="address" >${addressParts[3] || ''}</span>
                    </span>
                </p>
                    <p id="traffic-p" class="traffic">
                        <span style="margin-right: auto;">Traffic:</span>
                        <span id='traffic-v-p'class="traffic-value" >${congestedData.congestedStatus}</span>
                    </p>
                    <p id="traffic-p" class="speed">
                        <span style="margin-right: auto;">Average Speed:</span>
                        <span id='traffic-v-p' class="speed-value">${averageSpeed !== 'N/A' ? `<span class="value">${averageSpeed} km/h</span>` : '<span class="value">N/A</span>'}</span>
                    </p>
                    <p id="traffic-p" class="economic">
                        <span style="margin-right: auto;">Economic Profile:</span>
                        <span id='traffic-v-p' class="economic-value">${income}</span>
                    </p>
                </div>
            </div>
        </div>
        <div id="hl" class="hl"></div>
        <div class="icon-container">
            <div style="display: flex;">
                ${streetViewButtonHtml}
                ${satelliteViewButtonHtml}
                ${ClearSatelliteImages}
            </div>
        </div>
    </div>
    `
  //   return `
  //   <div style="width: 400px; cursor: grab; position: absolute; background-color: white;" id="popup-${_id}"> 
  //     <img src="${this.baseUrl}/files/${file}" alt="Photo" style="width: 300px; height: 139px; object-fit: cover; border-radius: 15px 15px 0 0;">
  //     <h2 class="marker-label"><b>${label}</b></h2>
  //     <h3 class="marker-info"> ${info} </h3>
  //     <div class="coordinates-container">
  //       <div class="coordinate">
  //         <span class="coordinates">LAT:  </span><span class="lat">${latitude}</span>
  //       </div>
  //       <div class="coordinate">
  //         <span class="coordinates">LON:  </span><span class="lat">${longitude}</span>
  //       </div>
  //     </div>
  //     <div class="info-box">
  //     ${addressParts && addressParts.length > 0 ? `
  //     <div class="address">
  //       <div class="label">Address:</div>
  //       <div class="values">
  //         <span class="value">${addressParts[0] || ''}</span><span class="value">${addressParts[2] || ''}</span>
  //         <span class="value">${addressParts[1] || ''}</span>
  //         <span class="value">${addressParts[3] || ''}</span>
  //       </div>
  //     </div>
  //   ` : ''}
  //       <div class="economy">
  //         <span class="label">Economic Profile:</span>
  //         <div class="values">
  //           <span class="value">${income}</span>
  //         </div>
  //       </div>
  //       <div class="traffic">
  //         <span class="label">Traffic:</span>
  //         <div class="values">
  //           <span class="value">${congestedData.congestedStatus}</span>
  //         </div>
  //       </div>
  //       <div class="speed">
  //         <span class="label">Speed:</span>
  //         <div class="values">
  //           ${averageSpeed !== 'N/A' ? `<span class="value">${averageSpeed} km/h</span>` : '<span class="value">N/A</span>'}
  //         </div>
  //       </div>
  //     </div>
  //     <div class='row mt-1 d-flex justify-content-center'>
  //       ${streetViewButtonHtml}
  //     </div>
  //     <div class='row mt-1 mb-2 d-flex justify-content-center'>
  //       ${satelliteViewButtonHtml}  
  //     </div>
  //     <div class='row mt-1 mb-2 d-flex justify-content-center'>
  //       ${ClearSatelliteImages}  
  //     </div>
  //   </div>
  // `;
  }
  private updateMarkers(pointsOfInterest: any[], markerGroup: L.LayerGroup, map: L.Map): void {
    const currentZoom = map.getZoom();
    if (currentZoom >= this.minZoomToShowMarker) {
      if (pointsOfInterest && Array.isArray(pointsOfInterest)) {
        pointsOfInterest.forEach((point: any) => {
          const icon = L.icon({
            iconUrl: point.icon,
            iconRetinaUrl: point.icon,
            shadowUrl: point.icon_background_color,
            iconSize: [15, 15],
            iconAnchor: [12, 41],
            popupAnchor: [1, -34],
          });
          const marker = L.marker([point.geometry.location.lat, point.geometry.location.lng], {
            draggable: false,
            icon: icon,
          }).addTo(map);
    
          const { name, rating, photos, vicinity } = point;
          let popupContent = `
            <div class='p-2'>
              <h3>${name}</h3>
              <p>Rating: ${rating}</p>
              <p>${vicinity}</p>
            </div>`;
          marker.bindPopup(popupContent);
          markerGroup.addLayer(marker);
        });
      }

    } else {
      markerGroup.clearLayers();
    }
  }
}
