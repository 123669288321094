import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UploadfilesService } from 'src/services/uploadfiles.service';
import { environment } from 'src/environments/environment';
import { AlertService } from 'src/shared/alert.service';
import { EnquiryChatService } from 'src/services/enquiry-chat.service';
import { AuthService } from 'src/services/auth.service';
import { DatePipe } from '@angular/common';
import { UserService } from 'src/services/user.service';
import { WebsocketsService } from 'src/services/websockets.service';
@Component({
  selector: 'app-support-channel',
  templateUrl: './support-channel.component.html',
  styleUrls: ['./support-channel.component.scss']
})
export class SupportChannelComponent implements OnInit {
  filename: any = 'Click here to upload a file';
  selectedFile: File | undefined;
  subject:any;
  content:any;
  email:any;
  uploaded_file: any;
  createTicketClicked: boolean = false;
  replyMessage: any;
  selectedChat: any;
  searchTerm: any;
  enquiries: any = new Array();
  typingTimeout: any;
  @ViewChild('messageContainer', { static: false })
  messageContainer!: ElementRef;
  constructor(
    private uploadService: UploadfilesService,
    private alert: AlertService, 
    private enquiry: EnquiryChatService,
    private auth:AuthService,
    private datePipe: DatePipe,
    private user: UserService,
    private cdr: ChangeDetectorRef,
    private webSocketService: WebsocketsService) { }

  ngOnInit(): void {
    this.email = this.auth.getLoggedInUserId().contactInfo.Email;
    this.getAllenquiriesChats();
    this.webSocketService.onMessage().subscribe({
      next: (message) => 
      {
        if(message.type && message.type === 'typing_status' && message.isTyping){
          this.enquiries.forEach((element:any) => {
            if(element._id === message.chatId){
              element.typing = true;
              this.scrollToBottom();
            }
          });
        }
        else if(message.type && message.type === 'typing_status' && !message.isTyping){
          this.enquiries.forEach((element:any) => {
            if(element._id === message.chatId){
              element.typing = false;
              this.scrollToBottom();
            }
          });
        }
        else{
          this.enquiries.forEach((element:any) => {
            if(element._id === message._id){
              element.replies.push(message);
              this.scrollToBottom();
            }
        });
        }

      },
      error: (error) => {console.error('WebSocket error:', error)}
      })
  }
  ngOnDestroy() {
    // Clear the typing timeout when the component is destroyed
    clearTimeout(this.typingTimeout);
  }
  onTyping() {
    
    clearTimeout(this.typingTimeout);
    this.typingTimeout = setTimeout(() => {
      this.sendTypingStatus(false);
    }, 3000);
    this.sendTypingStatus(true);
  }
  private sendTypingStatus(isTyping: boolean) {
    const receiverEmail = 'admin'; 
    const chatId = this.selectedChat._id;

    const typingStatusMessage = {
      type: 'typing_status',
      isTyping: isTyping,
      receiverEmail: receiverEmail,
      chatId: chatId
    };
    this.webSocketService.sendMessage(typingStatusMessage);
  }
  createEnquiryRealTime(new_enquiry:any){
    const created_enquiry = {
      type: 'enquiry',
      enquiry: new_enquiry,
      receiverEmail: 'admin',
    };
    this.webSocketService.sendMessage(created_enquiry);
  }
  getAllenquiriesChats(){
    this.enquiries = [];
    this.enquiry.getALLEnquiries().subscribe((response:any)=>{
      response.forEach((element: any, index: any) => {
          if(element.email  === this.email){
            this.enquiries.push(element);
          }
      });
      this.enquiries.sort((a:any, b:any) => {
        const dateA = new Date(a.date).getTime();
        const dateB = new Date(b.date).getTime();
        if (a.resolved && !b.resolved) {
          return 1;
        } else if (!a.resolved && b.resolved) {
            return -1; 
        } else {
            return dateB - dateA;
        }
      });
      if(this.enquiries.length >= 1){
        this.setUserEnquiryProfile();
        this.selectedChat = this.enquiries[0];
        this.scrollToBottom();
      }
    })
  }
  scrollToBottom(): void {
    try {
      requestAnimationFrame(() => {
        this.messageContainer.nativeElement.scrollTop = this.messageContainer.nativeElement.scrollHeight;
      })
      this.cdr.detectChanges();
    } catch(err) {
    }
  }
  onFileSelected(event: any): void {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
        this.filename = selectedFile ? selectedFile.name : 'No file uploaded';
        this.uploadService.uploadFile(selectedFile ).subscribe(
          response => {
            this.uploaded_file = environment.baseUrl+'/files/' + response.fileId;
          },
          error => {
            console.error(error);
          }
        );
    }
  }
  formatDateForSubject(dateTime: string): string {
    const currentDate = new Date();
    const messageDate = new Date(dateTime);
    const isSameDay = currentDate.toDateString() === messageDate.toDateString();
  
    if (!isSameDay) {
      return this.datePipe.transform(dateTime, 'dd MMM YYYY') || 'Just now';
    } else {
      return this.datePipe.transform(dateTime, 'HH:mm') || 'Just now';
    }
  }
  createTicket(){
    this.createTicketClicked = true;
  }
  formatDateTime(dateTime: string): string {
    return this.datePipe.transform(dateTime, 'EEE, dd MMM \'at\' HH:mm') || '';
  }
  sendEnquiry(){
    const chat = {
      subject: this.subject,
      attachment: this.uploaded_file || '',
      email: this.email,
      message: this.content,
      unread: true,
      date: new Date(),
      replies: []
    };
    this.createEnquiryRealTime(chat);
    this.enquiry.sendEnquiry(this.email,this.content,this.uploaded_file,this.subject).subscribe((response:any)=>{
      if(response.success){
        this.alert.notificationToast('success',`Enquiry sent ticket #${response.chat}!`);
        this.createTicketClicked = false;
        this.getAllenquiriesChats();
      }
    })
  }
  sendReply() {
      const reply = {
        message: this.replyMessage,
        from: 'me',
        date: new Date(),
      };
      this.selectedChat.replies.push(reply);
      const message = {
        senderEmail: this.email,
        receiverEmail: 'admin',
        message: this.replyMessage,
        _id: this.selectedChat._id,
        from: 'me',
        date: new Date(),
      };
      this.webSocketService.sendMessage(message);
      this.scrollToBottom();
      this.replyMessage = '';
      this.enquiry.sendResponse(this.selectedChat._id,this.selectedChat.email,reply).subscribe(data=>{
        
      })
  }
  setSelectedChat(chat:any){
    this.selectedChat = chat;
    this.selectedChat.unread = false;
    this.scrollToBottom();
    this.enquiry.markChatAsRead(chat._id).subscribe(data=>{});
  }

  resolveTicket(){
    this.enquiry.resolveTicket(this.selectedChat._id).subscribe(data=>{
      this.alert.notificationToast('success',`The ticket #${this.selectedChat._id} has been resolved`);
      this.selectedChat.resolved = true;
    })
  }
  setUserEnquiryProfile(){
    this.enquiries.forEach((element:any) => {
      this.user.getUserByEmail(element.email).subscribe((user:any)=>{
        element.username =  user.user.contactInfo.FirstName +' '+ user.user.contactInfo.Surname;
        element.company = user.user.companyInfo.companyName || '';
      })
    });
  }
  
}
