<div class="my-account-container" >
  <h1 class="mobile-header" *ngIf="!displayOTPScreen">My Account</h1>
  <h1 class="mobile-header" *ngIf="displayOTPScreen">Confirm Your OTP</h1>
<div class="container"  *ngIf="!displayOTPScreen">
    <h2 class="inline-header"  *ngIf="userType != 'individual' " [ngClass]="{'active': activeHeader === 'company-details'}" (click)="toggleHeader('company-details')" [class.active-header]="activeHeader === 'company-details'">
        Company Details
    </h2>
    <h2 class="inline-header" [ngClass]="{'active': activeHeader === 'personal-details'}" (click)="toggleHeader('personal-details')" [class.active-header]="activeHeader === 'personal-details'">
        Personal details
    </h2>
    <h2 class="inline-header" [ngClass]="{'active': activeHeader === 'security-details'}" (click)="toggleHeader('security-details')" [class.active-header]="activeHeader === 'security-details'"> 
        Security Settings
    </h2>
    <h2 class="inline-header" *ngIf="userType != 'individual' " [ngClass]="{'active': activeHeader === 'documents'}" (click)="toggleHeader('documents')" [class.active-header]="activeHeader === 'documents'"> 
        Documents
    </h2>
    <div class="row mt-4 account-screen" id="personal-details" *ngIf="activeHeader === 'personal-details' || !activeHeader">
        <div class="col-lg-3 col-12 profile-container">
            <div class="profile-holder">
              <img src={{profileUrl}} class="profileHolder" alt="" width="100%" height="100%">   
            </div>
            <div class="mt-2">
              <label for="file-input-profile-pic" class="profile-file-label">
                  <mat-icon class="icon">create</mat-icon> Change Profile Image
              </label>
              <input type="file" id="file-input-profile-pic" name="profile-pic" class="file-input" (change)="uploadProfilePicture($event)" accept="image/*" />
            </div>
            <div class="border-line"></div>
            <div class="profile-text mt-2">
                <h3>{{firstname}} {{lastname}}</h3>
                <p>{{PREV_email}}</p>
                <p>{{PREV_telephone}}</p>
            </div>
        </div>
        <div class="col-lg-8 col-12 personal-details-container">
            
            <div class="alternate-details-header"> 
              <h2>Personal details</h2>
              <div class="edit-container" (click)="onEditClick()">
                {{ isEditClicked ? 'Cancel' : 'Edit' }} <mat-icon (click)="onEditClick()">create icon</mat-icon>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-lg-6 col-12">
                <mat-form-field appearance="outline">
                  <mat-label *ngIf="isInputClicked.firstname">First Name</mat-label>
                  <input matInput [(ngModel)]="firstname" name="firstname" required (focus)="onInputFocus('firstname')" disabled>
                </mat-form-field>
              </div>
              <div class="col-lg-6 col-12">
                <mat-form-field appearance="outline">
                  <mat-label *ngIf="isInputClicked.lastname">Surname</mat-label>
                  <input matInput [(ngModel)]="lastname" name="lastname" required (focus)="onInputFocus('lastname')" disabled>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-12" *ngIf="userType != 'individual' ">
                <mat-form-field appearance="outline">
                  <mat-label *ngIf="isInputClicked.email">ID</mat-label>
                  <input matInput [(ngModel)]="id" name="id" required (focus)="onInputFocus('id')" placeholder="ID Number" disabled>
                </mat-form-field>
              </div>
              <div class="col-lg-6 col-12">
                <mat-form-field appearance="outline">
                  <mat-label *ngIf="isInputClicked.email">Email</mat-label>
                  <input matInput [(ngModel)]="email" name="email" (ngModelChange)="onEmailChange($event)" required (focus)="onInputFocus('email')" [disabled]="!isEditClicked">
                </mat-form-field>
              </div>
              <div class="col-lg-6 col-12">
                <mat-form-field appearance="outline">
                  <mat-label *ngIf="isInputClicked.telephone">Contact Number</mat-label>
                  <input matInput [(ngModel)]="telephone" name="telephone" required (focus)="onInputFocus('telephone')" [disabled]="!isEditClicked">
                </mat-form-field>
              </div>
            </div>
            <div class="alternate-details-header" *ngIf="userType != 'individual' "> 
              <h2>Alternate Details</h2>
              <div class="edit-container" (click)="onEditClickAlternate()">
                {{ isEditClickedAlternate ? 'Cancel' : 'Edit' }} <mat-icon (click)="onEditClickAlternate()">create icon</mat-icon>
              </div>
            </div>
            
            <div class="row mt-4" *ngIf="userType != 'individual' ">
              <div class="col-lg-6 col-12">
                <mat-form-field appearance="outline">
                  <mat-label *ngIf="isEditClickedAlternate && isInputClickedAlternate.alt_email">alternate Email</mat-label>
                  <input matInput [(ngModel)]="alt_email" name="alt_email" required (focus)="onInputFocus('alt_email')" [disabled]="!isEditClickedAlternate">
                </mat-form-field>
              </div>
              <div class="col-lg-6 col-12">
                <mat-form-field appearance="outline">
                  <mat-label *ngIf="isEditClickedAlternate && isInputClickedAlternate.alt_contactNo">alternate Contact Number</mat-label>
                  <input matInput [(ngModel)]="alt_contactNo" name="alt_contactNo" required (focus)="onInputFocus('alt_contactNo')" [disabled]="!isEditClickedAlternate">
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <p id="error" class="d-flex align-items-center">
                  <mat-icon class="error-icon mr-3">error</mat-icon>
                  For security purposes, please update either your contact number or email at a time. Allow a 24-hour waiting period before changing again. Thank you for your cooperation.
              </p>
          </div>
           
        </div>
        <p class="register-business mt-2" *ngIf="userType == 'individual' ">Want to register your business on Ad drop? <a href="/create-account">Register Now</a></p>
        <div class="button-container" *ngIf="activeButton === 'alternate'">
            <button type="button" class="btn btn-primary mt-4 custom-button" [disabled]="!isEditClickedAlternate"  (click)="updateAlternateContactInfo()">Save</button>
        </div>
        <div class="button-container" *ngIf="activeButton === 'maindetails'">
          <button type="button" class="btn btn-primary mt-4 custom-button" [disabled]="!isEditClicked"  (click)="updateContactInfo()">Save</button>
        </div>
    </div>
    <div class="row mt-4" id="security-details" *ngIf="activeHeader === 'security-details'">
      <form [formGroup]="passwordForm">
        <div class="col-12 personal-details-container">
          <h1>
              Change Password
          </h1>
          <div class="row">
              <div class="col-lg-6 col-12">
                <mat-form-field appearance="outline">
                  <mat-label>Current Password</mat-label>
                  <input matInput formControlName="currentPassword" type="password" placeholder="Current Password">
                  <mat-error *ngIf="passwordForm.get('currentPassword')!.hasError('pattern')">
                    Ensure the password is 8 characters long, contains 1 special character, 1 number, and 1 uppercase letter.
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-lg-6 col-12">
                <mat-form-field appearance="outline">
                  <mat-label>New Password</mat-label>
                  <input matInput formControlName="newPassword" type="password" placeholder="New Password">
                  <mat-error *ngIf="passwordForm.get('newPassword')!.hasError('pattern')">
                    Ensure the password is 8 characters long, contains 1 special character, 1 number, and 1 uppercase letter.
                  </mat-error>
                </mat-form-field>
              </div>
          </div>
          <div class="row">
            <div class="col-lg-6 col-12">
              <mat-form-field appearance="outline">
                <mat-label>Verify Password</mat-label>
                <input matInput formControlName="verifyPassword" type="password" placeholder="Verify Password">
                <mat-error *ngIf="passwordForm.get('verifyPassword')!.hasError('passwordMismatch')">
                  Passwords do not match.
                </mat-error>
              </mat-form-field>
            </div>
          </div>
      </div>
      <div class="button-container">
        <button type="button" class="btn btn-primary mt-4 custom-button" [disabled]="passwordForm.invalid" (click)="showOTPscreen()">Save</button>
      </div>
      </form>
    </div>
    <div class="row  mb-4" id="company-details" *ngIf="activeHeader === 'company-details' || !activeHeader" >
      <div class="app-company-details-page">
        <app-company-details-page></app-company-details-page>
      </div>
      
    </div>
    <div class="row mt-4" id="document-details" *ngIf="activeHeader === 'documents' || !activeHeader" >
        <table class="document-header-table">
          <thead>
            <tr>
              <th>Document </th>
              <th>File Size</th>
              <th>Date</th>
              <th>Status</th>
              <th>Filename</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><img src="../../assets/document-icon.png" class="edit-location mr-5" alt="">Proof of ID Document</td>
              <td id="file-size" [ngClass]="{'size-pending': IDFrontDocSize === undefined || IDFrontDocSize === null}">{{IDFrontDocSize || '[Max: 250MB]'}}</td>
              <td id="file-date">{{IDFrontDocDate || '-' }} </td>
              <td [ngStyle]="getStatusStyles(IDFrontDocStatus)">{{ IDFrontDocStatus }}</td>
              <td id="doc-name"> {{ IDFrontDoc }}</td>
              <td>
                <div class="upload-file">
                  <label for="id-front" class="file-label" >
                    upload
                  </label>
                  <input type="file" id="id-front" class="file-input" (change)="uploadIDFront($event)" required/> 
                </div>
              </td>
            </tr>
            <tr>
              <td><img src="../../assets/document-icon.png" class="edit-location mr-5" alt="">Proof  of Address</td>
              <td id="file-size"  [ngClass]="{'size-pending': Proof_of_addressSize === undefined || Proof_of_addressSize === null}">{{Proof_of_addressSize || '[Max: 250MB]'}}</td>
              <td id="file-date">{{Proof_of_addressDate || '-'}}</td>
              <td [ngStyle]="getStatusStyles(Proof_of_addressStatus)">{{ Proof_of_addressStatus }}</td>
              <td id="doc-name"> {{ Proof_of_address }}</td>
              <td>
                <div class="upload-file">
                  <label for="proof-of-address" class="file-label" >
                    upload
                  </label>
                  <input type="file" id="proof-of-address" class="file-input" (change)="uploadProofOfaddress($event)" required/> 
                </div>
              </td>
            </tr>
            <tr>
              <td><img src="../../assets/document-icon.png" class="edit-location mr-5" alt="">Company Registration Document</td>
              <td id="file-size"  [ngClass]="{'size-pending': CompanyRegLetterSize === undefined || CompanyRegLetterSize === null}">{{CompanyRegLetterSize || '[Max: 250MB]'}}</td>
              <td id="file-date">{{CompanyRegLetterDate || '-'}}</td>
              <td [ngStyle]="getStatusStyles(CompanyRegLetterStatus)">{{ CompanyRegLetterStatus }}</td>
              <td id="doc-name"> {{ CompanyRegLetter }}</td>
              <td>
                <div class="upload-file">
                  <label for="id-back" class="file-label" >
                    upload
                  </label>
                  <input type="file" id="id-back" class="file-input" (change)="uploadCompanyReg($event)" required/> 
                </div>
              </td>
            </tr>
            <tr *ngIf="userType != 'individual' ">
              <td><img src="../../assets/document-icon.png" class="edit-location mr-5" alt="">Letter of Authority</td>
              <td id="file-size" [ngClass]="{'size-pending': company_letterSize === undefined || company_letterSize === null}">{{company_letterSize || '[Max: 250MB]'}}</td>
              <td id="file-date">{{company_letterDate || '-'}}</td>
              <td [ngStyle]="getStatusStyles(company_letterStatus)">{{ company_letterStatus }}</td>
              <td id="doc-name"> {{ company_letter }}</td>
              <td>
                <div class="upload-file">
                  <label for="company-letter" class="file-label" >
                    upload
                  </label>
                  <input type="file" id="company-letter" class="file-input" (change)="uploadCompanyLetter($event)" required/> 
                </div>
              </td>
            </tr>
          </tbody>
        </table>
    </div>
</div>
</div>  
<div *ngIf="showModalBox" class="modal fade" id="multiple-entries">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header" id="modal-header-error">
        <h1 class="modal-title fs-5" id="exampleModalLabel">Error</h1>
        <div data-bs-theme="dark">
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
      </div>
      <div class="modal-body">
        <h4 class="multiple-entries">Multiple Entries Detected</h4>
        <p id="modal-content">
          We've noticed that you've entered both a phone number and an email for the change. 
          Our system allows for updating only one of these at a time for security reasons.
          Please choose to update either your phone number or email and try again.
        </p>
      </div>
    </div>
  </div>
</div>
<div class="otp-container" *ngIf="displayOTPScreen">
  <div class="otp-content">
      <img class="responsive-logo" src="../../../assets/logo.svg" alt="Logo">
      <div class="otp-form">
       <p *ngIf="type === 'contactNumber' " class="description">To update your mobile number, kindly verify the new number. Please proceed by selecting "Get OTP" below.
       </p>
       <p *ngIf="type === 'email' " class="description">To login into the account you must do 2FA, kindly verify your email address. Please proceed by selecting "Get OTP" below.
      </p>
       <form [formGroup]="verificationForm">
          <div  class="verify-otp" *ngIf="otpFormSubmitted">
              <div class="verification">
                <h2 class="header2-otp">Account Verification</h2>
                <p id="otpsent">OTP Sent!</p>
                <p id="enterotp">Please enter the 5 digit OTP sent to your email address!</p>
              </div>
              <div class="otp-container-details">
                <ng-otp-input  (onInputChange)="onOtpChange($event)"  [config]="{length:5}"></ng-otp-input>
              </div>
              <p id="remainingTime">Remaining Time: {{ displayTime }}</p>
              <p id="resentOTP">Didn’t receive an OTP?<span id="sendAgain" (click)="requestOtp()"> Send again</span></p>
              <div class="continue">
                <button button type="button" class="btn btn-success mt-4 custom-button" mat-button (click)="verifyEmail()">SUBMIT</button>
              </div>
            </div>
            <div class="getOTP" *ngIf="!otpFormSubmitted">
              <div class="verification">
                <h2 class="header2-otp">Account Verification</h2>
                <p id="otpsent">With OTP</p>
              </div>
              <div *ngIf="type === 'contactNumber' " class="otp-inputs mt-4">
                <mat-form-field appearance="outline" [style.width.px]="300">
                  <mat-label>Enter Mobile Number</mat-label>
                  <input matInput formControlName="otpNumber" >
                </mat-form-field>
              </div>
              <div *ngIf="type === 'email' " class="otp-inputs mt-4">
                <mat-form-field appearance="outline" [style.width.px]="300">
                  <mat-label>Enter Email Address</mat-label>
                  <input matInput formControlName="otpEmail" >
                </mat-form-field>
              </div>
              <div class="continue">
                  <button button type="button" class="btn btn-success mt-4 custom-button" mat-button (click)="requestOtp()">GET OTP</button>
              </div>
            </div>  
       </form>
      </div>
  </div>
</div>

