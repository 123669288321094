import { Component, OnInit , Renderer2 , NgZone } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/services/auth.service';
import { AlertService } from 'src/shared/alert.service';
import { UserService } from 'src/services/user.service';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  activePage: string = 'addLocation';
  loggedinUser: any;
  firstname: any;
  lastname: any;
  email: any;
  userType: any;
  loggedInUser: any;
  profileUrl: any = "../../assets/placeholder.jpg";
  baseUrl: any = environment.baseUrl;
  unreadCount: number = 0;
  myNotifications: any = [];
  
  constructor(private auth:AuthService,private _alert: AlertService,private users: UserService) { }

  ngOnInit(): void {
    this.getUserByEmail(this.auth.getLoggedInUserId().contactInfo.Email);
  }
  getUserByEmail(email:any){
    this.users.getLoggedinUser(email).subscribe((data:any)=>{
      this.loggedInUser = data.user;
      this.firstname= this.loggedInUser.contactInfo.FirstName;
      this.lastname = this.loggedInUser.contactInfo.Surname;
      this.email= this.loggedInUser.contactInfo.Email;
      this.userType = this.loggedInUser.userType;
      this.users.getUploadedDocuments(this.email).subscribe((docs:any)=>{
        docs.forEach((doc: any) => {
          if(doc.metadata.type == "profile_picture"){
            this.profileUrl = this.baseUrl+`/files/document/${this.email}/${doc.name}`;
          }
        });
      })
      this.getUserNotifications()
    })
  }
  getUserNotifications(){
    this.unreadCount= 0;
    this.users.getUserNotifications(this.email).subscribe((myNotifications:any)=>{
      this.myNotifications = myNotifications.notifications;
      myNotifications.notifications.forEach((element: any) => {
        if(element.unread){
          this.unreadCount++;
        }
      });
    })
  }
  setActivePage(page: string): void {
    this.activePage = page;
  }
  navigateToAccountPage(){
    this.setActivePage('account');
  }
  logOutUser(){
    this._alert.notificationToast("success",`goodbye`);
    this.auth.logOut();
  }
  readNotification(email:any,content:any){
    this.users.readUserNotifications(email,content).subscribe(data=>{
      this.getUserNotifications();
    })
  }
}
