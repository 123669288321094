<form [formGroup]="personalInfoGroup!">
    <div class="row">
      <mat-form-field appearance="outline" [style.width.px]="300">
        <mat-label>First Name</mat-label>
        <input matInput formControlName="FirstName" required>
        <mat-error *ngIf="personalInfoGroup!.get('FirstName')?.hasError('required')">
          First Name is required
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" [style.width.px]="300">
        <mat-label>Surname</mat-label>
        <input matInput formControlName="Surname" required>
        <mat-error *ngIf="personalInfoGroup!.get('Surname')?.hasError('required')">
          Surname is required
        </mat-error>
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field appearance="outline" [style.width.px]="300">
        <mat-label>Email</mat-label>
        <input matInput formControlName="Email" required>
        <mat-error *ngIf="personalInfoGroup!.get('Email')?.hasError('required')">
          Email is required
        </mat-error>
        <mat-error *ngIf="personalInfoGroup!.get('Email')?.hasError('email')">
          Please enter a valid email address
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" [style.width.px]="300">
          <mat-label>Confirm Email</mat-label>
          <input matInput formControlName="confirmEmail" required>
          <mat-error *ngIf="personalInfoGroup!.get('confirmEmail')?.hasError('required')">
            Confirmation email is required
          </mat-error>
          <mat-error *ngIf="personalInfoGroup!.get('confirmEmail')!.hasError('emailMismatch')">
            Email and confirmation email must match
          </mat-error>
      </mat-form-field>
    </div>
    <div class="row">
        <mat-form-field appearance="outline" [style.width.px]="300">
          <mat-label>Password</mat-label>
          <input matInput type="password" formControlName="Password" required>
          <mat-error *ngIf="personalInfoGroup!.get('Password')?.hasError('required')">
            Password is required
          </mat-error>
          <mat-error *ngIf="personalInfoGroup!.get('Password')?.hasError('pattern')">
            Password must be at least 8 characters long, contain 1 capital letter, and 1 special character
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" [style.width.px]="300">
          <mat-label>Verify Password</mat-label>
          <input matInput formControlName="verifyPassword" type="password">
          <mat-error *ngIf="personalInfoGroup!.get('verifyPassword')!.hasError('passwordMismatch')">
            Passwords do not match.
          </mat-error>
        </mat-form-field>
    </div>
    <input type="text"
      ng2TelInput
      formControlName="ContactNumber"
      [ng2TelInputOptions]="{initialCountry: 'za'}"
      (hasError)="hasError($event)"
      (ng2TelOutput)="getNumber($event)"
      (intlTelInputObject)="telInputObject($event)"
      (countryChange)="onCountryChange($event)"
      class="ng2-tel-input-custom" />
      <p class="include-prefix-message">include prefix</p>

    <div class="row mt-4 selection-row">
      <div>
        <p class="selection-label"> Age (optional)</p>
        <div class="age-container"> 
          <div class="radio-group">
            <input type="radio" id="young" name="ageGroup" value="18-30" formControlName="ageGroup">
            <label for="young" id="young">18-30</label>
          
            <input type="radio" id="medium" name="ageGroup" value="30-50" formControlName="ageGroup">
            <label for="medium" id="medium">30-50</label>
          
            <input type="radio" id="old" name="ageGroup" value="50+" formControlName="ageGroup">
            <label for="old" id="old">50+</label>
          </div>
        </div>
      </div>
      <div>
        <p class="selection-label"> Gender (optional)</p>
        <div class="gender-container"> 
          <div class="radio-group">
            <input type="radio" id="Male" name="genderGroup" value="Male" formControlName="genderGroup">
            <label for="Male" id="Male">Male</label>
          
            <input type="radio" id="Female" name="genderGroup" value="Female" formControlName="genderGroup">
            <label for="Female" id="Female">Female</label>
          
            <input type="radio" id="not-available" name="genderGroup" value="Rather not say" formControlName="genderGroup">
            <label for="not-available" id="not-available">Rather not say</label>
          </div>
        </div>
      </div>
      
    </div>
    <div class="row">
      <div>
        <p class="selection-label"> Employment (optional)</p>
        <div class="employment-container"> 
          <div class="radio-group">
            <input type="radio" id="Employed" name="employmentGroup" value="Employed" formControlName="employmentGroup">
            <label for="Employed" id="Employed">Employed</label>
          
            <input type="radio" id="Unemployed" name="employmentGroup" value="Unemployed" formControlName="employmentGroup">
            <label for="Unemployed" id="Unemployed">Unemployed</label>
          
            <input type="radio" id="none" name="employmentGroup" value="Rather not say" formControlName="employmentGroup">
            <label for="none" id="none">Rather not say</label>
          </div>
        </div>
      </div>
    </div>
    </form>
    <div class="continue">
      <button button type="button" class="btn btn-success mt-4 custom-button" mat-button (click)="updatePersonalInfo()">CONTINUE</button>
      <p class="newUser mt-2"> Already have an account? <span id="login-span" (click)="navigateToLogin()">Login</span></p>
    </div>
    