import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/services/auth.service';
import { UserService } from 'src/services/user.service';
import { AlertService } from 'src/shared/alert.service';

@Component({
  selector: 'app-company-details-page',
  templateUrl: './company-details-page.component.html',
  styleUrls: ['./company-details-page.component.scss']
})
export class CompanyDetailsPageComponent implements OnInit {
  isEditClicked: boolean = false;
  isEditClickedPostal: boolean = false;
  companyName: any;
  registrationNo: any;
  VatNo: any;
  BuildingNo: any;
  BuildingName: any;
  StreetName: any;
  cityTown: any;
  ProvinceState: any;
  country: any;
  PostalCode: any;

  postal_PObox: any;
  postal_cityTown: any;
  postal_ProvinceState: any;
  postal_country: any;
  postal_PostalCode: any;
  postal_CountryCode: any;
  postal_CityCode:any;

  loggedInUser: any;
  constructor(private auth: AuthService,private users: UserService,private _alert: AlertService) { }


  ngOnInit(): void {
    this.getUserByEmail(this.auth.getLoggedInUserId().contactInfo.Email);
  }
  getUserByEmail(email:any){
    this.users.getLoggedinUser(email).subscribe((data:any)=>{
      this.loggedInUser = data.user;
      this.companyName=this.loggedInUser.companyInfo.companyName ;
      this.registrationNo = this.loggedInUser.companyInfo.registrationNumber; 
      this.VatNo = this.loggedInUser.companyInfo.vatGstNumber;
  
      //physicalAddress
      this.BuildingNo = this.loggedInUser.physicalAddress.BuildingNo;
      this.BuildingName = this.loggedInUser.physicalAddress.BuildingName;
      this.StreetName = this.loggedInUser.physicalAddress.StreetName;
      this.cityTown = this.loggedInUser.physicalAddress.CityTown;
      this.ProvinceState = this.loggedInUser.physicalAddress.ProvinceState;
      this.country = this.loggedInUser.physicalAddress.Country;
      this.PostalCode = this.loggedInUser.physicalAddress.PostalCode;
  
      //postalAddress
      this.postal_PObox = this.loggedInUser.postOfficeAddress.PObox;
      this.postal_cityTown = this.loggedInUser.postOfficeAddress.CityTown;
      this.postal_ProvinceState = this.loggedInUser.postOfficeAddress.ProvinceState;
      this.postal_country = this.loggedInUser.postOfficeAddress.Country;
      this.postal_PostalCode = this.loggedInUser.postOfficeAddress.PostalCode;
      this.postal_CountryCode = this.loggedInUser.postOfficeAddress.CountryCode;
      this.postal_CityCode = this.loggedInUser.postOfficeAddress.CityCode;

    })
  }
  onEditClick() {
    this.isEditClicked = !this.isEditClicked; 
  }
  onPostalClick(){
    this.isEditClickedPostal = !this.isEditClickedPostal; 
  }
  updateAddressInfo(){
    const physicalAddress = {
      BuildingNo: this.BuildingNo,
      BuildingName: this.BuildingName,
      StreetName: this.StreetName,
      CityTown: this.cityTown,
      ProvinceState: this.ProvinceState,
      Country: this.country,
      PostalCode: this.PostalCode
    };
    const postOfficeAddress = {
      PObox: this.postal_PObox,
      CityTown: this.postal_cityTown,
      ProvinceState: this.postal_ProvinceState,
      Country: this.postal_country,
      PostalCode: this.postal_PostalCode,
      CountryCode: this.postal_CountryCode,
      CityCode: this.postal_CityCode
    };
    this.users.updateUserAddressInfo(this.loggedInUser.email,physicalAddress,postOfficeAddress).subscribe((data:any)=>{
      if(data.status.success){
        this._alert.notificationToast('success','check your email address to confirm detail changes!');
      }
      else{
        this._alert.notificationToast('warning','Your must confirm your current changes or wait for 24 hrs before updating user changes!')
      }
    })
  }

}
