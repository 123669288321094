<!-- <div class="mobile-stepper">
  <div class="wrapper row p-4">
    <div class="col-9">
      <h4>{{this.currentStep}}</h4>
      <h5> Next Step</h5>
    </div>
    <div class="col-3">
      STEP 1
    </div>
  </div>
</div> -->
<div class="reg-container">
    <div class="horiz-container">
    <div class="header">
        <img class="responsive-logo" src="../../assets/logo.svg" alt="Logo">
        <br>
        <mat-button-toggle-group name="fontStyle" class="col-12 col-lg-7" aria-label="Font Style" (change)="onToggleChange($event)">
         <mat-button-toggle value="media-owner" checked>REGISTER AS A CORPORATION</mat-button-toggle>
         <mat-button-toggle value="individual">REGISTER AS AN INDIVIDUAL</mat-button-toggle>
       </mat-button-toggle-group>
    </div>
       <div class="stepper-container">
        <mat-horizontal-stepper [linear]="isLinear" #stepper labelPosition="bottom" *ngIf="regType=='media-owner'" #steppers>
          <mat-step [stepControl]="contactInfoFormGroup" label="Contact Info">
            <app-contact-info [contactInfoFormGroup]="contactInfoFormGroup" (contactInfoUpdated)="onContactInfoUpdated($event)"></app-contact-info>
                <!-- <div>
                  <button mat-button matStepperPrevious>Back</button>
                  <button mat-button matStepperNext>Next</button>
                </div> -->
            </mat-step>
            <mat-step [stepControl]="firstFormGroup">
              <form [formGroup]="firstFormGroup">
                <ng-template matStepLabel>Company Info</ng-template>
                    <div class="row">
                            <mat-form-field appearance="outline" [style.width.px]="300">
                                <mat-label>Company Name</mat-label>
                                <input matInput formControlName="companyName" required>
                            </mat-form-field>
                            <mat-form-field appearance="outline" [style.width.px]="300" >
                                <mat-label>Registration Number</mat-label>
                                <input matInput formControlName="regNumber" required>
                            </mat-form-field>
                    </div>
                    <div class="row">
                      <mat-form-field appearance="outline" [style.width.px]="300">
                        <mat-label>VAT/GST Number</mat-label>
                        <input matInput formControlName="vatGstNumber" required>
                      </mat-form-field>
                      <mat-form-field appearance="outline" [style.width.px]="300" >
                        <mat-select formControlName="country" placeholder="Select Country" [style.height.px]="30">
                          <input matInput [placeholder]="'Search Country'" [formControl]="countryFilterControl" style="margin-left: 10px;" required>
                          <mat-option *ngFor="let country of filteredCountries" [value]="country.name">
                            {{ country.name }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <p id="uploadDoc"> Upload Documents </p>
                    <p id="description">Please note: You have the option to bypass this step and upload your documents 
                        later from your account in the "My Account" section. You will have a grace period of up to 5 days to 
                        submit the necessary documents.</p>
                        <div class="input-company">
                            <div class="input-file">
                                <div class="input">
                                  <div class="filename-container">
                                    <span class="file-label">{{ company_letter }}</span>
                                  </div>
                                    <label for="myFile1" class="choose-file-btn">Choose File</label>
                                    <input type="file" id="myFile1" name="filename" (change)="uploadCompanyLetter($event)">
                                </div>
                            </div>
                            <p id="format">Accepted formats: PNG, JPEG, PDF</p>
                        </div>
                        <div class="input-company">
                            <div class="input-file">
                                <div class="input">
                                  <div class="filename-container">
                                    <span class="file-label">{{ company_registration }}</span>
                                  </div>
                                    <label for="myFile2" class="choose-file-btn">Choose File</label>
                                    <input type="file" id="myFile2" name="filename" (change)="uploadCompanyRegistration($event)">
                                </div>
                            </div>
                            <p id="format">Accepted formats: PNG, JPEG, PDF</p>
                        </div>
                <div>
                    <div class="continue">
                        <button button type="button" class="btn btn-success mt-4 custom-button" mat-button  (click)="getCompanyDetails()">CONTINUE</button>
                        <p class="newUser mt-2"> Already have an account? <span id="login-span" (click)="navigateToLogin()">Login</span></p>
                    </div>
                </div>
              </form>
            </mat-step>
            <mat-step [stepControl]="physicalAddress" label="Address Info">
              <app-address-info [PostOfficeAddress]="PostOfficeAddress" [email]="contactInfoFormGroup.value!.Email" [physicalAddress]="physicalAddress" (addressInfoUpdated)="onAddressInfoUpdated($event)"></app-address-info>
            </mat-step>

            <mat-step [stepControl]="verificationForm" label="Contact Info">
              <form [formGroup]="verificationForm">
                <div  class="verify-otp" *ngIf="otpFormSubmitted">
                  <ng-template matStepLabel>Verification</ng-template>
                  <div class="verification">
                    <h2>Account Verification</h2>
                    <p id="otpsent">OTP Sent!</p>
                    <p id="enterotp">Please enter the 5 digit OTP sent to your mobile number</p>
                  </div>
                  <div class="otp-container">
                    <ng-otp-input  (onInputChange)="onOtpChange($event)"  [config]="{length:5}"></ng-otp-input>
                  </div>
                  <p id="remainingTime">Remaining Time: {{ displayTime }}</p>
                  <p id="resentOTP">Didn’t receive an OTP?<span id="sendAgain" (click)="requestOTPForCompany()"> Send again</span></p>
                  <div class="continue">
                    <button button type="button" class="btn btn-success mt-4 custom-button" mat-button (click)="verifyEmail()">SUBMIT</button>
                  </div>
                </div>
                <div class="getOTP" *ngIf="!otpFormSubmitted">
                  <ng-template matStepLabel>Verification</ng-template>
                  <div class="verification">
                    <h2>Account Verification</h2>
                    <p id="otpsent">With OTP</p>
                    <p id="channel">Select your channel:</p>
                  </div>
                  <div class="verification-type">
                    <input type="radio" id="sms" name="verificationType" value="SMS" formControlName="verificationType">
                    <label for="sms" id="sms-label">SMS</label>
                    <input type="radio" id="email" name="verificationType" value="Email" formControlName="verificationType">
                    <label for="email" id="email-label">Email</label>
                  </div>
                  <div *ngIf="isSmsSelected" class="otp-inputs mt-4">
                    <mat-form-field appearance="outline" [style.width.px]="300">
                      <mat-label>Enter Mobile Number</mat-label>
                      <input matInput formControlName="otpNumber" >
                    </mat-form-field>
                  </div>
                  <div *ngIf="isEmailSelected" class="otp-inputs mt-4">
                    <mat-form-field appearance="outline" [style.width.px]="300">
                      <mat-label>Enter Email Address</mat-label>
                      <input matInput formControlName="otpEmail" >
                    </mat-form-field>
                  </div>
                  <div class="continue">
                    <button button type="button" class="btn btn-success mt-4 custom-button" mat-button (click)="requestOTPForCompany()">GET OTP</button>
                  </div>
                </div>  
              </form>
            </mat-step>
        </mat-horizontal-stepper>
        <mat-horizontal-stepper [linear]="p_isLinear" #personalInfoStepper labelPosition="bottom" *ngIf="regType=='individual'">
          <mat-step [stepControl]="personalInfoGroup" label="Personal Info">
            <app-personal-info [personalInfoGroup]="personalInfoGroup" (formValueChanges)="onFormValueChanges($event)"></app-personal-info>
          </mat-step>
          <mat-step [stepControl]="PersonalverificationForm" label="Verification">
            <form [formGroup]="PersonalverificationForm">
              <div  class="verify-otp" *ngIf="otpFormSubmitted">
                <ng-template matStepLabel>Verification</ng-template>
                <div class="verification">
                  <h2>Account Verification</h2>
                  <p id="otpsent">OTP Sent!</p>
                  <p id="enterotp">Please enter the 5 digit OTP sent to your mobile number</p>
                </div>
                <div class="otp-container">
                  <ng-otp-input  (onInputChange)="onOtpChange($event)"  [config]="{length:5}"></ng-otp-input>
                </div>
                <p id="remainingTime">Remaining Time: {{ displayTime }}</p>
                <p id="resentOTP">Didn’t receive an OTP?<span id="sendAgain" (click)="requestOTPForIndividual()"> Send again</span></p>
                <div class="continue">
                  <button button type="button" class="btn btn-success mt-4 custom-button" mat-button (click)="verifyEmail()">SUBMIT</button>
                </div>
              </div>
              <div class="getOTP" *ngIf="!otpFormSubmitted">
                <ng-template matStepLabel>Verification</ng-template>
                <div class="verification">
                  <h2>Account Verification</h2>
                  <p id="otpsent">With OTP</p>
                  <p id="channel">Select your channel:</p>
                </div>
                <div class="verification-type">
                  <input type="radio" id="sms" name="pverificationType" value="SMS" formControlName="pverificationType">
                  <label for="sms" id="sms-label">SMS</label>
                  <input type="radio" id="email" name="pverificationType" value="Email" formControlName="pverificationType">
                  <label for="email" id="email-label">Email</label>
                </div>
                <div *ngIf="pisSmsSelected" class="otp-inputs mt-4">
                  <mat-form-field appearance="outline" [style.width.px]="300">
                    <mat-label>Enter Mobile Number</mat-label>
                    <input matInput  formControlName="p_otpNumber">
                  </mat-form-field>
                </div>
                <div *ngIf="pisEmailSelected" class="otp-inputs mt-4">
                  <mat-form-field appearance="outline" [style.width.px]="300">
                    <mat-label>Enter Email Address</mat-label>
                    <input matInput  formControlName="p_otpEmail">
                  </mat-form-field>
                </div>
                <div class="continue">
                  <button button type="button" class="btn btn-success mt-4 custom-button" mat-button (click)="requestOTPForIndividual()">GET OTP</button>
                </div>
              </div> 
            </form>
          </mat-step>
        </mat-horizontal-stepper>
      </div>
    </div>
</div>