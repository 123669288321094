import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/services/user.service';
import { AlertService } from 'src/shared/alert.service';

@Component({
  selector: 'app-users-page',
  templateUrl: './users-page.component.html',
  styleUrls: ['./users-page.component.scss']
})
export class UsersPageComponent implements OnInit {
  isDropdownOpen = false;
  isOptionsOpen = false;
  selectedReason: string = 'ID Document not valid';
  otherReason: string = '';
  referReason: string = '';
  isCarouselOpen: boolean = true;
  usersList: any;
  selectedUser: any;
  searchText: any;
  sortKey: string = 'default';
  reverseSort: boolean = false;
  IdDocument: any;
  LetterOfAuthority:any;
  company_Registration_Document:any;
  proofOfAddress:any;
  selectedDocuments: string[] = [];
  constructor(private users: UserService,private _alert: AlertService) {
    this.GetAllUsers();
   }

  ngOnInit(): void {
    
  }
  onDocumentChange(documentType: string): void {
    if (this.selectedDocuments.includes(documentType)) {
      this.selectedDocuments = this.selectedDocuments.filter(doc => doc !== documentType);
    } else {
      this.selectedDocuments.push(documentType);
    }
  }
  rejectDocument(){
    try {
      for(let doc of this.selectedUser.docs){
        if(this.selectedReason === 'ID Document not valid'){
          if(doc.metadata.type == 'identity_document_front'){
            this.users.verifyDocument(this.selectedUser.email,doc.metadata.type,doc.name,'invalid',this.selectedReason).subscribe(data=>{
              this.GetAllUsers();
            });
          }
        }
        if(this.selectedReason === 'Proof of Address Document not valid'){
          if(doc.metadata.type == 'proof_of_address'){
            this.users.verifyDocument(this.selectedUser.email,'proof_of_address',doc.name,'invalid',this.selectedReason).subscribe(data=>{
              this.GetAllUsers();
            });
          }
        }
      }
    } catch (error) {
    }
    this.users.rejectUserRequest(this.selectedUser.email,'Rejected').subscribe(data=>{
      this.GetAllUsers();
      this._alert.notificationToast('success','User request successfully rejected!');
    });
  }
  ReferUser(){
    this.users.rejectUserRequest(this.selectedUser.email,this.referReason).subscribe(data=>{
      this.GetAllUsers();
      this._alert.notificationToast('success','User request successfully referred!');
    });
  }
  GetAllUsers() {
    this.users.getAllUsers().subscribe((data: any) => {
      this.usersList = data;
      data.users.forEach((user: any, index: number) => {
        this.users.getUploadedDocuments(user.email).subscribe({
          next: (docs: any) => {
            if (this.usersList && this.usersList.users) {
              const currentUserIndex = this.usersList.users.findIndex((u: any) => u.email === user.email);
              if (currentUserIndex !== -1) {
                const currentUser = { ...this.usersList.users[currentUserIndex] };
                if (!currentUser.imageUrl) {
                  currentUser.imageUrl = [];
                  currentUser.docs = docs;
                }
                docs.forEach((document:any) => {
                  if(document.metadata.type !== 'profile_picture'){
                    //this line to be removed on deployment
                  //  const fileUrl =document.url.replace("https://ad-drop-maps-api.shayelasolutions.co.za", "http://localhost:8008");
                  //  currentUser.imageUrl.push(fileUrl);
                    currentUser.imageUrl.push(document.url);
                  }
                });
                this.usersList.users[currentUserIndex] = currentUser;
              }
            }
          },
          error: (error) => {
            if (this.usersList && this.usersList.users) {
              const currentUserIndex = this.usersList.users.findIndex((u: any) => u.email === user.email);
              if (currentUserIndex !== -1) {
                const currentUser = { ...this.usersList.users[currentUserIndex] };
                if (!currentUser.imageUrl) {
                  currentUser.imageUrl = [];
                }
                currentUser.imageUrl.push('https://static.vecteezy.com/system/resources/previews/023/914/428/original/no-document-or-data-found-ui-illustration-design-free-vector.jpg');
                this.usersList.users[currentUserIndex] = currentUser;
              }
            }
          },
          complete: () => {
          },
        });
      });
    });
  }
  
  approveUser(email: any, approveStatus:any){
    try {
      let verifiedDocumentCount = 0;
    
      for (let selectedDocumentType of this.selectedDocuments) {
        for (let doc of this.selectedUser.docs) {
          if (doc.metadata.type === selectedDocumentType) {
            switch (selectedDocumentType) {
              case 'identity_document_front':
              case 'proof_of_address':
              case 'company_registration':
              case 'company_letter':
                this.users.verifyDocument(this.selectedUser.email, doc.metadata.type, doc.name, 'valid', 'document met requirements').subscribe(data => {
                  verifiedDocumentCount++;
    
                  if (verifiedDocumentCount === this.selectedDocuments.length) {
                    this.users.approveUserRequest(approveStatus, this.selectedUser.email).subscribe((data: any) => {
                      this._alert.notificationToast('success', 'user successfully approved');
                      this.users.sendApprovalMessage(this.selectedUser.email).subscribe(data => {
                      });
                      this.GetAllUsers();
                    });
                  }
                });
                break;
    
              default:
                break;
            }
          }
        }
      }
    } catch (error) {
    }
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }
  onOtherSelected() {
    this.otherReason = '';
  }
  onClickedOutside() {
    if(this.isOptionsOpen){
      this.isOptionsOpen = false;
    }
  }
  onClickedOutsideSort(){
    if(this.isDropdownOpen){
      this.isDropdownOpen = false;
    }
  }
  toggleOptions(event: Event, user: any) {
    event.stopPropagation(); 
    this.isOptionsOpen = !this.isOptionsOpen;
    this.selectedUser = user;
  }
  closeCarousel(): void {
    this.isCarouselOpen = false;
  }
  sortBy(key: string) {
    if (this.sortKey === key) {
      this.reverseSort = !this.reverseSort;
    } else {
      this.sortKey = key;
      this.reverseSort = false;
    }
    this.usersList.users.sort((a: any, b: any) => {
      const valueA = this.getPropertyValue(a, this.sortKey);
      const valueB = this.getPropertyValue(b, this.sortKey);
      if (key === 'createdAt') {
        const dateA = new Date(valueA);
        const dateB = new Date(valueB);
        return this.reverseSort ? dateB.getTime() - dateA.getTime() : dateA.getTime() - dateB.getTime();
      } else {
        if (valueA < valueB) {
          return this.reverseSort ? 1 : -1;
        } else if (valueA > valueB) {
          return this.reverseSort ? -1 : 1;
        } else {
          return 0;
        }
      }
    });
  }
  getPropertyValue(obj: any, key: string): any {
    const keys = key.split('.');
    return keys.reduce((o, k) => (o && o[k] !== undefined ? o[k] : undefined), obj);
  }

}
