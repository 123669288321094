<h1 class="header">Add location</h1>
<div class="row">
    <div class="col-lg-6 col-12">
      <div class="form-group has-search">
        <div class="search-container" (clickOutside)="onclickOutsideSearch()">
          <div class="search-input">
            <input type="text" class="responsive-input"  [(ngModel)]="input" (keyup.enter)="onInputChange()"  class="form-control" placeholder="Search">
            <mat-icon class="search-icon"  (click)="onInputChange()">search</mat-icon>
          </div>
          <ul class="suggestions" *ngIf="showSuggestions && predictions.length > 0">
            <li *ngFor="let prediction of predictions">
              <p (click)="onSuggestionSelect($event, prediction)">{{ prediction.description }}</p>
            </li>
          </ul>
        </div>

      </div>
    </div>
    
    <div class="col-3 ">
      <div class="add billboard">
          <button type="button" class="btn btn-outline-info" data-bs-toggle="modal" data-bs-target="#addBillboard"> <mat-icon id="add-box">add_box</mat-icon> Add Billboard</button>
      </div>
    </div>
    <div class="col-3">
      <div class="add billboard">
          <button type="button" class="btn btn-outline-info poi-btn" data-bs-toggle="modal" data-bs-target="#addPointsOfinterest"> <mat-icon id="add-box">add_box</mat-icon>Add Point Of Interest</button>
      </div>
    </div>
  </div>
<div class="row">
  <div class="col-12 mt-4">
    <div class="container">
      <div id="map-container" [ngSwitch]="currentMapStyle">
        <div *ngSwitchDefault>
            <div id="map"></div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal" id="addBillboard" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" (shown.bs.modal)="handleOnShown()">
    <div class="modal-dialog modal-dialog-centered modal-lg" >
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel">Add Billboard</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <p>Provide some information about this place. If this place is added to the map, it will appear publicly.</p>
          </div>
          <div class="row" id="fields">
            <p>All fields marked (*) are required</p>
          </div>
          <div class="row">
            <div class="col-5">
              <mat-form-field appearance="outline" >
                <mat-label>Place Name (Required)</mat-label>
                <input matInput  [(ngModel)]="markerLabel" required>
              </mat-form-field>
            </div>
            <div class="col-5">
              <mat-form-field appearance="outline" >
                <mat-label>Additional information (Required)</mat-label>
                <input matInput [(ngModel)]="markerInfo" name="markerInfo" required>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-5">
              <mat-form-field appearance="outline">
                <mat-label>Latitude (Required)</mat-label>
                <input matInput [(ngModel)]="newLatitude" name="newLatitude" (ngModelChange)="onLatLongChange($event)" required>
              </mat-form-field>
            </div>
            <div class="col-5">
              <mat-form-field appearance="outline">
                <mat-label>Longitude (Required)</mat-label>
                <input matInput [(ngModel)]="newLongitude" name="newLongitude"  (ngModelChange)="onLatLongChange($event)" required>
              </mat-form-field>
            </div>
          </div>
          <div class="maps-container">
            <div class="row">
              <div class="col-12 mt-4">
                  <div id="photo-map-container" [ngSwitch]="currentMapStyle">
                    <div *ngSwitchDefault>
                      <div id="LocationMap"></div>
                    </div>
                  </div>
              </div>
            </div>
            <div class="button-container" data-bs-toggle="modal" href="#EditPointOfInterest">
              <p class="edit-map-paragraph" ><img src="../../assets/edit_location.svg" class="edit-location" alt=""> Edit map location</p>
            </div>
          </div>
          <h2>Place Image</h2>
          <div class="row">
            <div class="col-12 col-md-3">
                <label for="file-input-place-image" class="file-label" >
                    <img src="assets/add_a_photo.png" alt="Upload Icon" class="file-icon" width="14px" height="14px" /> Add Image
                </label>
                <input type="file" id="file-input-place-image" class="file-input" (change)="onFileSelected($event)" required/>
            </div>
          </div>
          <button button type="button" class="btn btn-success mt-4 custom-button" (click)="addMarker()" data-bs-dismiss="modal">Add Billboard</button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" id="addPointsOfinterest" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static" (shown.bs.modal)="handleOnShown()">
    <div class="modal-dialog modal-dialog-centered modal-lg" >
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel">Add Point Of Interest</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="form-container">
            <div class="row">
              <p>Provide some information about this place. If this place is added to the map, it will appear publicly.</p>
            </div>
            <div class="row" id="fields">
              <p>All fields marked (*) are required</p>
            </div>
            <div class="row">
              <div class="col-5">
                <mat-form-field appearance="outline" >
                  <mat-label>Place Name (Required)</mat-label>
                  <input matInput [(ngModel)]="pointOfinterestName" name="pointOfinterestName" required>
                </mat-form-field>
              </div>
              <div class="col-5">
                <mat-form-field appearance="outline">
                  <mat-label>Latitude (Required)</mat-label>
                  <input matInput [(ngModel)]="newLatitude" name="newLatitude" required>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-5">
                <mat-form-field appearance="outline">
                  <mat-label>Longitude (Required)</mat-label>
                  <input matInput [(ngModel)]="newLongitude" name="newLongitude" required>
                </mat-form-field>
              </div>
            </div>
            <div class="maps-container">
              <div class="row">
                <div class="col-12 mt-4">
                    <div id="photo-map-container" [ngSwitch]="currentMapStyle">
                      <div *ngSwitchDefault>
                        <div id="LocationMapDefault"></div>
                      </div>
                    </div>
                </div>
              </div>
              <div class="button-container" data-bs-toggle="modal" href="#EditLocation">
                <p class="edit-map-paragraph" ><img src="../../assets/edit_location.svg" class="edit-location" alt=""> Edit map location</p>
              </div>
            </div>
            <h2>Place Image & Logo</h2>
            <p>Add a helpful image and logo of the point of interest.</p>
            <div class="row">
              <div class="col-2" style="margin-right: 40px;">
                <label for="file-input-poi-image" class="file-label">
                    <img src="assets/add_a_photo.png" alt="Upload Icon" class="file-icon" width="14px" height="14px" /> Add Image
                </label>
                <input type="file" id="file-input-poi-image" name="poi-image" class="file-input" (change)="onPointsOfFileSelected($event)" required />
            </div>
            
            <div class="col-2">
                <label for="file-input-poi-logo" class="file-label-longer-text">
                    <img src="assets/add_a_photo.png" alt="Upload Icon" class="file-icon" width="14px" height="14px" /> Add Logo Image
                </label>
                <input type="file" id="file-input-poi-logo" name="poi-logo" class="file-input" (change)="onIcon($event)" required />
            </div>
            </div>
            <button class="mt-4" name="add-marker-button" (click)="addPointsOfinterest()" data-bs-dismiss="modal">Add Point of interest</button>
          </div>

        </div>
      </div>
    </div>
  </div>
  <div class="modal" id="EditPointOfInterest" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static" (shown.bs.modal)="handleOnShown()">
    <div class="modal-dialog modal-dialog-centered modal-lg" >
      <div class="modal-content">
        <div class="modal-header">
          <p class="modal-title fs-5" id="exampleModalLabel"><mat-icon data-bs-toggle="modal" href="#addBillboard" >arrow_back</mat-icon>Location</p>
          <button type="button" data-bs-toggle="modal" href="#addBillboard" class="btn-close" aria-hidden="true" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12 mt-4">
              <div id="photo-map-placeholder" [ngSwitch]="currentMapStyle">
                <div *ngSwitchDefault>
                  <div id="LocationMaps"></div>
                </div>
              </div>
            </div>
          </div>
          <p class="text-reset-map">Reset Map</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" data-bs-toggle="modal" href="#addBillboard">Save</button>
          <button type="button" class="btn btn-outline-primary" data-bs-toggle="modal" href="#addBillboard">Cancel</button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal" id="EditLocation" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static" (shown.bs.modal)="handleOnShown()">
    <div class="modal-dialog modal-dialog-centered modal-lg" >
      <div class="modal-content">
        <div class="modal-header">
          <p class="modal-title fs-5" id="exampleModalLabel"><mat-icon data-bs-toggle="modal" href="#addPointsOfinterest" >arrow_back</mat-icon>Location</p>
          <button type="button" data-bs-toggle="modal" href="#addPointsOfinterest" class="btn-close" aria-hidden="true" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12 mt-4">
              <div id="photo-map-placeholder" [ngSwitch]="currentMapStyle">
                <div *ngSwitchDefault>
                  <div id="LocationForPointsOfinterest"></div>
                </div>
              </div>
            </div>
          </div>
          <p class="text-reset-map">Reset Map</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" data-bs-toggle="modal" href="#addPointsOfinterest">Save</button>
          <button type="button" class="btn btn-outline-primary" data-bs-toggle="modal" href="#addPointsOfinterest">Cancel</button>
        </div>
      </div>
    </div>
  </div>