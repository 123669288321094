import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/services/auth.service';
import { UserService } from 'src/services/user.service';
import { AlertService } from 'src/shared/alert.service';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {
  email: string = '';
  password: string = '';
  contactNumber: string = '';
  isFormValid: boolean = false;
  rememberMe: boolean = false;
  otpFormSubmitted: boolean = false;
  verificationForm!: FormGroup;
  remainingTime: number = 300;
  displayTime: string = '05:00'
  intervalId: any;
  type: string = 'email';
  otpInput: any;
  displayOTPScreen: boolean = false;
  disableSendAgain: boolean = false;
  cooldownMessage: string = '';
  displayTimeSendAgain: number = 0;
  constructor(private router: Router,private auth: AuthService, private _alert: AlertService, 
    private user: UserService,private _formBuilder: FormBuilder,private ref: ChangeDetectorRef) {}

  ngOnInit(): void {
  }
  checkFormValidity() {
    this.isFormValid = this.email.trim() !== '';
  }
  onEmailChange(newEmail: string) {
    this.email = newEmail.toLowerCase();
  }
  showOTPscreen(){
    this.displayOTPScreen = true;
    this.verificationForm = this._formBuilder.group({
      verificationType: ['SMS', Validators.required],
      otpInput:['', Validators.required],
      otpEmail: [{value: this.email, disabled: true}, Validators.email],
      otpNumber: [{value: this.contactNumber , disabled: true}]
    });
  }
  verifyUSerRegistration(){
    this.auth.login(this.email).subscribe({
      next: (data: any) => {
        this.disableSendAgain = true;
        this.displayTimeSendAgain = 60;
        this.showOTPscreen();
        this.otpFormSubmitted = true;
        this.ref.detectChanges();
        this.startTimer();
        this._alert.notificationToast("success",data.message)
      },
      error: (error: any) => {
        this._alert.notificationToast('warning','You must register to be able to login.');
      },
      complete: () => {
        
      }
    });
  }
  requestOtp(){
    this.stopTimer();

    this.disableSendAgain = true;
    this.displayTimeSendAgain = 60;
    this.user.requestOTPUpdateChanges(this.email,this.email).subscribe({
      next: (regStatus:any) => {
        this.otpFormSubmitted = true;
        this.ref.detectChanges();
        this.startTimer();
        this._alert.notificationToast("success",regStatus.message)
      },
      error: error => {
        console.error('Error handler:', error);
        this._alert.notificationToast("warning",error.error.message)
      },
      complete: () => {
      }
    });
  }
  startTimer() {
    this.intervalId = setInterval(() => {
      this.updateTimer();
      this.displayTimeSendAgain--;
      if (this.disableSendAgain) {
        this.cooldownMessage = 'Please wait ' + this.displayTimeSendAgain + ' seconds before requesting another OTP.';
      }
      if (this.displayTimeSendAgain === 0) {
        this.disableSendAgain = false;
        this.cooldownMessage = '';
      }
    }, 1000);
  }
  stopTimer() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.remainingTime = 300;
    }
  }
  updateTimer() {
    if (this.remainingTime > 0) {
      this.remainingTime--;
      this.updateDisplayTime();
    } else {
    }
  }
  updateDisplayTime() {
    const minutes = Math.floor(this.remainingTime / 60);
    const seconds = this.remainingTime % 60;
    this.displayTime = `${this.padNumber(minutes)}:${this.padNumber(seconds)}`;
  }
  padNumber(num: number): string {
    return num < 10 ? '0' + num : num.toString();
  }
  onOtpChange(value:any){
    this.otpInput = value;
  }
  verifyEmail(){
    if(this.remainingTime == 0){
      this.stopTimer();
      this.remainingTime = 0;
      this._alert.notificationToast('warning','OTP has expired request another one!');
      return;
    }
    this.user.verifyOTP(this.email,this.otpInput).subscribe({
      next: (regStatus:any) => {
        if(regStatus.status){
          this._alert.notificationToast("success",'otp successfully validated');
          localStorage.setItem('token', regStatus.token);
          this.stopTimer();
          this.loginUser();
        }
        else{
          this._alert.notificationToast("warning",'error validating otp');
        }
      },
      error: error => {
        console.error('Error handler:', error);
        this._alert.notificationToast("warning",error.error.message)
      },
      complete: () => {
      }
    });
  }
  navigateToRegister(){
    this.router.navigate(['/create-account'])
  }
  navigateToResetPassword(){
    this.router.navigate(['/reset-password'])
  }
  loginUser(){
    this.auth.login(this.email).subscribe({
      next: (loginStatus:any) => {
        this._alert.notificationToast("success",`welcome ${loginStatus.user.email}`);
        if(this.rememberMe){
          this.auth.setLoggedInUserIdPermanent(loginStatus.user);
        }
        else{
          this.auth.setLoggedInUserId(loginStatus.user);
        }
        this.router.navigate(['/dashboard']);
      },
      error: error => {
        console.error('Error handler:', error);
        this._alert.notificationToast("warning",error.error.message)
        if(error.error.message === 'User needs to undergo liveness test to login.'){
          this.user.getUserByEmail(this.email).subscribe({
            next: (fetchedUser:any) => {
              console.log(fetchedUser);
              if(fetchedUser.user.userType === 'individual')
              {
                const documents = {
                  IDdocument: 'None',
                  email: this.email
                };
                this.router.navigate(['/liveness-notice'], {
                  queryParams: documents
                });
              }
              else{
                this.user.getUploadedDocuments(this.email).subscribe((docs:any)=>{
                  docs.forEach((doc: any) => {
                    if(doc.metadata.type === 'identity_document_front'){
                      const documents = {
                        IDdocument: doc.url,
                        email: this.email
                      };
                      this.router.navigate(['/liveness-notice'], {
                        queryParams: documents
                      });
                    }
                  })
                })
              }
            },
            error: error => {},
          })

        }
      },
      complete: () => {
      }
    });
  }

}
